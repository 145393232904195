/** @format */

// api.js
import axios from 'axios';
import config from '../../configs';
import { toast } from 'react-toastify';
import { getInfLoggedInToken } from '../../utils/InfluencerAuthConfig';
///import { refreshToken, updateAccessToken, logout } from './redux/actions/authActions';

const api = axios.create({
  baseURL: config.apiBaseUrl,
  //timeout: 5000,
});

api.interceptors.request.use(
  (config) => {
    const token = getInfLoggedInToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//* Axios interceptor for handling status code errors
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log(error, "error error");

    const originalRequest = error.config;

    if (error.response) {
      const { status, data } = error.response;

      console.log(data, 'data');

      if (status === 401) {
        // Unauthorized error (e.g., token expired)
        // const refreshTokenValue = store.getState().auth.refreshToken;
        // if (refreshTokenValue) {
        //   try {
        //     // Use your API endpoint to refresh the token
        //     // const response = await api.post("/auth/refresh", {
        //     //   refreshToken: refreshTokenValue,
        //     // });
        //     // // Update the access token with the new one
        //     // //store.dispatch(updateAccessToken(response.data.accessToken));
        //     // // Retry the original request with the new access token
        //     // originalRequest.headers[
        //     //   "Authorization"
        //     // ] = `Bearer ${response.data.accessToken}`;
        //     // return api(originalRequest);
        //   } catch (refreshError) {
        //     // Handle token refresh error (e.g., refresh token expired)
        //     console.error("Token refresh failed:", refreshError);
        //     ///store.dispatch(logout()); // Logout the user or handle the error
        //   }
        // } else {
        //   // No refresh token available, handle logout or redirect to login
        //   console.error("No refresh token available.");
        //   //store.dispatch(logout()); // Logout the user or handle the error
        // }
        toast.error(data.message);
      } else if (status === 403) {
        // Handle forbidden error (e.g., insufficient permissions)
        // Redirect to a page or display an error message
      } else if (status === 400) {
        // Handle forbidden error (e.g., insufficient permissions)
        // Redirect to a page or display an error message
      } else if (status === 429) {
        if (data && data.message) {
          toast.error(data.message);
        }
        // Handle forbidden error (e.g., insufficient permissions)
        // Redirect to a page or display an error message
      }
    }

    return Promise.reject(error);
  }
);

export default api;
