/** @format */

import React from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  CardActions,
  Button,
} from '@mui/material';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//import { Link } from 'react-router-dom'

function Dashboard() {
  return (
    <Box
      sx={{ mb: 3 }}
      className='cardWrapper'>
      <div className='pageTitleWrap'>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'>
          <Typography
            className=''
            component={'h2'}
            variant='h2'>
            Dashboard
          </Typography>
        </Stack>
      </div>
    </Box>
  );
}

export default Dashboard;
