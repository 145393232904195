/** @format */

import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
//import Dashboard from '../pages/Dashboard';
import { Box } from '@mui/material';
// import JoinCards from '../../pages/home/joincards/JoinCards';
// import CampaignList from '../brand/CampaignList';
//import DepartmentList from '../pages/Department';
import Header from './Header';
import InfluencerCampaignList from '../../influencer/CampaignList';
import InfluencerCampaignDetails from '../../influencer/CampaignDetails';
import InfAllCampaigns from '../../influencer/AllCampaignList';
import Footer from '../../../components/footer/Footer';
// import CampaignAdd from '../brand/CampaignAdd';
// import CampaignDetails from '../brand/CampaignDetails';

const InfluencerLayout = () => {
  const location = useLocation();
  let route = location.pathname.split('/');
  // const drawerWidth = 210;
  console.log(route, 'route');

  return (
    <div>
      {route.length !== 3 || (route.length === 3 && route[2] !== 'campaign') ? (
        <Header />
      ) : null}
      <Box
        className='contnenWrap'
        sx={{}}>
        <div className='content'>
          <Routes>
            <Route
              exact
              path='dashboard'
              element={<InfluencerCampaignList />}
            />
          </Routes>
          <Routes>
            <Route
              exact
              path='all-campaigns'
              element={<InfAllCampaigns />}
            />
          </Routes>

          <Routes>
            <Route
              exact
              path='campaign/detail/:id'
              element={<InfluencerCampaignDetails />}
            />
          </Routes>
        </div>
      </Box>
      <Footer />
    </div>
  );
};

export default InfluencerLayout;
