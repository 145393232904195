/** @format */

import React from 'react';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '../../components/textfield/TextField';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { authAction } from '../../store/actions/influencer/authAction';

// import axios from 'axios';
import { getInfLoggedInToken } from '../../utils/InfluencerAuthConfig';

const initialUserValues = {
  phone: '',
};

const initialErrors = {
  phone: '',
};

const Login = () => {
  // For dynamic navigatioj
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedIn = getInfLoggedInToken();
  //const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      navigate('/influencer/dashboard');
    }
  }, [loggedIn, navigate]);

  // {useState} for user object
  const [user, setUser] = useState(initialUserValues);
  const [errors, setErrors] = useState(initialErrors);
  // {useState} for remember me
  const [isRememberMe, setRememberMe] = useState(false);

  // Google Sign In
  const [profile, setProfile] = useState([]);

  function phoneNumberValidation() {
    const phone = user.phone;
    if (validator.isEmpty(phone)) {
      return 'Please, enter valid phone number or valid Email!';
    } else if (validator.isNumeric(phone) && phone.length !== 10) {
      return 'Please, enter valid phone number';
    } else if (!validator.isNumeric(phone) && !validator.isEmail(phone)) {
      return 'Please, enter valid Email!';
    }
    return '';
  }

  function passwordValidation() {
    const password = user.password;
    if (validator.isEmpty(password)) {
      return 'Password required';
    } else if (password.length < 8) {
      return 'Password must be atleast 8 letter';
    } else if (password.length > 15) {
      return 'Password must be 8-15 letters';
    }
    return '';
  }

  // Tap events
  const onTapRememberMe = () => {
    setRememberMe(!isRememberMe);
  };

  const onTapLoginButton = () => {
    setErrors((values) => ({
      ...values,
      phone: phoneNumberValidation(),
    }));
    if (phoneNumberValidation() === '') {
      dispatch(authAction.login(user, navigate));
    }
  };

  const onTapForgotPasswordButton = () => {
    alert('onTapForgotPasswordButton');
  };

  // const onTapGoogleSignInButton = () => {
  //   login();
  // };

  const onTapSignUpButton = () => {
    navigate('/influencer/signup');
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnBlurTextField = (e) => {};

  return (
    <div className='login'>
      <div className='login-container'>
        <div className='login-container-left'>
          <div className='login-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='login-container-left-content-fields'>
              <div className='login-header'>Login to Social Bottle</div>

              {/* {profile.name && (
                <div className='profile-data'>
                  <span className='welcome-message'>Welcome, </span>
                  <span className='profile-name'>{profile.name} !</span>
                </div>
              )} */}
              <TextField
                name='phone'
                placeholder='Phone number'
                hintText='Phone number'
                variant='phone'
                onChange={handleTextFieldChange}
                onBlur={handleOnBlurTextField}
              />
              {errors.phone !== null && errors.phone !== '' && (
                <div className='error-message-container'>{errors.phone}</div>
              )}
              {/* <TextField
                name='password'
                placeholder='Enter password'
                hintText='Password'
                variant='password'
                onChange={handleTextFieldChange}
                onBlur={handleOnBlurTextField}
              />
              {errors.password !== null && errors.password !== '' && (
                <div className='error-message-container'>{errors.password}</div>
              )} */}
              <div className='remember-container'>
                <div
                  className='remember-me'
                  onClick={onTapRememberMe}>
                  <div className='remember-me-checkbox'>
                    {isRememberMe ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </div>
                  <div className='remember-me-text'>Remember me</div>
                </div>
                <button
                  className='forgot-password'
                  onClick={onTapForgotPasswordButton}>
                  Forgot password?
                </button>
              </div>
              <div className='login-button'>
                <button onClick={onTapLoginButton}>Login</button>
              </div>
              {/* <div className="or-container">
                <div className="hr-left"><hr/></div>
                <div className="or-text">or</div>
                <div className="hr-right"><hr /></div>
              </div> */}
              {/* <div
                className='login-with-google-container'
                onClick={onTapGoogleSignInButton}>
                <div className='google-icon'>
                  <img
                    src={GoogleIcon}
                    alt=''
                  />
                </div>
                <div className='google-login-text'>Login with Google</div>
              </div> */}
              <div className='dont-have-account-container'>
                <span className='dont-have-account-text'>
                  {' '}
                  Don’t have an account?
                </span>
                <span
                  className='register-button'
                  onClick={onTapSignUpButton}>
                  Register now
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='login-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default Login;
