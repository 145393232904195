/** @format */

import { approveInfluencerTypes } from '../../types/admin/ApproveInfluencerTypes';
const initialState = {
  loading: false,
  approveInfluencer_data: [],
  approveInfluencer_status: false,
  approveInfluencerData: [],
  approveInfluencerData_status: false,
};
export const approveInfluencers = (state = initialState, action) => {
  switch (action.type) {
    case approveInfluencerTypes.GETALL_APPROVE_INFLUENCER_REQUEST:
      return {
        loading: true,
      };
    case approveInfluencerTypes.GETALL_APPROVE_INFLUENCER_SUCCESS:
      return {
        ...state,
        loading: false,
        approveInfluencer_status: true,
        approveInfluencer_data: action.payload,
      };

    case approveInfluencerTypes.GETALL_APPROVE_INFLUENCER_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case approveInfluencerTypes.EDIT_APPROVE_INFLUENCER_REQUEST:
      return {
        loading: true,
      };
    case approveInfluencerTypes.EDIT_APPROVE_INFLUENCER_SUCCESS:
      return {
        ...state,
        loading: false,
        approveInfluencerData_status: true,
        approveInfluencerData: action.payload,
      };
    case approveInfluencerTypes.EDIT_APPROVE_INFLUENCER_ERROR:
      return {
        loading: false,
        error: action.error,
      };

    case approveInfluencerTypes.UPDATE_APPROVE_INFLUENCER_REQUEST:
      return {
        loading: true,
        data: action.data,
      };
    case approveInfluencerTypes.UPDATE_APPROVE_INFLUENCER_SUCCESS:
      return {
        loading: false,
        item: action.data,
      };
    case approveInfluencerTypes.UPDATE_APPROVE_INFLUENCER_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
