/** @format */

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import Login from '../influencerAuth/login/Login';
import SignUp from '../influencerAuth/signup/SignUp';
import OtpScreen from '../influencerAuth/otp/OtpScreen';
import EmailOtpScreen from '../influencerAuth/email-otp/EmailOtpScreen';
import Social from '../influencerAuth/social-account/Social';
import ProfileInput from '../influencerAuth/register/ProfileInput';
import SocialProvider from '../influencerAuth/social-account/SocialProvider';
import TopicAndLanguageInput from '../influencerAuth/topics/topics';
import BrandLayout from '../views/layout/Layout';
import PrivateInfluencerRoute from './PrivateInfluencerRouter';
import InfluencerLayout from '../views/influencerLayout/layout';
import InfThankYouScreen from '../pages/thank-you/InfThankYouScreen';

function InfluencerRouter() {
  return (
    <>
      <Routes>
        <Route
          index
          path='login'
          element={<Login />}
        />
        <Route
          path='signup'
          element={<SignUp />}
        />
        <Route
          path='verify-otp/:id'
          element={<OtpScreen />}
        />
        <Route
          path='verify-email-otp/:id'
          element={<EmailOtpScreen />}
        />
        <Route
          path='register/:id'
          element={<ProfileInput />}
        />
        <Route
          path='social/:id'
          element={<Social />}
        />
        <Route
          path='social/:provider/:id'
          element={<SocialProvider />}
        />
        <Route
          path='topic/:id'
          element={<TopicAndLanguageInput />}
        />
        <Route
          path='thank-you'
          element={<InfThankYouScreen />}
        />
        <Route
          path='/'
          element={<PrivateInfluencerRoute />}>
          <Route
            path='/*'
            element={<InfluencerLayout />}
          />
        </Route>

        {/* <Route
          index
          path='/login'
          element={<BrandLogin />}
        />
        <Route
          path='/signup'
          element={<BrandSignUp />}
        />
        <Route
          path='/verify-otp/:id'
          element={<BrandOtpScreen />}
        />
        <Route
          path='/verify-email-otp/:id'
          element={<BrandEmailOtpScreen />}
        />
        <Route
          path='/role/:id'
          element={<RoleScreen />}
        />
        <Route
          path='/profile-input/:id'
          element={<BrandProfileInput />}
        /> */}
      </Routes>
    </>
  );
}
export default InfluencerRouter;
