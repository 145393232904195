/** @format */

import React from 'react';
import './influencers.scss';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import InfluencerHeader from '../../views/influencerLayout/layout/Header';
import InfluencerBanner from './InfluencerBanner';
import InfluencerVideoSec from './VideoSection';
import InflulencerDataSec from './InflulencerDataSec';
import InfluencerFeedback from './InfluencerFeedback';

const Influencers = () => {
  return (
    <div className='influencers influencersWrapper'>
      <InfluencerHeader />
      <InfluencerBanner />
      <InfluencerVideoSec />
      <InflulencerDataSec />
      <InfluencerFeedback />
      <Footer />
    </div>
  );
};

export default Influencers;
