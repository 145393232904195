/** @format */

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { approveInfluencerAction } from '../../../store/actions/admin/approveInfluencerAction';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { MiniLoader } from '../../../utils/Loader';
import { StatusValidation } from '../../../utils/AdminValidation';

export default function InfluencerEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();

  useEffect(() => {
    dispatch(approveInfluencerAction.edit(code));
  }, [dispatch, code]);

  const { approveInfluencerData, loading } = useSelector(
    (state) => state.approveInfluencers
  );

  return (
    <Box
      sx={{ mb: 3, position: 'relative', overflow: 'hidden' }}
      className='cardWrapper'>
      {loading ? <MiniLoader /> : ''}
      <div className='pageTitleWrap'>
        <Typography
          component='h2'
          variant='h2'>
          Edit Influencer
        </Typography>
      </div>
      <Formik
        initialValues={{
          status: approveInfluencerData?.data?.status || null, // Initialize status with data from API
        }}
        enableReinitialize
        validationSchema={StatusValidation}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          console.log(values);
          dispatch(
            approveInfluencerAction.update(
              code,
              values,
              setErrors,
              setSubmitting,
              navigate
            )
          );
        }}>
        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
          <Form>
            <Card
              variant='outlined'
              className='formWrapper'>
              <CardHeader
                title={`${approveInfluencerData?.data?.firstName} ${approveInfluencerData?.data?.lastName}`}
              />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>First Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.firstName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Last Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.lastName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.email}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>City</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.cityId[0]?.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Is Connect Instagram
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.isConnectInstagram
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Is Connect YouTube
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.isConnectYouTube
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Is Connect Twitter
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.isConnectTwitter
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow> */}
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Budget</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.budget}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Requirement Type</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveInfluencerData?.data?.requirementType.map(
                              (require) => (
                                <Chip
                                  sx={{ mr: 1, color: 'primary.text' }}
                                  color='primary'
                                  key={require._id}
                                  label={require.name}
                                />
                              )
                            )}
                            {/* {approveInfluencerData?.data?.requirementType} */}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Topics</Typography>
                        </TableCell>
                        <TableCell>
                          {approveInfluencerData?.data?.topics.map((topic) => (
                            <Chip
                              sx={{ mr: 1, color: 'primary.text' }}
                              color='primary'
                              key={topic._id}
                              label={topic.name}
                            />
                          ))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Publication Languages
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {approveInfluencerData?.data?.pubLang.map((lang) => (
                            <Chip
                              sx={{ mr: 1, color: 'primary.text' }}
                              color='primary'
                              key={lang._id}
                              label={lang.name}
                            />
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              <CardContent>
                <Typography
                  sx={{ my: 3 }}
                  variant='h6'
                  gutterBottom>
                  Connected YouTube Channels:
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>User Info</TableCell>
                        <TableCell>Channel Info</TableCell>
                        <TableCell>Subscriber Count</TableCell>
                        <TableCell>View Count</TableCell>
                        <TableCell>Video Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {approveInfluencerData?.connectedAccount?.map(
                        (account) => (
                          <TableRow key={account._id}>
                            <TableCell>
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems={'center'}>
                                <Avatar
                                  alt={account.userinfo?.name}
                                  src={account.userinfo?.picture}
                                />
                                <Typography variant='subtitle1'>
                                  {account.userinfo?.name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction='row'
                                spacing={2}
                                alignItems={'center'}>
                                <Avatar
                                  alt='Channel Thumbnail'
                                  src={
                                    account.channelinfo[0]?.snippet.thumbnails
                                      .medium.url
                                  }
                                />
                                <Typography variant='subtitle1'>
                                  Custom URL:{' '}
                                  {account.channelinfo[0]?.snippet.customUrl}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography variant='subtitle1'>
                                {
                                  account.channelinfo[0]?.statistics
                                    .subscriberCount
                                }
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='subtitle1'>
                                {account.channelinfo[0]?.statistics.viewCount}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant='subtitle1'>
                                {account.channelinfo[0]?.statistics.videoCount}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              {approveInfluencerData?.data?.isConnectYouTube ? (
                <CardContent sx={{ maxWidth: '400px' }}>
                  <Field
                    className='autoCompleteField'
                    as={Autocomplete}
                    name='status'
                    fullWidth
                    value={values.status}
                    options={['approved', 'pending', 'rejected']}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option}
                    onChange={(e, newvalue) => {
                      setFieldValue('status', newvalue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Status'
                        error={errors.status && touched.status}
                        helperText={
                          errors.status && touched.status && errors.status
                        }
                      />
                    )}
                  />
                </CardContent>
              ) : (
                ''
              )}
              <CardActions sx={{ p: 2 }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    navigate('/admin/influencer');
                  }}>
                  Back
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
