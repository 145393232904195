/** @format */

import React from 'react';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './signup.scss';
import Logo from '../../assets/logo-black.svg';
import GoogleIcon from '../../assets/auth/google-icon.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';

import TextField from '../../components/textfield/TextField';
import validator from 'validator';

import axios from 'axios';
import { authAction } from '../../actions/AuthAction';
import { getLoggedInToken } from '../../utils/AuthConfig';

const initialUserValues = {
  phone_number: '',
};

const initialErrors = {
  phone_number: '',
};

const BrandSignUp = () => {
  // For dynamic navigatioj
  const navigate = useNavigate();

  const loggedIn = getLoggedInToken();
  //const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      navigate('/brand/dashboard');
    }
  }, [loggedIn, navigate]);

  // {useState} for user object
  const [user, setUser] = useState(initialUserValues);

  const [errors, setErrors] = useState(initialErrors);

  // Google Sign In

  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState([]);

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     console.log(codeResponse);
  //     setGoogleUser(codeResponse);
  //   },
  //   onError: (error) => console.log('Login Failed:', error),
  // });

  // useEffect(() => {
  //   if (googleUser && googleUser.length > 0) {
  //     axios
  //       .get(
  //         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${googleUser.access_token}`,
  //             Accept: 'application/json',
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         console.log(res.data);
  //         setProfile(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [googleUser]);

  function phoneNumberValidation() {
    const phoneNumber = user.phone_number;
    if (validator.isEmpty(phoneNumber)) {
      return 'Please, enter phone number';
    } else if (!validator.isNumeric(phoneNumber) || phoneNumber.length !== 10) {
      return 'Please, enter valid phone number';
    }
    return '';
  }

  const onTapContinueButton = () => {
    setErrors((values) => ({
      ...values,
      phone_number: phoneNumberValidation(),
    }));
    if (phoneNumberValidation() === '') {
      console.log('sdds');
      authAction.OtpSendPhone(user, navigate);
      //navigate('/verify-otp');
    }
  };

  // const onTapGoogleSignInButton = () => {
  //   login();
  // };

  const onTapLoginButton = () => {
    navigate('/brand/login');
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className='signup'>
      <div className='signup-container'>
        <div className='signup-container-left'>
          <div className='signup-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='signup-container-left-content-fields'>
              <div className='signup-header'>
                Register to start
                <br />
                promoting now.
              </div>
              {/* <div className='signup-caption'>
                <span>
                  You must have at least 1000 subscribers in order to sign up
                  for Social Bottle.
                </span>
              </div> */}

              {profile.name && (
                <div className='profile-data'>
                  <span className='welcome-message'>Welcome, </span>
                  <span className='profile-name'>{profile.name} !</span>
                </div>
              )}
              <TextField
                name='phone_number'
                hintText='Phone number'
                variant='phone'
                onChange={handleTextFieldChange}
              />
              {errors.phone_number !== '' && (
                <div className='error-message-container'>
                  {errors.phone_number}
                </div>
              )}

              <div className='continue-button'>
                <button onClick={onTapContinueButton}>Continue</button>
              </div>
              <div className='dont-have-account-container'>
                <span className='dont-have-account-text'>
                  {' '}
                  Already have an account?{' '}
                </span>
                <span
                  className='login-button'
                  onClick={onTapLoginButton}>
                  Login
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='signup-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default BrandSignUp;
