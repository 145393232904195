/** @format */

import React from 'react';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './signup.scss';
import Logo from '../../assets/logo-black.svg';
import GoogleIcon from '../../assets/auth/google-icon.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';

import TextField from '../../components/textfield/TextField';
import validator from 'validator';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { authAction } from '../../store/actions/influencer/authAction';
import { getInfLoggedInToken } from '../../utils/InfluencerAuthConfig';

const initialUserValues = {
  phone_number: '',
};

const initialErrors = {
  phone_number: '',
};

const SignUp = () => {
  // For dynamic navigatioj
  const navigate = useNavigate();
  const loggedIn = getInfLoggedInToken();
  //const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      navigate('/influencer/dashboard');
    }
  }, [loggedIn, navigate]);

  // {useState} for user object
  const [user, setUser] = useState(initialUserValues);

  const [errors, setErrors] = useState(initialErrors);

  // Google Sign In

  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const dispatch = useDispatch();

  function phoneNumberValidation() {
    const phone_number = user.phone_number;
    if (validator.isEmpty(phone_number)) {
      return 'Please, enter phone number';
    } else if (
      !validator.isNumeric(phone_number) ||
      phone_number.length !== 10
    ) {
      return 'Please, enter valid phone number';
    }
    return '';
  }

  const onTapContinueButton = () => {
    setErrors((values) => ({
      ...values,
      phone_number: phoneNumberValidation(),
    }));
    if (phoneNumberValidation() === '') {
      dispatch(authAction.otpSendPhone(user, navigate));
      //navigate('/verify-otp');
    }
  };

  // const onTapGoogleSignInButton = () => {
  //   login();
  // };

  const onTapLoginButton = () => {
    navigate('/influencer/login');
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className='signup'>
      <div className='signup-container'>
        <div className='signup-container-left'>
          <div className='signup-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='signup-container-left-content-fields'>
              <div className='signup-header'>
                Register to start
                <br />
                earning now.
              </div>
              <div className='signup-caption'>
                <span>
                  You must have at least 1000 subscribers in order to sign up
                  for Social Bottle.
                </span>
              </div>

              {profile.name && (
                <div className='profile-data'>
                  <span className='welcome-message'>Welcome, </span>
                  <span className='profile-name'>{profile.name} !</span>
                </div>
              )}
              <TextField
                name='phone_number'
                hintText='Phone number'
                variant='phone'
                onChange={handleTextFieldChange}
              />
              {errors.phone_number !== '' && (
                <div className='error-message-container'>
                  {errors.phone_number}
                </div>
              )}

              <div className='continue-button'>
                <button onClick={onTapContinueButton}>Continue</button>
              </div>
              {/* <div className='or-container'>
                <div className='hr-left'>
                  <hr />
                </div>
                <div className='or-text'>or</div>
                <div className='hr-right'>
                  <hr />
                </div>
              </div> */}
              {/* <div className='login-with-google-container'
                onClick={onTapGoogleSignInButton}>
                <div className='google-icon'>
                  <img
                    src={GoogleIcon}
                    alt=''
                  />
                </div>
                <div className='google-login-text'>Login with Google</div>
              </div> */}
              <div className='dont-have-account-container'>
                <span className='dont-have-account-text'>
                  {' '}
                  Already have an account?{' '}
                </span>
                <span
                  className='login-button'
                  onClick={onTapLoginButton}>
                  Login
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='signup-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
