/** @format */

import React, { useState, useEffect } from 'react';
import './ProfileInput.scss';
import AuthBanner from '../../components/authbanner/AuthBanner';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Logo from '../../assets/logo-black.svg';
import TextField from '../../components/textfield/TextField';
import SelectField from '../../components/selectfield/SelectField';
import validator from 'validator';
import { useNavigate, useParams } from 'react-router-dom';
import { authAction } from '../../actions/AuthAction';
import api from '../../services/HttpService';
import { AUTH } from '../../configs/ApiActionUrl';
import { toast } from 'react-toastify';
import { getRegisterData } from '../../utils/AuthConfig';

const initialUserValues = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  categories: '',
};

const initialErrors = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  categories: '',
};

const optionValue = [
  { value: '01', label: 'NewDelhi' },
  { value: '02', label: 'Chennai' },
  { value: '03', label: 'Bangalore' },
];

const BrandProfileInput = () => {
  const [user, setUser] = useState(initialUserValues);
  const [errors, setErrors] = useState(initialErrors);
  const [options, setOptions] = useState([]);
  const [isAcceptTerms, setAcceptTerms] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  //const updateData = getRegisterData();

  async function verifyLink(module, code) {
    try {
      const res = await api.get(AUTH.VERIFY_LINK + '/' + module + '/' + code);
      if (res.data && res.data.data) {
        // if (updateData.holderId) {
        //   navigate('/brand/verify-email-otp/' + updateData.holderId);
        // }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        navigate('/brand/signup');
        toast.error('Link Expired');
        // You can handle the error here, e.g., toast.error(error.response.data.message);
      }
    }
    return null;
  }

  useEffect(() => {
    setUser((prevValues) => ({ ...prevValues, holderId: id }));
    // JavaScript logic here (the code you provided)
    // const sourceDiv = document.getElementById('container-left');
    // const targetDiv = document.getElementById('container-right');
    // const sourceDivHeight = sourceDiv.getBoundingClientRect().height;
    // console.log(sourceDivHeight);
    // targetDiv.style.maxHeight = sourceDivHeight + 'px';

    verifyLink('register', id);

    async function fetchCategoryOptions() {
      try {
        const res = await api.get(AUTH.CATEGORY);
        if (res.data && res.data.data) {
          const transformedData = res.data.data.map((item) => ({
            value: item._id,
            label: item.name,
          }));
          setOptions(transformedData);
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // You can handle the error here, e.g., toast.error(error.response.data.message);
        }
      }
      return null;
    }

    // async function fetchData() {
    //   const options = await fetchCategoryOptions();
    //   if (options) {
    //     setOptions(options);
    //   }
    // }

    fetchCategoryOptions();
  }, []);

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOnBlurTextField = (e) => {};

  // const [selectedOption, setSelectedOption] = useState(null);
  // var headers = new Headers();
  // headers.append("X-CSCAPI-KEY", "API_KEY");

  // var requestOptions = {
  // method: 'GET',
  // headers: headers,
  // redirect: 'follow'
  // };

  // // Pass Country & State Code -- Eg: Country Code : IN & State Code : MH
  // fetch("https://api.countrystatecity.in/v1/countries/IN/states/MH/cities", requestOptions)
  // .then(response => response.text())
  // .then(result => console.log(result))
  // .catch(error => console.log('error', error));

  const onTabNextButton = () => {
    setErrors((values) => ({
      ...values,
      firstName: firstNameValidation(),
      lastName: lastNameValidation(),
      email: emailValidation(),
      company: companyValidation(),
      categories: categoryValidation(),
    }));
    const paramValue = 'social';
    // navigate(`/verify-otp/${paramValue}`);
    //navigate('/verify-email-otp');
    console.log('dfafasda');
    if (
      firstNameValidation() === '' &&
      lastNameValidation() === '' &&
      emailValidation() === '' &&
      companyValidation() === '' &&
      categoryValidation() === ''
    ) {
      authAction.brandRegister(user, navigate);
    }
  };

  function firstNameValidation() {
    const firstName = user.firstName;
    if (validator.isEmpty(firstName)) {
      return 'Please, enter First Name';
    }
    return '';
  }
  function lastNameValidation() {
    const lastName = user.lastName;
    if (validator.isEmpty(lastName)) {
      return 'Please, enter Last Name';
    }
    return '';
  }
  function emailValidation() {
    const email = user.email;
    if (validator.isEmpty(email)) {
      return 'Please, enter Email';
    } else if (!validator.isEmail(email)) {
      return 'Please, enter valid Email!';
    }
    return '';
  }

  function companyValidation() {
    const company = user.company;
    if (validator.isEmpty(company)) {
      return 'Please, enter Company Name';
    }
    return '';
  }

  console.log(user, 'user');

  // function phoneNumberValidation() {
  //   const phoneNumber = user.phoneNumber;
  //   if (validator.isEmpty(phoneNumber)) {
  //     return 'Please, enter phone number';
  //   } else if (validator.isNumeric(phoneNumber) && phoneNumber.length !== 10) {
  //     return 'Please, enter valid phone number';
  //   }
  //   return '';
  // }

  function categoryValidation() {
    if (!user.categories) {
      return (errors.categories = 'Please select a category');
    }
    return '';
  }

  const onTapAccept = () => {
    setAcceptTerms(!isAcceptTerms);
  };

  function handleSelectFieldChange(name, selectedOption) {
    //const valuesArray = selectedOption.map((item) => item.value);
    setUser((prevValues) => ({ ...prevValues, [name]: selectedOption }));
  }
  return (
    <div className='profile-input'>
      <div className='profile-input-container'>
        <div
          id='container-left'
          className='profile-input-container-left'>
          <div className='profile-input-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='profile-input-container-left-content-fields'>
              <div className='profile-input-header'>Describe your brand</div>
              <div className='profile-name-fileds-container'>
                <div className='name-sec'>
                  <div className='firstname'>
                    <TextField
                      name='firstName'
                      placeholder='FirstName'
                      hintText='First Name'
                      variant='normal'
                      onChange={handleTextFieldChange}
                      onBlur={handleOnBlurTextField}
                    />
                    {errors.firstName !== null && errors.firstName !== '' && (
                      <div className='error-message-container'>
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className='lasttname'>
                    <TextField
                      name='lastName'
                      placeholder='Last Name'
                      hintText='Last Name'
                      variant='normal'
                      onChange={handleTextFieldChange}
                      onBlur={handleOnBlurTextField}
                    />
                    {errors.lastName !== null && errors.lastName !== '' && (
                      <div className='error-message-container'>
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className='mailTextField'>
                  <TextField
                    name='email'
                    placeholder='e.g john@mail.com'
                    hintText='Email'
                    variant='normal'
                    onChange={handleTextFieldChange}
                    onBlur={handleOnBlurTextField}
                  />
                  {errors.email !== null && errors.email !== '' && (
                    <div className='error-message-container'>
                      {errors.email}
                    </div>
                  )}
                </div>
                <TextField
                  name='company'
                  placeholder='Company Name'
                  hintText='Company Name'
                  variant='normal'
                  onChange={handleTextFieldChange}
                  onBlur={handleOnBlurTextField}
                />
                {errors.company !== null && errors.company !== '' && (
                  <div className='error-message-container hhhh'>
                    {errors.company}
                  </div>
                )}
                <div className='categoryTextField'>
                  <SelectField
                    name='categories'
                    options={options}
                    selectedItem={user.categories}
                    multiple={true}
                    placeholder={
                      <div className='placeholder-text'>Select Category</div>
                    }
                    setselectedItem={(selectedOption) =>
                      handleSelectFieldChange('categories', selectedOption)
                    }
                  />
                  {errors.categories !== null && errors.categories !== '' && (
                    <div className='error-message-container'>
                      {errors.categories}
                    </div>
                  )}
                </div>
                <div className='terms-and-condtions-container'>
                  <div
                    className='terms-and-condtions-checkbox'
                    onClick={onTapAccept}>
                    {isAcceptTerms ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </div>
                  <div className='terms-and-condtions-container-text'>
                    I accept{' '}
                    <span className='terms-link'>the offer agreement </span>and
                    give <span className='terms-link'>consent</span> to the
                    pyocessing of my personal data
                  </div>
                </div>
              </div>

              <div className='next-button'>
                <button onClick={onTabNextButton}>Next</button>
              </div>
            </div>
          </div>
        </div>
        <div
          id='container-right'
          className='profile-input-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default BrandProfileInput;
