import React from 'react'
import './blog-detail.scss'
import Articles from '../../../components/articles/Articles'

const articles = [
    {
        id:1,
        title:'Influencer Insights: My Journey to Success',
        crated_at:'08 Oct 2022',
        author:'Cameron Williamson',
        image:'https://cdn.pixabay.com/photo/2015/03/05/01/46/sailing-ship-659758_1280.jpg',
    },
    {
        id:2,
        title:'Beyond the Instagram Grid: The Realities of Influencer Marketing',
        crated_at:'08 Oct 2022',
        author:'Subhash singh',
        image:'https://cdn.pixabay.com/photo/2014/08/05/10/30/iphone-410324_1280.jpg',
    }
];

const BlogDetails = () => {
  return (
    <div className="bloginfo">
            <div className='container'>
                <div className="title">The Power of Authenticity: My Experience as an Influencer</div>
                <div className="created">
                    <span>08 Oct 2022</span>
                    <span>Subhash singh</span>
                </div>
                <div className='blogimage'>
                    <img src='https://www.gmercyu.edu/images/learn_pages/learn_cisheader.jpg' alt=''/>
                </div>
                <div className='info'>
                    <div className='title'>How does Social Bottle work for influencers?</div>
                    <div className='content'>
                    <p>The world of influencer marketing is constantly evolving, and one of the most important aspects to consider when building a successful career as an influencer is authenticity.</p>
                    <p>Being authentic means being true to yourself and your audience. It means sharing your genuine thoughts, feelings, and experiences with your followers, rather than putting on a facade or pretending to be someone you're not. Authenticity is what helps build trust and connection with your audience, and it's what sets you apart from other influencers.</p>
                    <p>My personal experience as an influencer has taught me the value of authenticity. When I first started out, I was tempted to follow the crowd and post content that I thought would be popular, rather than content that truly reflected who I am and what I'm passionate about. But I quickly realized that this approach was not sustainable, and it was not resonating with my audience.</p>
                    <p>I decided to take a step back and reassess my approach. I asked myself, what do I truly want to share with my audience? What are my unique perspectives and experiences? And how can I use my platform to make a positive impact?</p>
                    <p>By embracing authenticity, I've been able to build a community of followers who are genuinely interested in what I have to say, and who feel a connection with me as a person. I've also been able to build stronger partnerships with brands who appreciate the authenticity and integrity of my content.</p>
                    <p>Authenticity is not always easy, and it's not always comfortable. It can be challenging to put yourself out there and be vulnerable with your audience. But in the end, it's worth it, because it's what helps you build a loyal following and a fulfilling career as an influencer.</p>
                    <p>In conclusion, authenticity is the key to success as an influencer, it's a must-have ingredient in your recipe. It helps you to stay true to yourself, build trust with your audience and attract the right brands. Remember, the people who follow you want to see the real you, so don't be afraid to be yourself.</p>
                    </div>
                </div>
                <hr className='horiznatal-line'></hr>
            
            <div className='articles'>
                <div className='sec-title'>Related articles</div>
                <Articles article={articles} />
            </div>
           
        </div>
        </div>
  )
}

export default BlogDetails