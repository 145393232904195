/** @format */

import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import SocialLogo from './../../assets/logo-black.svg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CategoryIcon from '@mui/icons-material/Category';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ApiIcon from '@mui/icons-material/Api';
import { Badge } from '@mui/material';
import { LoginOutlined } from '@mui/icons-material';
import { adminAuthAction } from '../../store/actions/admin/adminAuthAction';
const drawerWidth = 280;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -2,
    top: 9,
    background: '#fff',
    padding: '0 2px',
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function SideBarLayout(props) {
  const [open, setOpen] = React.useState(true);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        open={open}
        sx={{
          bgcolor: '#fff',
          boxShadow: '65px -31px 59.8px 5.2px rgba(0, 0, 0, 0.3)',
        }}>
        <Toolbar>
          <Box
            sx={{
              marginRight: 2,
              marginLeft: 2,
              ...(open && { display: 'none' }),
            }}>
            {/* <img
              src={velanLogoSmall}
              alt='Velan'
            /> */}
          </Box>
          <Box sx={{ bgcolor: 'teritary.main' }}>
            {/* <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                marginRight: 2,
                bgcolor: 'teritary.main',
              }}>
                <MenuOpenIcon />
            </IconButton> */}
          </Box>
          <Typography
            variant='h6'
            noWrap
            component='div'>
            {/* {'Welcome ' + userName} */}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0 }}>
            <Stack
              direction='row'
              spacing={2}
              sx={{ alignItems: 'center', bgcolor: 'teritary.main' }}>
              {/* <Tooltip
                title='Search'
                arrow>
                <IconButton
                  color='inherit'
                  aria-label='open search'
                  onClick={handleSearchOpen}>
                  <i className='icon-search'></i>
                </IconButton>
              </Tooltip>
              <Tooltip
                title='Notifications'
                arrow>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleOpenNotify}>
                  {' '}
                  <StyledBadge
                    badgeContent={notifyData.length}
                    color='secondary'>
                    <NotificationsNoneOutlinedIcon />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Tooltip
                title='Settings'
                arrow>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleOpenUserMenu}>
                  <i className='icon-settings'></i>
                </IconButton>
              </Tooltip> */}
              <Tooltip
                title='Logout'
                arrow>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  sx={{
                    background: '#111827',
                    '&:hover': {
                      color: '#111827',
                      backgroundColor: '#44BBA4',
                    },
                  }}
                  onClick={() => {
                    adminAuthAction.logout();
                  }}>
                  <LoginOutlined sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Menu
              sx={{}}
              id='menu-appbar'
              anchorEl={anchorElUser}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  width: '250px',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <MenuItem
                sx={{ justifyContent: 'space-between' }}
                component={Link}
                to={'/profile'}>
                Account Settings
                <i className='icon-user'></i>
              </MenuItem>

              {/* <MenuItem
                sx={{ justifyContent: 'space-between' }}
                onClick={handleChangePassword}>
                Change Password
                <i className='icon-password'></i>
              </MenuItem> */}

              <MenuItem sx={{ justifyContent: 'space-between' }}>
                Logout
                <i className='icon-logout'></i>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        className='sideMenuList'
        sx={{
          '& .MuiPaper-root': {
            bgcolor: '#ffffff',
            color: '#ffffff',
            borderRight: '0',
            boxShadow: '-31px 0px 59.8px 5.2px rgba(0, 0, 0, 0.3)',
          },
        }}>
        <DrawerHeader sx={{ minHeight: '80px !important', bgcolor: '#fff' }}>
          <Box>
            <img
              src={SocialLogo}
              alt='Social Bottle'
            />
          </Box>

          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </DrawerHeader>
        <Box>
          <List sx={{ p: 0 }}>
            <React.Fragment>
              <ListItem
                component={NavLink}
                to='/admin/dashboard'>
                <ListItemIcon className='menu__icon'>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Dashboard'
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>

              <ListItem
                component={NavLink}
                to='/admin/categories'>
                <ListItemIcon className='menu__icon'>
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Categories'
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
              <ListItem
                component={NavLink}
                to='/admin/requirement'>
                <ListItemIcon className='menu__icon'>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Requirements'
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
              <ListItem
                component={NavLink}
                to='/admin/influencer'>
                <ListItemIcon className='menu__icon'>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Influencer'
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
              <ListItem
                component={NavLink}
                to='/admin/brand'>
                <ListItemIcon className='menu__icon'>
                  <ApiIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Brand'
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItem>
            </React.Fragment>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
