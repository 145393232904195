import React from 'react'
import './HowItWorks.scss'
import DiscoverInfluencer from '../../assets/discover-influencer.svg'
import MakePayment from '../../assets/make-payment.svg'
import ContactInfluencer from '../../assets/contact-influencer.svg'

const featuredData = [
    {path: DiscoverInfluencer, feature: "Discover influencer", description: "We asked our influencers to specify their areas of expertise and passion. Utilize our category or location search engines to find the influencer that most closely meets your requirements."},
    {path: MakePayment, feature: "Make Payment", description: "Influencers have complete pricing transparency; they determine their own rates. Simply reserve them using a credit card or UPI payments. For additional information, see our FAQs."},
    {path: ContactInfluencer, feature: "Contact influencer", description: "To communicate your brief with influencers, use our chat feature. Make an effort to be as thorough as you can. Use our Media Upload feature if you have media to contribute."},

]

const HowItWorks = () => {
  return (
    <div className="howItWorks">
        <div className="container">
            <div className="header">Here's How It Works</div>
            <div className="title">It is made simple to harness the power of creators. Utilize our robust dashboard to manage all aspects of your influencer campaign.</div>
            <div className="featured">
                {
                    featuredData.map((item, index) =>(
                        <FeaturedCard path={item.path} feature={item.feature} description={item.description}/>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

const FeaturedCard = ({path, feature, description})=>{
    return (
        <div className="featuredCard">
            <img src={path} alt="" />
            <div className="feature">{feature}</div>
            <div className="description">{description}</div>
        </div>
    )
}


export default HowItWorks