/** @format */

import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SideBarLayout from './Sidebar';
import Dashboard from '../pages/Dashboard';
import CategoriesList from '../pages/Categories';
import './adminLayout.scss';
import RequirementsList from '../pages/Requirements';
import ApproveInfluencerList from '../pages/ApproveInfluencer';
import InfluencerEdit from '../pages/ApproveInfluencer/edit';
import ApproveBrandList from '../pages/ApproveBrand';
import BrandEdit from '../pages/ApproveBrand/edit';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const HeaderLayout = () => {
  const dispatch = useDispatch();
  const drawerWidth = 280;

  return (
    <Box sx={{ display: 'flex', background: '#f7f7f7', minHeight: '100vh' }}>
      <SideBarLayout />
      <Box
        className='contnenWrap'
        component='main'
        sx={{
          flexGrow: 1,
          p: 6,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        <DrawerHeader />
        <div className='content'>
          <Routes>
            <Route
              exact
              path='/dashboard'
              element={<Dashboard />}
            />
            <Route
              path='/categories'
              exact
              element={<CategoriesList />}
            />
            <Route
              path='/requirement'
              exact
              element={<RequirementsList />}
            />
            <Route
              path='/influencer'
              exact
              element={<ApproveInfluencerList />}
            />
            <Route
              path='/influencer/edit/:code'
              exact
              element={<InfluencerEdit />}
            />

            <Route
              path='/brand'
              exact
              element={<ApproveBrandList />}
            />
            <Route
              path='/brand/edit/:code'
              exact
              element={<BrandEdit />}
              BrandEdit
            />
            <Route
              path='*'
              element={<Navigate to='/404' />}
            />
            {/* <Route
              path="/unauthorized"
              element={<Navigate to="/unauthorized" />}
            /> */}
          </Routes>
        </div>
      </Box>
    </Box>
  );
};
