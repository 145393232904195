/** @format */

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.scss';
import Logo from '../../assets/logo-black.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Login } from '@mui/icons-material';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React?.useState(null) ?? '';
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  // const onTapLogin = ()=>{
  //   navigate('/login')
  // }

  return (
    <div className='navbar'>
      <div className='container'>
        <div className='logo'>
          <img
            src={Logo}
            alt='Logo'
          />
        </div>
        <div className='links'>
          <div className='leftLinks'>
            <Link
              className='link'
              to='/brand'>
              <span>For Brand</span>
            </Link>
            <Link
              className='link'
              to='/influencer'>
              <span>For Influncers</span>
            </Link>
            <div className='more'>
              {' '}
              <span className='link'>more</span> <KeyboardArrowDownIcon />
            </div>
          </div>
          {/* <div className='rightLinks'>
            <div
              className='login'
              onClick={handleClick}>
              <span>Login</span> <KeyboardArrowDownIcon />
            </div>
            <Menu
              anchorEl={anchorEl}
              id='account-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate('/brand/login');
                }}>
                <ListItemIcon>
                  <Login fontSize='small' />
                </ListItemIcon>
                <ListItemText>Brand</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate('/influencer/login');
                }}>
                <ListItemIcon>
                  <Login fontSize='small' />
                </ListItemIcon>
                <ListItemText>Influencer</ListItemText>
              </MenuItem>
            </Menu>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
