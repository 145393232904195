/** @format */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  TextField,
  Autocomplete,
  Tooltip,
  Chip,
  Typography,
  MenuItem,
  CardActions,
  Button,
  Avatar,
  Stack,
  CircularProgress,
  IconButton,
} from '@mui/material';
import BigLogo from '../../assets/biglogo.png';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { CampaignAction } from '../../store/actions/brand/campaignAction';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGrid } from '@mui/x-data-grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { setRegisterData } from '../../utils/AuthConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Loader } from '../../utils/Loader';
import UploadMedia from './UploadMedia';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix='$'
    />
  );
});

const columns = [
  {
    field: 'userProfile',
    headerName: 'userProfile',
    flex: 0.05,
    width: 50,
    renderCell: (params) => {
      console.log(params);
      return (
        <>
          <Avatar
            src={params.value}
            variant='rounded'
          />
        </>
      );
    },
  },
  {
    field: 'name',
    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <Typography
            sx={{ textTransform: 'capitalize' }}
            variant='h2'
            component={'h2'}>
            {params.value}
          </Typography>
        </>
      );
    },
  },
  {
    field: 'date',
    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <Typography
            variant='body1'
            component={'p'}>
            28/10/2022
          </Typography>
        </>
      );
    },
  },
  {
    field: 'status',
    flex: 1,
    renderCell: (params) => {
      return (
        <>
          <Button className='pending'>Pending</Button>
        </>
      );
    },
  },
  // Add more columns as needed
];

const rows = [
  { id: 1, firstName: 'John', lastName: 'Doe' },
  { id: 2, firstName: 'Jane', lastName: 'Doe' },
  { id: 3, firstName: 'John', lastName: 'Doe' },
  { id: 4, firstName: 'Jane', lastName: 'Doe' },
  { id: 5, firstName: 'John', lastName: 'Doe' },
  { id: 6, firstName: 'Jane', lastName: 'Doe' },
  { id: 7, firstName: 'John', lastName: 'Doe' },
  { id: 8, firstName: 'Jane', lastName: 'Doe' },
  { id: 9, firstName: 'John', lastName: 'Doe' },
  { id: 10, firstName: 'Jane', lastName: 'Doe' },
  { id: 11, firstName: 'John', lastName: 'Doe' },
  { id: 12, firstName: 'Jane', lastName: 'Doe' },
  { id: 13, firstName: 'John', lastName: 'Doe' },
  { id: 14, firstName: 'Jane', lastName: 'Doe' },
  { id: 15, firstName: 'John', lastName: 'Doe' },
  { id: 16, firstName: 'Jane', lastName: 'Doe' },
  { id: 17, firstName: 'John', lastName: 'Doe' },
  { id: 18, firstName: 'Jane', lastName: 'Doe' },

  // Add more rows as needed
];

const pageSize = 2; // Number of rows per page

function InfluencerCampaignDetails() {
  const [itemData, setItemData] = useState([]);
  const [value, setValue] = React.useState('1');
  const [paymentState, setPaymentState] = useState('product');
  const [approvedData, setApprovedData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [itemID, setItemID] = useState('');

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const visibleRows = rows.slice(startIndex, endIndex);

  useEffect(() => {
    if (id) {
      dispatch(CampaignAction.getItemCampaign(id));
    }
  }, [dispatch, id]);

  const { campaignItemData, isLoading } = useSelector(
    (state) => state.influencercampaign
  );

  useEffect(() => {
    if (campaignItemData && campaignItemData.data) {
      setItemData(campaignItemData.data);
      const { influencers } = campaignItemData.data;
      setApprovedData(influencers?.approved || []);
      setPendingData(influencers?.pending || []);
      setRejectedData(influencers?.rejected || []);
    }
  }, [campaignItemData]);

  // const colors = ['red', 'blue', 'purple'];
  // const categWithColors = categOptions.map((item, index) => ({
  //   ...item,
  //   color: colors[index % colors.length], // Assign colors in a cyclic manner
  // }));

  return (
    <div className='mainWrap'>
      <div className='header'>
        <div className='container'>
          <Button
            variant='text'
            startIcon={<ArrowBackIosNewSharpIcon />}
            component={Link}
            to='/influencer/dashboard'>
            Back
          </Button>
          {/* <Typography
            variant='h2'
            component={'h2'}>
            All Campaign
          </Typography> */}
        </div>
      </div>
      <div className='mainContent'>
        {isLoading ? <Loader /> : ''}
        <div className='container'>
          <Box className='compaignDetailWrap'>
            <Card className='topCard'>
              <CardHeader
                action={
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={(e) => {
                      setUploadOpen(true);
                      // if (itemData && itemData.id) {
                      //   setItemID(itemData.id);
                      // }
                    }}>
                    Submit your entry
                    {/* {'Budget ₹ ' + (itemData && itemData.budget)} */}
                  </Button>
                }
                title='Campaign name'
                subheader={itemData && itemData.name}
              />
              <CardContent>
                <Typography
                  variant='body1'
                  component='h3'>
                  <span className='categTitle'>Categories : </span>
                  {itemData.categories &&
                    itemData.categories.map((item, idx) => {
                      return (
                        <span
                          key={idx}
                          className='categItem'>
                          {item.name}
                          {idx < itemData.categories.length - 1 && ', '}
                        </span>
                      );
                    })}
                </Typography>
              </CardContent>
            </Card>
            <Card
              className='midCard'
              sx={{}}>
              <CardContent>
                <Typography
                  variant='h3'
                  component={'h3'}>
                  Campaign Description
                </Typography>
                <Typography
                  variant='body2'
                  component={'div'}
                  color='text.secondary'
                  dangerouslySetInnerHTML={{
                    __html: itemData && itemData.description,
                  }}
                />

                <Box>
                  <Typography
                    variant='h3'
                    sx={{ color: '#DD6E69' }}
                    component={'h3'}>
                    Forbidden
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ p: 3, background: '#FCF3F4' }}
                    component={'div'}
                    color='text.secondary'
                    dangerouslySetInnerHTML={{
                      __html: itemData && itemData.forbidden,
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant='h3'
                    sx={{ color: '#000' }}
                    component={'h3'}>
                    Required for content
                  </Typography>
                  <Typography
                    variant='body2'
                    // sx={{ p: 3, background: '#FCF3F4' }}
                    component={'div'}
                    color='text.secondary'
                    dangerouslySetInnerHTML={{
                      __html: itemData && itemData.requiredFContent,
                    }}
                  />
                </Box>
                <Typography
                  variant='h3'
                  component={'h3'}>
                  Campaign Requirements
                </Typography>
                <Stack
                  sx={{ mb: 2 }}
                  direction='row'
                  spacing={2}>
                  {itemData.requirementType &&
                    itemData.requirementType.map((item, idx) => {
                      return (
                        <Chip
                          key={idx}
                          label={item.name}
                          color='primary'
                          sx={{
                            color: '#fff',
                          }}
                          //color='primary'
                        />
                      );
                    })}
                </Stack>
              </CardContent>
              {/* <CardContent className='talentsWrap'>
                <Stack
                  sx={{
                    mb: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  direction='row'
                  spacing={2}>
                  <Typography
                    sx={{ mb: '0 !important' }}
                    variant='h3'
                    component={'h3'}>
                    Talents
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={(e) => {
                      setTalentOpen(true);
                      // if (itemData && itemData.id) {
                      //   setItemID(itemData.id);
                      // }
                    }}
                    endIcon={<AddIcon />}
                    color='primary'>
                    {'Add'}
                  </Button>
                </Stack>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <TabList onChange={(event, newValue) => setValue(newValue)}>
                      <Tab
                        className='approved'
                        //={`Approved (${approvedData.length})`}
                        label={`Approved`}
                        value='1'
                        icon={
                          <IconButton sx={{ background: '#44BBA4' }}>
                            {approvedData.length}
                          </IconButton>
                        }
                        iconPosition='end'
                      />
                      <Tab
                        className='rejected'
                        //label={`Rejected (${rejectedData.length})`}
                        label={`Rejected`}
                        icon={
                          <IconButton sx={{ background: '#f92936' }}>
                            {rejectedData.length}
                          </IconButton>
                        }
                        iconPosition='end'
                        value='3'
                      />
                      <Tab
                        className='pending'
                        label={`Waiting`}
                        icon={
                          <IconButton sx={{ background: '#455a64' }}>
                            {pendingData.length}
                          </IconButton>
                        }
                        iconPosition='end'
                        value='2'
                      />
                    </TabList>

                    <TabPanel value='1'>
                      <Box className='commnTable hideHeader'>
                        {renderDataGrid(approvedData)}
                        <Pagination
                          count={Math.ceil(approvedData.length / pageSize)}
                          page={currentPage}
                          onChange={handleChangePage}
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                          variant='text'
                          shape='rounded'
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value='2'>
                      <Box className='commnTable hideHeader'>
                        {renderDataGrid(pendingData)}
                        <Pagination
                          count={Math.ceil(pendingData.length / pageSize)}
                          page={currentPage}
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                          onChange={handleChangePage}
                          variant='text'
                          shape='rounded'
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value='3'>
                      <Box className='commnTable hideHeader'>
                        {renderDataGrid(rejectedData)}
                        <Pagination
                          count={Math.ceil(rejectedData.length / pageSize)}
                          page={currentPage}
                          onChange={handleChangePage}
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                          variant='text'
                          shape='rounded'
                        />
                      </Box>
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardContent> */}
            </Card>
          </Box>
        </div>
      </div>
      {uploadOpen ? (
        <UploadMedia
          open={uploadOpen}
          itemID={id}
          onCloseModal={() => setUploadOpen(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
}
export default InfluencerCampaignDetails;
