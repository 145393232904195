/** @format */
import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import FeedBackLeft from '../../assets/feedback_left.png';

const InfluencerFeedback = () => {
  return (
    <Box className='feedBackSection'>
      <div className='container'>
        <Typography
          variant='h2'
          component={'h2'}>
          Dreamer’s Feedback
        </Typography>
        <Typography
          variant='body1'
          component={'p'}>
          See what influencers say about their collaboration with Social Bottle.
        </Typography>
        <Grid
          container
          alignItems={'center'}
          spacing={2}>
          <Grid
            sx={{ textAlign: 'center' }}
            item
            xs={6}
            md={6}>
            <img src={FeedBackLeft} />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            className='feedbacks'>
            <Typography
              component={'h4'}
              variant={'h4'}>
              Theerthanadharani
            </Typography>
            <Typography
              component={'h5'}
              variant={'h5'}>
              3,06k subscribers
            </Typography>
            <Typography
              component={'p'}
              variant={'body1'}>
              Would like to thank Anurag Sinha for introducing such a wonderful
              platform called Social Bottle. I appreciate the clarity you
              provide for project tasks, you clearly answer all my doubts
              regarding the projects, and I look forward to working on more
              projects with you. Thanks for taking the time to talk with me
              about task concerns. ❤️
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default InfluencerFeedback;
