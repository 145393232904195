import React from 'react'
import './joincard.scss'

const JoinCard = ({header, content, buttonTitle, onTap}) => {
  return (
    <div className="joincard">
        <div className="container">
            <div className="header">{header}</div>
            <div className="content">{content}</div>
            <button className="button" onClick={onTap}>{buttonTitle}</button>
        </div>
    </div>
  )
}

export default JoinCard