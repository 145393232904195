/** @format */

import React, { useState } from 'react';
import BrandPromoBanner from '../../assets/brand-promo-banner.png';
import Hand from '../../assets/hand-shake.svg';
import Card from '../../assets/credit-card.svg';
import Point from '../../assets/hand-pointing.svg';
import Bulb from '../../assets/bulb.svg';

const promos = [
  {
    heading: 'Discover influencer',
    icon: Point,
    description:
      'We asked our influencers to specify their areas of expertise and passion.',
  },
  {
    heading: 'Make payment',
    icon: Card,
    description:
      'You can now select well-known brands to work with. You can earn as much money by promoting them as you like.',
  },
  {
    heading: 'Contact influencer directly',
    icon: Hand,
    description:
      'You can now select well-known brands to work with. You can earn as much money by promoting them as you like.',
  },
  {
    heading: 'Get results',
    icon: Bulb,
    description:
      'You can now select well-known brands to work with. You can earn as much money by promoting them as you like.',
  },
];

const BrandPromoSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className='brandPromoSection'>
      <div className='container'>
        <div className='header'>Pay influencers only and you get results</div>
        <div className='title'>
          Working with macro-influencers typically entails paying them a set sum
          for a post and hoping for the best. We have changed the game thanks to
          our 250k+ micro-influencers. You may now select from a range of
          payment types and manage the return on investment for your campaigns.
        </div>
        <div className='promoContainer'>
          <div className='left'>
            <img
              src={BrandPromoBanner}
              alt='banner'
            />
          </div>
          <div className='right'>
            {promos.map((promo, index) => (
              <BrandPromoCard
                id={index}
                isSelected={index === selectedIndex}
                promo={promo}
                onClick={() => {
                  setSelectedIndex(index);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const BrandPromoCard = ({ promo, isSelected, onClick }) => {
  return (
    <div
      className={
        isSelected ? 'influencerPromoCard selected' : 'influencerPromoCard'
      }
      onClick={onClick}>
      <div className='promoCardContainer'>
        <div className='logo'>
          <img
            src={promo.icon}
            alt=''
          />
        </div>
        <div className='content'>
          <div className='heading'>{promo.heading}</div>
          {isSelected && (
            <div className='promoContent'>{promo.description}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandPromoSection;
