/** @format */

export const approveBrandTypes = {
  GETALL_APPROVE_BRAND_REQUEST: 'GETALL_APPROVE_BRAND_REQUEST',
  GETALL_APPROVE_BRAND_SUCCESS: 'GETALL_APPROVE_BRAND_SUCCESS',
  GETALL_APPROVE_BRAND_ERROR: 'GETALL_APPROVE_BRAND_ERROR',

  EDIT_APPROVE_BRAND_REQUEST: 'EDIT_APPROVE_BRAND_REQUEST',
  EDIT_APPROVE_BRAND_SUCCESS: 'EDIT_APPROVE_BRAND_SUCCESS',
  EDIT_APPROVE_BRAND_ERROR: 'EDIT_APPROVE_BRAND_ERROR',

  UPDATE_APPROVE_BRAND_REQUEST: 'UPDATE_APPROVE_BRAND_REQUEST',
  UPDATE_APPROVE_BRAND_SUCCESS: 'UPDATE_APPROVE_BRAND_SUCCESS',
  UPDATE_APPROVE_BRAND_ERROR: 'UPDATE_APPROVE_BRAND_ERROR',
};
