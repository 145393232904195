/** @format */

export const authTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  OTP_SEND_PHONE_REQUEST: 'OTP_SEND_PHONE_REQUEST',
  OTP_SEND_PHONE_SUCCESS: 'OTP_SEND_PHONE_SUCCESS',
  OTP_SEND_PHONE_ERROR: 'OTP_SEND_PHONE_ERROR',

  OTP_VERIFY_PHONE_REQUEST: 'OTP_VERIFY_PHONE_REQUEST',
  OTP_VERIFY_PHONE_SUCCESS: 'OTP_VERIFY_PHONE_SUCCESS',
  OTP_VERIFY_PHONE_ERROR: 'OTP_VERIFY_PHONE_ERROR',

  OTP_VERIFY_EMAIL_REQUEST: 'OTP_VERIFY_EMAIL_REQUEST',
  OTP_VERIFY_EMAIL_SUCCESS: 'OTP_VERIFY_EMAIL_SUCCESS',
  OTP_VERIFY_EMAIL_ERROR: 'OTP_VERIFY_EMAIL_ERROR',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',

  CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_ERROR: 'CHANGE_EMAIL_ERROR',

  FETCH_CITIES_REQUEST: 'FETCH_CITIES_REQUEST',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_ERROR: 'FETCH_CITIES_ERROR',

  GET_USER_EMAIL_REQUEST: 'GET_USER_EMAIL_REQUEST',
  GET_USER_EMAIL_SUCCESS: 'GET_USER_EMAIL_SUCCESS',
  GET_USER_EMAIL_ERROR: 'GET_USER_EMAIL_ERROR',

  GET_VERIFY_LINK_REQUEST: 'GET_VERIFY_LINK_REQUEST',
  GET_VERIFY_LINK_SUCCESS: 'GET_VERIFY_LINK_SUCCESS',
  GET_VERIFY_LINK_ERROR: 'GET_VERIFY_LINK_ERROR',

  FETCH_TOPICS_REQUEST: 'FETCH_TOPICS_REQUEST',
  FETCH_TOPICS_SUCCESS: 'FETCH_TOPICS_SUCCESS',
  FETCH_TOPICS_ERROR: 'FETCH_TOPICS_ERROR',

  FETCH_LANGUAGE_REQUEST: 'FETCH_LANGUAGE_REQUEST',
  FETCH_LANGUAGE_SUCCESS: 'FETCH_LANGUAGE_SUCCESS',
  FETCH_LANGUAGE_ERROR: 'FETCH_LANGUAGE_ERROR',

  SOCIAL_CONNECT_REQUEST: 'SOCIAL_CONNECT_REQUEST',
  SOCIAL_CONNECT_SUCCESS: 'SOCIAL_CONNECT_SUCCESS',
  SOCIAL_CONNECT_ERROR: 'SOCIAL_CONNECT_ERROR',

  POST_TOPIC_LANG_REQUEST: 'POST_TOPIC_LANG_REQUEST',
  POST_TOPIC_LANG_SUCCESS: 'POST_TOPIC_LANG_SUCCESS',
  POST_TOPIC_LANG_ERROR: 'POST_TOPIC_LANG_ERROR',

  FETCH_INF_REQUIREMENT_REQUEST: 'FETCH_INF_REQUIREMENT_REQUEST',
  FETCH_INF_REQUIREMENT_SUCCESS: 'FETCH_INF_REQUIREMENT_SUCCESS',
  FETCH_INF_REQUIREMENT_ERROR: 'FETCH_INF_REQUIREMENT_ERROR',

  // LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  // LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  // LOGOUT_ERROR: 'LOGOUT_ERROR',

  // FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  // FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  // FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  // GET_RESET_PASSWORD_REQUEST: 'GET_RESET_PASSWORD_REQUEST',
  // GET_RESET_PASSWORD_SUCCESS: 'GET_RESET_PASSWORD_SUCCESS',
  // GET_RESET_PASSWORD_ERROR: 'GET_RESET_PASSWORD_ERROR',

  // RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  // RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  // RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
};
