/** @format */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  TextField,
  Autocomplete,
  Tooltip,
  Chip,
  Typography,
  MenuItem,
  CardActions,
  Button,
  CircularProgress,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import BigLogo from '../../assets/biglogo.png';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { CampaignAction } from '../../store/actions/brand/campaignAction';
import CloseIcon from '@mui/icons-material/Close';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CampaignAddValidate } from '../../utils/Validation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix='₹'
    />
  );
});

function CampaignAdd() {
  const [categOptions, setCategOptions] = useState([]);
  const [influOptions, setInfluOptions] = useState([]);
  const [requireOptions, setRequireOptions] = useState([]);
  const [campignDesc, setCampignDesc] = React.useState('');
  const [campignForbidden, setCampignForbidden] = React.useState('');
  const [campignRequire, setCampignRequire] = React.useState('');
  const [paymentState, setPaymentState] = useState('product');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CampaignAction.fetchCategoriesOptions());
    dispatch(CampaignAction.fetchInfluencerOptions());
    dispatch(CampaignAction.fetchInRequirementsOptions());
  }, [dispatch]);

  const { categoryData, isLoading, influencerData, requirementData } =
    useSelector((state) => state.brandcampaign);

  useEffect(() => {
    if (categoryData && categoryData.data) {
      setCategOptions(categoryData.data);
    }

    if (influencerData && influencerData.data) {
      setInfluOptions(influencerData.data);
    }

    if (requirementData && requirementData.data) {
      setRequireOptions(requirementData.data);
    }
  }, [categoryData, influencerData, requirementData]);

  // const colors = [
  //   'red',
  //   'blue',
  //   'purple',
  //   'green',
  //   'yellow',
  //   'orange',
  //   'pink',
  //   'brown',
  //   'teal',
  //   'cyan',
  //   'maroon',
  //   'navy',
  //   'olive',
  //   'silver',
  //   'gold',
  //   'indigo',
  //   'violet',
  //   'magenta',
  //   'turquoise',
  //   'coral',
  //   'lime',
  // ];
  // const categWithColors = categOptions.map((item, index) => ({
  //   ...item,
  //   color: colors[index % colors.length], // Assign colors in a cyclic manner
  // }));

  // Function to shuffle an array using the Fisher-Yates algorithm
  // function shuffleArray(array) {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]];
  //   }
  //   return array;
  // }

  // const colors = [
  //   'red',
  //   'blue',
  //   'purple',
  //   'green',
  //   'yellow',
  //   'orange',
  //   'pink',
  //   'brown',
  //   'teal',
  //   'cyan',
  //   'maroon',
  //   'navy',
  //   'olive',
  //   'silver',
  //   'gold',
  //   'indigo',
  //   'violet',
  //   'magenta',
  //   'turquoise',
  //   'coral',
  //   'lime',
  // ];

  // // Shuffle the colors array
  // const shuffledColors = shuffleArray(colors);

  // const categWithColors = categOptions.map((item, index) => ({
  //   ...item,
  //   color: shuffledColors[index % shuffledColors.length], // Assign colors in a cyclic manner
  // }));

  // const influenData = influOptions.map((item, index) => ({
  //   ...item,
  //   color: shuffledColors[index % shuffledColors.length], // Assign colors in a cyclic manner
  // }));

  const handleChangeDesc = (dataContent) => {
    setCampignDesc(dataContent);
  };

  const handleChangeForbidden = (dataContent) => {
    setCampignForbidden(dataContent);
  };

  const handleChangeRequire = (dataContent) => {
    setCampignRequire(dataContent);
  };

  return (
    <div>
      <Box className='campaignAddWrap'>
        <div className='container'>
          <Typography
            variant='h1'
            component={'h1'}>
            Create Campaign
          </Typography>
          <Grid
            container
            rowSpacing={26}
            sx={{
              mt: 0,
              alignItems: 'center',
              '.MuiChip-root': {
                '.MuiChip-deleteIcon': {
                  color: '#fff',
                  fontSize: '18px',
                },
              },
            }}
            columnSpacing={3}>
            <Grid
              className=''
              sx={{ pt: '', textAlign: 'center' }}
              item
              xs={6}
              md={6}>
              <img
                src={BigLogo}
                alt='logo'
              />
            </Grid>
            <Grid
              className=''
              sx={{ pt: '60px !important' }}
              item
              xs={6}
              md={6}>
              <Card className='parentCardWrap'>
                <CardContent>
                  <Formik
                    initialValues={{
                      name: '',
                      description: campignDesc,
                      forbidden: campignForbidden,
                      requiredFContent: campignRequire,
                      categories: [],
                      requirement: '',
                      influencers: [],
                      budget: '',
                      //paymentType: paymentState,
                    }}
                    // validationSchema={CampaignAddValidate}
                    // enableReinitialize
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      console.log(values, 'values');
                      // let form_data = new FormData();

                      let influArr = [];
                      if (values.influencers) {
                        values.influencers.map((influencer) => {
                          if (influencer !== null) {
                            influArr.push(influencer.id);
                          }
                          return true;
                        });
                      }
                      let categoriesArr = [];
                      if (values.categories) {
                        values.categories.map((categ) => {
                          if (categ !== null) {
                            categoriesArr.push(categ._id);
                          }
                          return true;
                        });
                      }
                      let requireArr = [];
                      if (values.requirement) {
                        values.requirement.map((require) => {
                          if (require !== null) {
                            requireArr.push(require._id);
                          }
                          return true;
                        });
                      }
                      values.categories = categoriesArr;
                      values.influencers = influArr;
                      values.requirement = requireArr;
                      values.description = campignDesc;
                      values.forbidden = campignForbidden;
                      values.requiredFContent = campignRequire;

                      dispatch(
                        CampaignAction.add(
                          values,
                          setErrors,
                          setSubmitting,
                          navigate
                        )
                      );
                    }}>
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Card
                          sx={{}}
                          variant='outlined'
                          className='formWrapper'>
                          {/* <CardHeader title='Add' /> */}
                          <CardContent>
                            <Box>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Campaign Name
                              </FormLabel>
                              <TextField
                                error={errors.name && touched.name}
                                label=''
                                fullWidth
                                name='name'
                                value={values.name}
                                placeholder='Campaign Name'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.name && touched.name && errors.name
                                }
                                margin='normal'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                              />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Campaign Description
                              </FormLabel>
                              <CKEditor
                                editor={ClassicEditor}
                                data={campignDesc}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    'Editor is ready to use!',
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChangeDesc(data);
                                  // console.log(event);
                                }}
                                onBlur={(event, editor) => {
                                  //console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                  //console.log('Focus.', editor);
                                }}
                              />
                              {/* <TextField
                                error={
                                  errors.description && touched.description
                                }
                                label=''
                                fullWidth
                                name='description'
                                value={values.description}
                                rows={4}
                                multiline
                                //maxRows={4}
                                placeholder='Campaign Description'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.description &&
                                  touched.description &&
                                  errors.description
                                }
                                margin='normal'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                              /> */}
                            </Box>
                            <Box sx={{ mb: 2 }}>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Forbidden
                              </FormLabel>
                              <CKEditor
                                editor={ClassicEditor}
                                data={campignForbidden}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    'Editor is ready to use!',
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChangeForbidden(data);
                                  //console.log(event);
                                }}
                                onBlur={(event, editor) => {
                                  //console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                  //console.log('Focus.', editor);
                                }}
                              />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Required for content
                              </FormLabel>
                              <CKEditor
                                editor={ClassicEditor}
                                data={campignRequire}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    'Editor is ready to use!',
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChangeRequire(data);
                                }}
                                onBlur={(event, editor) => {
                                  //console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                  //console.log('Focus.', editor);
                                }}
                              />
                            </Box>
                            <Box>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Select categories
                              </FormLabel>
                              <Field
                                name='categories'
                                id='categories'
                                component={Autocomplete}
                                className='tagsInputField'
                                multiple
                                limitTags={2}
                                options={categOptions}
                                disableClearable
                                onChange={(e, newvalue) => {
                                  console.log(newvalue, 'newvalue newvalue');
                                  //if (newvalue && newvalue._id) {
                                  //console.log(newvalue.id, "newvalue newvalue");
                                  setFieldValue('categories', newvalue);
                                  //}
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                  return (
                                    <li
                                      {...props}
                                      key={option._id}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                  return tagValue.map((option, index) => (
                                    <Tooltip
                                      title={option.name}
                                      arrow
                                      placement='top'>
                                      <Chip
                                        //size='small'
                                        sx={{
                                          background: option.color,
                                          color: '#fff',
                                        }}
                                        {...getTagProps({ index })}
                                        key={option._id}
                                        label={option.name}
                                        deleteIcon={<CloseIcon />}
                                      />
                                    </Tooltip>
                                  ));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name='categories'
                                    error={
                                      touched['categories'] &&
                                      !!errors['categories']
                                    }
                                    helperText={
                                      touched['categories'] &&
                                      errors['categories']
                                    }
                                    label=''
                                    placeholder='Select Categories'
                                    variant='outlined'
                                    margin='normal'
                                    required={false}
                                    InputLabelProps={{ required: true }}
                                    // SelectProps={{
                                    //   IconComponent: ExpandMore,
                                    // }}
                                  />
                                )}
                              />
                              {/* <TextField
                                label=''
                                fullWidth
                                name='categories'
                                select
                                onChange={(e) => {
                                  setFieldValue('categories', e.target.value);
                                  //handleTechChange(e);
                                  setFieldValue('approver', '');
                                }}
                                value={values.categories}
                                onBlur={handleBlur}
                                margin='normal'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                                error={errors.categories && touched.categories}
                                helperText={
                                  errors.categories &&
                                  touched.categories &&
                                  errors.categories
                                }>
                                <MenuItem value=''></MenuItem>
                              </TextField> */}
                            </Box>
                            <Box>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                requirement
                              </FormLabel>
                              <Field
                                name='requirement'
                                id='requirement'
                                component={Autocomplete}
                                className='tagsInputField'
                                multiple
                                limitTags={2}
                                options={requireOptions}
                                disableClearable
                                onChange={(e, newvalue) => {
                                  console.log(newvalue, 'newvalue newvalue');
                                  //if (newvalue && newvalue._id) {
                                  //console.log(newvalue.id, "newvalue newvalue");
                                  setFieldValue('requirement', newvalue);
                                  //}
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                  return (
                                    <li
                                      {...props}
                                      key={option._id}>
                                      {option.name}
                                    </li>
                                  );
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                  return tagValue.map((option, index) => (
                                    <Tooltip
                                      title={option.name}
                                      arrow
                                      placement='top'>
                                      <Chip
                                        color='primary'
                                        //size='small'
                                        sx={{
                                          color: '#fff',
                                        }}
                                        {...getTagProps({ index })}
                                        key={option._id}
                                        label={option.name}
                                        deleteIcon={<CloseIcon />}
                                      />
                                    </Tooltip>
                                  ));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name='requirement'
                                    error={
                                      touched['requirement'] &&
                                      !!errors['requirement']
                                    }
                                    helperText={
                                      touched['requirement'] &&
                                      errors['requirement']
                                    }
                                    label=''
                                    placeholder='Select Requirement'
                                    variant='outlined'
                                    margin='normal'
                                    required={false}
                                    InputLabelProps={{ required: true }}
                                    // SelectProps={{
                                    //   IconComponent: ExpandMore,
                                    // }}
                                  />
                                )}
                              />
                              {/* <TextField
                                error={
                                  errors.requirement && touched.requirement
                                }
                                label=''
                                fullWidth
                                name='requirement'
                                value={values.requirement}
                                placeholder='requirement'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.requirement &&
                                  touched.requirement &&
                                  errors.requirement
                                }
                                margin='normal'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                              /> */}
                            </Box>
                            <Box>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Budget
                              </FormLabel>
                              <TextField
                                label=''
                                fullWidth
                                name='budget'
                                onChange={(e) => {
                                  handleChange(e);
                                  //setTeamValue(e.target.value);
                                  setFieldValue('budget', e.target.value);
                                }}
                                value={values.budget}
                                onBlur={handleBlur}
                                margin='normal'
                                variant='outlined'
                                required={false}
                                error={errors.budget && touched.budget}
                                helperText={
                                  errors.budget &&
                                  touched.budget &&
                                  errors.budget
                                }
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                }}
                                InputLabelProps={{
                                  required: true,
                                  shrink: false,
                                }}></TextField>
                            </Box>
                            <Box>
                              <FormLabel
                                id=''
                                sx={{ mb: 1 }}>
                                Talents
                              </FormLabel>
                              <Field
                                name='influencers'
                                id='influencers'
                                component={Autocomplete}
                                className='tagsInputField'
                                multiple
                                limitTags={2}
                                options={influOptions}
                                disableClearable
                                onChange={(e, newvalue) => {
                                  //console.log(newvalue, "newvalue newvalue");
                                  //if (newvalue && newvalue._id) {
                                  //console.log(newvalue.id, "newvalue newvalue");
                                  setFieldValue('influencers', newvalue);
                                  //}
                                }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                  console.log(option, 'option');
                                  return (
                                    <ListItem
                                      {...props}
                                      key={option._id}
                                      secondaryAction={
                                        <Button
                                          variant='text'
                                          sx={{ color: '#000' }}
                                          color='secondary'>
                                          {'₹ ' + option?.from}
                                        </Button>
                                      }>
                                      <ListItemAvatar>
                                        <Avatar
                                          sx={{ width: 35, height: 35 }}
                                          src={
                                            option?.userProfile?.[0]
                                          }></Avatar>
                                      </ListItemAvatar>
                                      <ListItemText primary={option.name} />
                                    </ListItem>
                                  );
                                }}
                                renderTags={(tagValue, getTagProps) => {
                                  return tagValue.map((option, index) => (
                                    <Tooltip
                                      title={option.name}
                                      arrow
                                      placement='top'>
                                      <Chip
                                        color='primary'
                                        avatar={
                                          <Avatar
                                            alt={option.name}
                                            src={option?.userProfile?.[0]}
                                          />
                                        }
                                        //size='small'
                                        sx={{
                                          color: '#fff',
                                        }}
                                        {...getTagProps({ index })}
                                        key={option._id}
                                        label={option.name}
                                        deleteIcon={<CloseIcon />}
                                      />
                                    </Tooltip>
                                  ));
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name='influencers'
                                    error={
                                      touched['influencers'] &&
                                      !!errors['influencers']
                                    }
                                    helperText={
                                      touched['influencers'] &&
                                      errors['influencers']
                                    }
                                    label=''
                                    placeholder='Choose Talents'
                                    variant='outlined'
                                    margin='normal'
                                    required={false}
                                    InputLabelProps={{ required: true }}
                                    // SelectProps={{
                                    //   IconComponent: ExpandMore,
                                    // }}
                                  />
                                )}
                              />
                            </Box>
                            {/* <Box>
                              <FormControl sx={{ mt: 2 }}>
                                <FormLabel
                                  id=''
                                  sx={{ mb: 1 }}>
                                  Select Payment Method
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby='demo-radio-buttons-group-label'
                                  //defaultValue='product'
                                  value={values.paymentType}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setPaymentState(e.target.value);
                                  }}
                                  name='paymentType'>
                                  <FormControlLabel
                                    value='tender'
                                    //disabled
                                    control={<Radio />}
                                    label='Legal Tender'
                                  />
                                  <FormControlLabel
                                    value='product'
                                    control={<Radio />}
                                    label='Product'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box> */}
                          </CardContent>
                          <CardActions sx={{ justifyContent: 'center' }}>
                            <Button
                              type='submit'
                              variant='contained'
                              color='secondary'
                              fullWidth
                              endIcon={
                                !isSubmitting ? <ArrowForwardIcon /> : ''
                              }
                              disabled={isSubmitting}>
                              {' '}
                              {isSubmitting ? (
                                <CircularProgress size={20} />
                              ) : (
                                'Procced'
                              )}
                            </Button>
                            {/* <Button
                              type='button'
                              variant='contained'
                              color='info'
                              onClick={(e) => {
                                handleReset();
                                navigate('/snippet/list');
                              }}>
                              Cancel
                            </Button> */}
                          </CardActions>
                        </Card>
                      </form>
                    )}
                  </Formik>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
}
export default CampaignAdd;
