/** @format */

import { APPROVE_INFLUENCER } from '../../configs/ApiActionUrl';
import adminApi from './AdminHttpService';
export const approveInfluencerService = {
  getAll,
  edit,
  update,
};

async function getAll() {
  const res = await adminApi.get(APPROVE_INFLUENCER.LIST);
  if (res.status) {
  }
  return res;
}
async function edit(id) {
  const res = await adminApi.get(APPROVE_INFLUENCER.GET_ITEM + '/' + id);
  return res;
}
async function update(code, data) {
  const res = await adminApi.put(
    APPROVE_INFLUENCER.UPDATE_INDIVIDUAL + '/' + code,
    data
  );
  return res;
}
