/**
 * Base API URL
 *
 * @format
 */

const VERSION = '/api/v1';
/* AUTH */
const AUTH = {
  LOGIN: VERSION + '/auth/brand/login',
  OTP_SEND_PHONE: VERSION + '/auth/send-otp',
  OTP_VERIFY_PHONE_LOGIN: VERSION + '/auth/brand/login-verify',
  OTP_VERIFY_PHONE: VERSION + '/auth/verify-otp',
  OTP_VERIFY_EMAIL: VERSION + '/auth/verify-email',
  BRAND_REGISTER: VERSION + '/auth/brand/register',
  CATEGORY: VERSION + '/auth/brand/category',
  VERIFY_LINK: VERSION + '/auth/verify',
  GET_USER_EMAIL: VERSION + '/auth/brand/email',
  UPDATE_USER_EMAIL: VERSION + '/auth/brand/register',
  CONTACT_US: VERSION + '/brand/contact-us',

  // FORGOT_PASSWORD: VERSION + "/auth/password/email",
  // RESET_PASSWORD: VERSION + "/auth/password/reset",
  // GET_RESET_PASSWORD: VERSION + "/auth/password/validate",
  // LOGOUT: VERSION + "/auth/logout",
  // SET_PASSWORD: VERSION + "/api/v1/auth/set-password",
  // GET_SET_PASSWORD: VERSION + "/api/v1/auth/set-password",
  // VERIFY_EMAIL: VERSION + "/api/v1/auth/verify-email-change",
};

const INFLUENCER_AUTH = {
  LOGIN: VERSION + '/influencer/auth/login',
  OTP_SEND_PHONE: VERSION + '/influencer/auth/send-otp',
  OTP_VERIFY_PHONE_LOGIN: VERSION + '/influencer/auth/login-verify',
  OTP_VERIFY_PHONE: VERSION + '/influencer/auth/verify-otp',
  OTP_VERIFY_EMAIL: VERSION + '/influencer/auth/verify-email',
  REGISTER: VERSION + '/influencer/auth/register',
  CITIES: VERSION + '/influencer/city',
  VERIFY_LINK: VERSION + '/auth/verify',
  GET_USER_EMAIL: VERSION + '/influencer/auth/email',
  UPDATE_USER_EMAIL: VERSION + '/influencer/auth/register',
  SOCIAL_CONNECT: VERSION + '/influencer/scl-connect',
  TOPICS: VERSION + '/influencer/topics',
  LANGUAGE: VERSION + '/influencer/pub-lang',
  POST_TOPIC_LANG: VERSION + '/influencer/top-lang',
  REQUIREMENT: VERSION + '/admin/requirement',
};

const BRAND_CAMPAIGN = {
  CATEGORIES: VERSION + '/auth/brand/category',
  INFLUENCER: VERSION + '/brand/influencer',
  ADD: VERSION + '/brand/campaign',
  GET_ALL: VERSION + '/brand/campaign',
  GET_ITEM: VERSION + '/brand/campaign',
  ADD_INFLUENCER: VERSION + '/brand/campaign',
  REQUIREMENTS: VERSION + '/admin/requirement',
  GET_INF_PROFILE: VERSION + '/brand/campaign/influencer-profile',
  UPDATE_INF_PROFILE: VERSION + '/brand/campaign/influencer-status',
};

const INFLUENCER_CAMPAIGN = {
  CATEGORIES: VERSION + '/auth/influencer/category',
  INFLUENCER: VERSION + '/influencer/influencer',
  ADD: VERSION + '/influencer/campaign',
  GET_ALL: VERSION + '/influencer/campaign',
  GET_ITEM: VERSION + '/influencer/campaign',
  ADD_INFLUENCER: VERSION + '/influencer/campaign',
  UPLOAD_MEDIA: VERSION + '/influencer/campaign/entry',
};
const ADMIN_AUTH = {
  LOGIN: VERSION + '/admin/login',
};

const ADMIN_CATEGORIES = {
  LIST: VERSION + '/admin/categories',
  ADD: VERSION + '/admin/categories',
  GET_ITEM: VERSION + '/admin/categories',
  UPDATE_INDIVIDUAL: VERSION + '/admin/categories',
  DELETE: VERSION + '/admin/categories',
};

const REQUIREMENT = {
  LIST: VERSION + '/admin/requirement',
  ADD: VERSION + '/admin/requirement',
  GET_ITEM: VERSION + '/admin/requirement',
  UPDATE_INDIVIDUAL: VERSION + '/admin/requirement',
  DELETE: VERSION + '/admin/requirement',
};

const APPROVE_INFLUENCER = {
  LIST: VERSION + '/admin/influencers',
  GET_ITEM: VERSION + '/admin/influencer',
  UPDATE_INDIVIDUAL: VERSION + '/admin/influencer',
};

const APPROVE_BRAND = {
  LIST: VERSION + '/admin/brands',
  GET_ITEM: VERSION + '/admin/brand',
  UPDATE_INDIVIDUAL: VERSION + '/admin/brand',
};

export {
  AUTH,
  INFLUENCER_AUTH,
  BRAND_CAMPAIGN,
  INFLUENCER_CAMPAIGN,
  ADMIN_AUTH,
  ADMIN_CATEGORIES,
  REQUIREMENT,
  APPROVE_INFLUENCER,
  APPROVE_BRAND,
};
