/** @format */

import { authService } from '../../../services/influencer/AuthService';
import {
  setAccessToken,
  setRefreshToken,
  setUserData,
  setRegisterData,
} from '../../../utils/AuthConfig';
import {
  clearInfLocalStorage,
  setInfAccessToken,
  setInfRefreshToken,
  setInfUserData,
} from '../../../utils/InfluencerAuthConfig';
import { authTypes } from '../../types/influencer/AuthTypes';
import { toast } from 'react-toastify';

export const authAction = {
  login,
  otpSendPhone,
  otpVerifyPhone,
  otpVerifyEmail,
  influencerRegister,
  influencerUpdateEmail,
  fetchCitiesOptions,
  getUserEmail,
  verifyLink,
  fetchTopicsOptions,
  fetchLanguageOptions,
  socialConnect,
  topicLanguagePost,
  logout,
  fetchRequirementOptions,
  // forgetPassword,
  // getResetPassword,
  // resetPassword,
};

function login(postData, navigate) {
  return async (dispatch) => {
    dispatch({ type: authTypes.LOGIN_REQUEST });
    try {
      const res = await authService.login(postData);
      dispatch({ type: authTypes.LOGIN_SUCCESS, payload: res.data });

      console.log(res, 'res');
      if (res.data.holderId) {
        navigate('/influencer/verify-otp/' + res.data.holderId, {
          state: { data: postData, login: 'true' },
        });
      }
      if (res.message) {
        toast.success(res.message);
      }
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({ type: authTypes.LOGIN_ERROR, error: error.response });
        console.log(error.response, 'error.response');
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function otpSendPhone(postData, navigate) {
  return async (dispatch) => {
    dispatch({ type: authTypes.OTP_SEND_PHONE_REQUEST });
    try {
      const res = await authService.otpSendPhone(postData);
      dispatch({ type: authTypes.OTP_SEND_PHONE_SUCCESS, payload: res.data });
      console.log(res, 'res');
      if (res.data) {
        setRegisterData(res.data);
        if (!res.data.isPhoneVerify) {
          navigate('/influencer/verify-otp/' + res.data.holderId, {
            state: { data: postData },
          });
        } else if (!res.data.isRegister) {
          navigate('/influencer/register/' + res.data.holderId);
        } else if (!res.data.isEmailVerify) {
          navigate('/influencer/verify-email-otp/' + res.data.holderId);
        } else if (!res.data.isConnectAccount) {
          navigate('/influencer/social/' + res.data.userId);
        }
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.OTP_SEND_PHONE_ERROR,
          error: error.response,
        });
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.errors) {
            toast.error(error.response.data.message);
            //setErrors(error.response.data.errors);
          }
        }
      }
    }
  };
}

function otpVerifyPhone(postData, navigate, loginVerify) {
  return async (dispatch) => {
    dispatch({ type: authTypes.OTP_VERIFY_PHONE_REQUEST });
    try {
      const res = await authService.otpVerifyPhone(postData, loginVerify);
      dispatch({ type: authTypes.OTP_VERIFY_PHONE_SUCCESS, payload: res.data });
      if (res.message) {
        toast.success(res.message);
      }
      console.log(res, 'res');
      if (res) {
        if (loginVerify) {
          navigate('/influencer/dashboard');
          if (res.data.accessToken) {
            setInfAccessToken(res.data.accessToken);
          }
          if (res.data.refreshToken) {
            setInfRefreshToken(res.data.refreshToken);
          }
          if (res.data.userData) {
            setInfUserData(res.data.userData);
          }
          setRegisterData(res.data);
        } else {
          if (!res.isPhoneVerify) {
            navigate('/influencer/verify-otp/' + res.data.holderId, {
              state: { data: postData },
            });
          } else if (!res.isRegister) {
            navigate('/influencer/register/' + res.holderId);
          } else if (!res.isEmailVerify) {
            navigate('/influencer/verify-email-otp/' + res.holderId);
          } else if (!res.isConnectAccount) {
            navigate('/influencer/social/' + res.holderId);
          }
        }
        setRegisterData(res.data);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.OTP_VERIFY_PHONE_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            // navigate('/influencer/signup');
          }
        }
      }
    }
  };
}

function otpVerifyEmail(postData, navigate) {
  return async (dispatch) => {
    dispatch({ type: authTypes.OTP_VERIFY_EMAIL_REQUEST });
    try {
      const res = await authService.otpVerifyEmail(postData);
      dispatch({
        type: authTypes.OTP_VERIFY_EMAIL_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        setRegisterData(res.data);
        navigate('/influencer/social/' + res.data.userId);
        // if (res.data.holderId) {
        //   navigate('/influencer/role/' + res.data.holderId);
        // }
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.OTP_VERIFY_EMAIL_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            //navigate('/influencer/signup');
          }
        }
      }
    }
  };
}

function influencerRegister(postData, navigate) {
  console.log(postData, 'postData');
  return async (dispatch) => {
    dispatch({ type: authTypes.REGISTER_REQUEST });
    try {
      const res = await authService.influencerRegister(postData);
      dispatch({
        type: authTypes.REGISTER_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        console.log(res.data, 'res.data');

        setRegisterData(res.data);
        if (!res.data.isPhoneVerify) {
          navigate('/influencer/verify-otp/' + res.data.holderId, {
            state: { data: postData },
          });
        } else if (!res.data.isRegister) {
          navigate('/influencer/role/' + res.data.holderId);
        } else if (!res.data.isEmailVerify) {
          navigate('/influencer/verify-email-otp/' + res.data.holderId);
        }
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.REGISTER_ERROR,
          error: error.response,
        });
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.errors) {
            toast.error(error.response.data.message);
            //setErrors(error.response.data.errors);
          }
        }
      }
    }
  };
}

async function influencerUpdateEmail(code, postData, closeModal) {
  return async (dispatch) => {
    dispatch({ type: authTypes.CHANGE_EMAIL_REQUEST });
    try {
      const res = await authService.influencerUpdateEmail(code, postData);
      dispatch({
        type: authTypes.CHANGE_EMAIL_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        console.log(res.data, 'res.data');
        setRegisterData(res.data);
        closeModal();
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.CHANGE_EMAIL_ERROR,
          error: error.response,
        });
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.errors) {
            toast.error(error.response.data.message);
            //setErrors(error.response.data.errors);
          }
        }
      }
    }
  };
}

function getUserEmail(code) {
  return async (dispatch) => {
    dispatch({ type: authTypes.GET_USER_EMAIL_REQUEST });
    try {
      const res = await authService.getUserEmail(code);
      dispatch({
        type: authTypes.GET_USER_EMAIL_SUCCESS,
        payload: res.data,
      });
      // if (res.data && res.data.data && res.data.data.email) {
      //   setMailAddress(res.data.data.email);
      // }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.GET_USER_EMAIL_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
        }
      }
    }
    return null;
  };
}

function fetchCitiesOptions() {
  return async (dispatch) => {
    dispatch({ type: authTypes.FETCH_CITIES_REQUEST });
    try {
      const res = await authService.fetchCitiesOptions();
      dispatch({
        type: authTypes.FETCH_CITIES_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.FETCH_CITIES_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function verifyLink(module, code, navigate) {
  return async (dispatch) => {
    dispatch({ type: authTypes.GET_VERIFY_LINK_REQUEST });
    try {
      const res = await authService.verifyLink(module, code);
      dispatch({
        type: authTypes.GET_VERIFY_LINK_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data) {
      }
    } catch (error) {
      dispatch({
        type: authTypes.GET_VERIFY_LINK_ERROR,
        error: error.response,
      });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        navigate('/influencer/signup');
        toast.error(error.response.data.message);
        // You can handle the error here, e.g., toast.error(error.response.data.message);
      }
    }
    return null;
  };
}

function fetchTopicsOptions() {
  return async (dispatch) => {
    dispatch({ type: authTypes.FETCH_TOPICS_REQUEST });
    try {
      const res = await authService.fetchTopicsOptions();
      dispatch({
        type: authTypes.FETCH_TOPICS_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.FETCH_TOPICS_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function fetchLanguageOptions() {
  return async (dispatch) => {
    dispatch({ type: authTypes.FETCH_LANGUAGE_REQUEST });
    try {
      const res = await authService.fetchLanguageOptions();
      dispatch({
        type: authTypes.FETCH_LANGUAGE_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.FETCH_LANGUAGE_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function socialConnect(code, postData, navigate) {
  console.log(postData, 'postData');
  return async (dispatch) => {
    dispatch({ type: authTypes.SOCIAL_CONNECT_REQUEST });
    //console.log(authService.socialConnect(), 'postData111');
    try {
      const res = await authService.socialConnect(code, postData);

      dispatch({
        type: authTypes.SOCIAL_CONNECT_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }

        if (res.data.data.userId) {
          navigate('/influencer/topic/' + res.data.data.userId);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.SOCIAL_CONNECT_ERROR,
          error: error.response,
        });
        console.log(error.response, 'error.response');
        toast.error(error.response.data.message);
        // if (error.response.status === 422) {
        //   if (error.response.data && error.response.data.errors) {
        //     toast.error(error.response.data.message);
        //     //setErrors(error.response.data.errors);
        //   }
        // }
      }
    }
  };
}

function fetchRequirementOptions() {
  return async (dispatch) => {
    dispatch({ type: authTypes.FETCH_INF_REQUIREMENT_REQUEST });
    try {
      const res = await authService.fetchRequirementOptions();
      dispatch({
        type: authTypes.FETCH_INF_REQUIREMENT_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.FETCH_INF_REQUIREMENT_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function topicLanguagePost(code, postData, navigate) {
  console.log(postData, 'postData');
  return async (dispatch) => {
    dispatch({ type: authTypes.POST_TOPIC_LANG_REQUEST });
    //console.log(authService.socialConnect(), 'postData111');
    try {
      const res = await authService.topicLanguagePost(code, postData);

      dispatch({
        type: authTypes.POST_TOPIC_LANG_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        navigate('/influencer/thank-you');
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: authTypes.POST_TOPIC_LANG_ERROR,
          error: error.response,
        });
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.errors) {
            toast.error(error.response.data.message);
            //setErrors(error.response.data.errors);
          }
        }
      }
    }
  };
}

function logout() {
  //localStorage.clear();
  clearInfLocalStorage();
  window.location.href = '/influencer/login';
  toast.success('Logged out successfully');
}

// function forgetPassword(postData, setErrors, submitting, navigate) {
//   return async (dispatch) => {
//     dispatch({ type: authTypes.FORGOT_PASSWORD_REQUEST });
//     try {
//       const res = await authService.forgetPassword(postData);
//       dispatch({ type: authTypes.FORGOT_PASSWORD_SUCCESS, payload: res.data });
//       if (res.data) {
//         if (res.data.message) {
//           toast.success(res.data.message);
//         }
//       }
//       submitting(false);
//       navigate('/');
//     } catch (error) {
//       submitting(false);
//       if (error && error.response) {
//         dispatch({
//           type: authTypes.FORGOT_PASSWORD_ERROR,
//           error: error.response,
//         });
//         if (error.response.status === 422) {
//           if (error.response.data && error.response.data.errors) {
//             toast.error(error.response.data.message);
//             setErrors(error.response.data.errors);
//           }
//         }
//       }
//     }
//   };
// }

// //* Check the reset password mail link valid or not
// function getResetPassword(code, key) {
//   return async (dispatch) => {
//     dispatch({ type: authTypes.GET_RESET_PASSWORD_REQUEST });
//     try {
//       const res = await authService.getResetPassword(code, key);
//       if (res && res.data)
//         dispatch({
//           type: authTypes.GET_RESET_PASSWORD_SUCCESS,
//           payload: res.data,
//         });
//     } catch (error) {
//       if (error && error.response) {
//         dispatch({
//           type: authTypes.GET_RESET_PASSWORD_ERROR,
//           error: error.response,
//         });
//         if (error.response.status === 400) {
//           toast.error(error.response.data.message);
//         }
//       }
//       //
//     }
//   };
// }

// function resetPassword(postData, setErrors, submitting, navigate) {
//   return async (dispatch) => {
//     dispatch({ type: authTypes.RESET_PASSWORD_REQUEST });
//     try {
//       const res = await authService.resetPassword(postData);
//       dispatch({ type: authTypes.RESET_PASSWORD_SUCCESS, payload: res.data });
//       if (res.data) {
//         if (res.data.message) {
//           toast.success(res.data.message);
//         }
//       }
//       submitting(false);
//       navigate('/');
//     } catch (error) {
//       submitting(false);
//       if (error && error.response) {
//         dispatch({
//           type: authTypes.RESET_PASSWORD_ERROR,
//           error: error.response,
//         });
//         if (error.response.status === 422) {
//           if (error.response.data && error.response.data.errors) {
//             toast.error(error.response.data.message);
//             setErrors(error.response.data.errors);
//           }
//         }
//       }
//     }
//   };
// }

// function logout() {
//   // remove user from local storage to log user out

//   return async (dispatch) => {
//     dispatch({ type: authTypes.LOGOUT_REQUEST });
//     try {
//       const res = await authService.logout();
//       dispatch({ type: authTypes.LOGOUT_SUCCESS });
//       console.log(res, 'res');
//       // setTimeout(function () {
//       //   localStorage.clear();
//       //   window.location.reload(false);
//       // }, 500);
//     } catch (error) {
//       dispatch({ type: authTypes.LOGOUT_ERROR, error });
//       console.error(error);
//     }
//   };
// }
