/** @format */

export const approveInfluencerTypes = {
  GETALL_APPROVE_INFLUENCER_REQUEST: 'GETALL_APPROVE_INFLUENCER_REQUEST',
  GETALL_APPROVE_INFLUENCER_SUCCESS: 'GETALL_APPROVE_INFLUENCER_SUCCESS',
  GETALL_APPROVE_INFLUENCER_ERROR: 'GETALL_APPROVE_INFLUENCER_ERROR',

  EDIT_APPROVE_INFLUENCER_REQUEST: 'EDIT_APPROVE_INFLUENCER_REQUEST',
  EDIT_APPROVE_INFLUENCER_SUCCESS: 'EDIT_APPROVE_INFLUENCER_SUCCESS',
  EDIT_APPROVE_INFLUENCER_ERROR: 'EDIT_APPROVE_INFLUENCER_ERROR',

  UPDATE_APPROVE_INFLUENCER_REQUEST: 'UPDATE_APPROVE_INFLUENCER_REQUEST',
  UPDATE_APPROVE_INFLUENCER_SUCCESS: 'UPDATE_APPROVE_INFLUENCER_SUCCESS',
  UPDATE_APPROVE_INFLUENCER_ERROR: 'UPDATE_APPROVE_INFLUENCER_ERROR',
};
