import React from 'react'
import './latest-articles.scss'
import { StyledEngineProvider } from '@mui/material/styles'
import CustomPagination from '../../../components/pagination/Pagination'
import Articles from '../../../components/articles/Articles'

const articles = [
    {
        id:1,
        title:'Influencer Insights: My Journey to Success',
        crated_at:'08 Oct 2022',
        author:'Cameron Williamson',
        image:'https://cdn.pixabay.com/photo/2015/03/05/01/46/sailing-ship-659758_1280.jpg',
    },
    {
        id:2,
        title:'Beyond the Instagram Grid: The Realities of Influencer Marketing',
        crated_at:'08 Oct 2022',
        author:'Subhash singh',
        image:'https://cdn.pixabay.com/photo/2014/08/05/10/30/iphone-410324_1280.jpg',
    },
    {
        id:3,
        title:'Influencer Lifestyle: Balancing Creativity and Brand Partnerships',
        crated_at:'08 Oct 2022',
        author:'Theresa Webb',
        image:'https://cdn.pixabay.com/photo/2016/11/08/05/10/students-1807505_1280.jpg',
    },
    {
        id:4,
        title:'The Power of Authenticity: My Experience as an Influencer',
        crated_at:'08 Oct 2022',
        author:'Guy Hawkins',
        image:'https://cdn.pixabay.com/photo/2015/01/20/12/51/mobile-605422_1280.jpg',
    },
    {
        id:5,
        title:'Building a Personal Brand: Lessons Learned as an Influencer',
        crated_at:'08 Oct 2022',
        author:'Subhash singh',
        image:'https://cdn.pixabay.com/photo/2017/12/11/15/34/lion-3012515_1280.jpg',
    },
    {
        id:6,
        title:'Evolution of Influencer Marketing: How Its Changing the Game',
        crated_at:'08 Oct 2022',
        author:'Leslie Alexander',
        image:'https://cdn.pixabay.com/photo/2016/06/25/12/52/laptop-1478822_1280.jpg',
    },
    {
        id:7,
        title:'Collaborations and Creativity: My Life as an Influencer',
        crated_at:'08 Oct 2022',
        author:'Bessie Cooper',
        image:'https://cdn.pixabay.com/photo/2013/10/28/18/51/brandenburger-tor-201939_1280.jpg',
    },
    {
        id:8,
        title:'Behind the Scenes: A Day in the Life of an Influencer',
        crated_at:'08 Oct 2022',
        author:'Kathryn Murphy',
        image:'https://cdn.pixabay.com/photo/2015/05/31/15/18/technology-792180_1280.jpg',
    },
];

const LatestArticles = () => {
  return (
    <div className="latestarticles">
        <div className="section">
            <div className='container'>
                <div className="title">The Social Bottle blogs</div>
                <div className="tagline">Over a hundred thousand influencers put their trust in us because we care about our partners.</div>
            </div>
            <div className='articles'>
                <div className='sec-title'>Latest articles</div>
                <Articles article={articles} />
            </div>
            <hr className='horiznatal-line'></hr>
            <div className='pagination'>
                <React.StrictMode>
                    <StyledEngineProvider injectFirst>
                    <CustomPagination />
                    </StyledEngineProvider>
                </React.StrictMode>
            </div>
        </div>
    </div>
  )
}

export default LatestArticles