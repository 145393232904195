/** @format */

import React from 'react';

const infos = [
  {
    title: 'Paid influencers worldwide',
    data: '12M+',
  },
  {
    title: 'Minimum number of followers to work with',
    data: '1500',
  },
  {
    title: 'Max earnings in a single month',
    data: '$51K',
  },
  {
    title: 'Influencers on the platform',
    data: '1000+',
  },
];

const InflulencerDataSec = () => {
  return (
    <div className='infDataSection'>
      <div className='container'>
        <div className='title'>Social Bottle for Influencers</div>
        <div className='infData'>
          {infos.map((info, index) => (
            <InflulencerInfoCard info={info} />
          ))}
        </div>
      </div>
    </div>
  );
};

const InflulencerInfoCard = ({ info }) => {
  return (
    <div className='infoCard'>
      <div className='data'>{info.data}</div>
      <div className='info'>{info.title}</div>
    </div>
  );
};

export default InflulencerDataSec;
