/** @format */

export const requirementTypes = {
  GETALL_REQUIREMENTS_REQUEST: 'GETALL_REQUIREMENTS_REQUEST',
  GETALL_REQUIREMENTS_SUCCESS: 'GETALL_REQUIREMENTS_SUCCESS',
  GETALL_REQUIREMENTS_ERROR: 'GETALL_REQUIREMENTS_ERROR',

  ADD_REQUIREMENT_REQUEST: 'ADD_REQUIREMENT_REQUEST',
  ADD_REQUIREMENT_SUCCESS: 'ADD_REQUIREMENT_SUCCESS',
  ADD_REQUIREMENT_ERROR: 'ADD_REQUIREMENT_ERROR',

  EDIT_REQUIREMENT_REQUEST: 'EDIT_REQUIREMENT_REQUEST',
  EDIT_REQUIREMENT_SUCCESS: 'EDIT_REQUIREMENT_SUCCESS',
  EDIT_REQUIREMENT_ERROR: 'EDIT_REQUIREMENT_ERROR',

  UPDATE_REQUIREMENT_REQUEST: 'UPDATE_REQUIREMENT_REQUEST',
  UPDATE_REQUIREMENT_SUCCESS: 'UPDATE_REQUIREMENT_SUCCESS',
  UPDATE_REQUIREMENT_ERROR: 'UPDATE_REQUIREMENT_ERROR',

  DELETE_REQUIREMENT_REQUEST: 'DELETE_REQUIREMENT_REQUEST',
  DELETE_REQUIREMENT_SUCCESS: 'DELETE_REQUIREMENT_SUCCESS',
  DELETE_REQUIREMENT_ERROR: 'DELETE_REQUIREMENT_ERROR',
};
