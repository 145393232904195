/** @format */
import * as Yup from 'yup';
export const CampaignAddValidate = Yup.object().shape({
  name: Yup.string()
    .max(128, 'Name cannot exceed 128 characters')
    .required('Name cannot be empty'),
  email: Yup.string()
    .email('Enter a valid Email Address')
    .max(64, 'Email Address cannot exceed 64 characters')
    .min(8, 'Email Address cannot be less than 8 characters')
    .required('Email Address cannot be empty'),
  password: Yup.string()
    .required('Password cannot be empty')
    .max(16, 'Password cannot exceed 16 characters'),
});
