import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import JoinCards from '../home/joincards/JoinCards'
import HowItWorks from '../../components/how/HowItWorks'
import PromoBanner from '../../components/promo/PromoBanner'
import PartnersSection from '../../components/partners/PartnersSection'
import MarketingDataSection from '../../components/marketing/MarketingDataSection'
import InfluencersPromoSection from '../../components/influencerspromo/InfluencersPromoSection'

const Home = () => {
  return (
    <div className="home">
      <Navbar/>
      <PromoBanner/>
      <HowItWorks/>
      <InfluencersPromoSection/>
      <MarketingDataSection/>
      <PartnersSection/>
      <JoinCards/>
      <Footer/>
      </div>
  )
}

export default Home