/** @format */

import { InfCampTypes } from '../../types/influencer/campaignTypes';
const initialState = {
  categoryData: [],
  influencerData: [],
  campaignData: [],
  campaignItemData: [],
  campaignInfluencer: [],
};

export const influencercampaign = (state = initialState, action) => {
  switch (action.type) {
    case InfCampTypes.FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InfCampTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        categoryData: action.payload,
      };
    case InfCampTypes.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };
    case InfCampTypes.FETCH_INFLUENCER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InfCampTypes.FETCH_INFLUENCER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        influencerData: action.payload,
      };
    case InfCampTypes.FETCH_INFLUENCER_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    case InfCampTypes.GETALL_CAMPAIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InfCampTypes.GETALL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        campaignData: action.payload,
      };
    case InfCampTypes.GETALL_CAMPAIGN_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    case InfCampTypes.GET_ITEM_CAMPAIGN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InfCampTypes.GET_ITEM_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        campaignItemData: action.payload,
      };
    case InfCampTypes.GET_ITEM_CAMPAIGN_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    case InfCampTypes.GET_CAMPAIGN_INFLUENCER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case InfCampTypes.GET_CAMPAIGN_INFLUENCER_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        campaignInfluencer: action.payload,
      };
    case InfCampTypes.GET_CAMPAIGN_INFLUENCER_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    default:
      return state;
  }
};
