/** @format */

import { authTypes } from '../../types/influencer/AuthTypes';
let accessToken = localStorage.getItem('accessToken');
// const initialState = accessToken
//   ? { loggedIn: true, isLoading: true, cities: [] }
const initialState = {
  loggedIn: false,
  verifyEmailStatus: false,
  verifyLinkStatus: false,
  isLoading: true,
  cities: [],
  mailData: '',
  verifyStatus: false,
  language: [],
  topics: [],
  requirementType: [],
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        data: action.data,
      };
    case authTypes.LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    case authTypes.FETCH_CITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.FETCH_CITIES_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        cities: action.payload,
      };
    case authTypes.FETCH_CITIES_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    case authTypes.GET_USER_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.GET_USER_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailData: action.payload,
      };
    case authTypes.GET_USER_EMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        //error: action.error,
      };

    // case authTypes.GET_USER_EMAIL_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case authTypes.GET_USER_EMAIL_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     verifyStatus: true,
    //   };
    // case authTypes.GET_USER_EMAIL_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     verifyStatus: false,
    //     //error: action.error,
    //   };

    // case authTypes.LOGOUT:
    //   return {
    //     ...initialState, // Reset the session state
    //   };

    // case authTypes.FORGOT_PASSWORD_REQUEST:
    //   return { isLoading: true };
    // case authTypes.FORGOT_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     forgortData: action.data,
    //   };
    // case authTypes.FORGOT_PASSWORD_ERROR:
    //   return { error: action.error };

    // case authTypes.RESET_PASSWORD_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // case authTypes.RESET_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     data: action.data,
    //   };
    // case authTypes.RESET_PASSWORD_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: action.error,
    //   };
    case authTypes.GET_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        verifyLinkStatus: '',
        isLoading: true,
      };
    case authTypes.GET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyLinkStatus: true,
        data: action.data,
      };
    case authTypes.GET_RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        verifyLinkStatus: false,
        //error: action.data,
      };
    // case authTypes.GET_SET_PASSWORD_REQUEST:
    //   return {
    //     ...state,
    //     verifyLinkStatus: "",
    //     isLoading: true,
    //   };
    // case authTypes.GET_SET_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     verifyLinkStatus: true,
    //     data: action.data,
    //   };
    // case authTypes.GET_SET_PASSWORD_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     verifyLinkStatus: false,
    //     error: action.error,
    //   };
    // case authTypes.VERIFY_EMAIL_REQUEST:
    //   return {
    //     ...state,
    //     verifyEmailStatus: "",
    //     isLoading: true,
    //   };
    // case authTypes.VERIFY_EMAIL_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     verifyEmailStatus: true,
    //     data: action.data,
    //   };
    // case authTypes.VERIFY_EMAIL_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     verifyEmailStatus: false,
    //     error: action.error,
    //   };
    // case authTypes.LOGOUT:
    //   return {
    //     loggedIn: false,
    //     isLoading: false,
    //   };

    case authTypes.GET_VERIFY_LINK_REQUEST:
      return {
        ...state,
        verifyStatus: '',
        isLoading: true,
      };
    case authTypes.GET_VERIFY_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        verifyStatus: true,
        data: action.data,
      };
    case authTypes.GET_VERIFY_LINK_ERROR:
      return {
        ...state,
        isLoading: false,
        verifyStatus: false,
        //error: action.data,
      };

    case authTypes.FETCH_TOPICS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        topics: action.payload,
      };
    case authTypes.FETCH_TOPICS_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    case authTypes.FETCH_LANGUAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.FETCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        language: action.payload,
      };
    case authTypes.FETCH_LANGUAGE_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };
    case authTypes.FETCH_INF_REQUIREMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authTypes.FETCH_INF_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        requirementType: action.payload,
      };
    case authTypes.FETCH_INF_REQUIREMENT_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    default:
      return state;
  }
};
