/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { requirementAction } from '../../../store/actions/admin/requirementAction';
import { Stack } from '@mui/material';

/*Modal Style */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 450,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 1,
  p: 6,
};

export default function ConfirmModal(props) {
  const dispatch = useDispatch();

  const { requirement_info } = props;
  const { id, confirm_status, status } = requirement_info;

  const handleAction = () => {
    if (id) {
      if (confirm_status === 'delete') {
        dispatch(requirementAction.deleteRequirement(id, props.onCloseModal));
      } else if (confirm_status === 'active' || confirm_status === 'inactive') {
        let formData = {
          status: status,
        };
        dispatch(
          requirementAction.updateStatus(id, formData, props.onCloseModal)
        );
      }
    }
  };

  return (
    <div>
      <Modal
        className='cmn__form__modal'
        keepMounted
        open={props.dialogopen}
        //onClose={props.onCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Typography
            id='modal-title'
            variant='h4'
            sx={{ textAlign: 'center' }}
            component='h2'>
            {confirm_status === 'delete'
              ? 'Delete'
              : confirm_status === 'active'
              ? 'Active'
              : 'Inactive'}{' '}
          </Typography>
          <div className='modalform'>
            <Box
              className='innerContainer'
              sx={{}}>
              <Box
                sx={{
                  background: '#fff',
                  padding: '0',
                }}>
                <Typography
                  id='modal-content'
                  variant='body1'
                  component='p'>
                  Are you sure <br /> you want to{' '}
                  {confirm_status === 'delete'
                    ? 'delete'
                    : confirm_status === 'active'
                    ? 'active'
                    : 'inactive'}{' '}
                  this requirement?
                </Typography>

                <Stack
                  direction='row'
                  spacing={2}
                  className='button_group'>
                  <Button
                    type='submit'
                    className='save__btn'
                    variant='contained'
                    color='secondary'
                    fullWidth
                    size='large'
                    onClick={handleAction}>
                    Yes
                  </Button>
                  <Button
                    type='button'
                    className='cancel__btn'
                    variant='contained'
                    color='info'
                    fullWidth
                    size='large'
                    onClick={(e) => {
                      props.onCloseModal();
                    }}>
                    No
                  </Button>
                </Stack>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
