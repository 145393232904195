/** @format */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './EmailOtpScreen.scss';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import OtpInput from '../../components/otpfield/OtpInput';
import { authAction } from '../../actions/AuthAction';
import api from '../../services/HttpService';
import { AUTH } from '../../configs/ApiActionUrl';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import ChangeEmailModal from './ChangeEmailModal';
import { getRegisterData } from '../../utils/AuthConfig';

const BrandEmailOtpScreen = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [mailAddress, setMailAddress] = useState('');
  const location = useLocation();
  const { id } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }

  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(interval);
  //       } else {
  //         setSeconds(59);
  //         setMinutes(minutes - 1);
  //       }
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [seconds, minutes]);

  // Handling otp field text chanages
  const handleTextFieldChange = (e) => {
    console.log(e);
    setOtp(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Custom validation logic
    if (otp.length !== 6) {
      setOtpError('Please enter a valid 6-digit OTP.');
      return;
    }

    let formData = {
      holderId: id,
      otp: otp,
    };

    authAction.OtpVerifyEmail(formData, navigate);
    // If validation passes, you can submit the OTP value
    // (e.g., send it to the server or perform any other action)
    // Here, we'll just log it for demonstration purposes.

    //console.log('Submitted OTP:', otp);

    // Reset error state
    setOtpError('');
  };

  const updateData = getRegisterData();

  async function verifyLink(module, code) {
    try {
      const res = await api.get(AUTH.VERIFY_LINK + '/' + module + '/' + code);
      if (res.data && res.data) {
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        //navigate('/brand/signup');
        toast.error('Link Expired111111111111111111111111');
        // You can handle the error here, e.g., toast.error(error.response.data.message);
      }
    }
    return null;
  }

  async function getUserEmail(code) {
    try {
      const res = await api.get(AUTH.GET_USER_EMAIL + '/' + code);
      if (res.data && res.data.data && res.data.data.email) {
        setMailAddress(res.data.data.email);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        //navigate('/brand/signup');
        toast.error(error.response.data.message);
        // You can handle the error here, e.g., toast.error(error.response.data.message);
      }
    }
    return null;
  }

  console.log(updateData, 'updateData');

  useEffect(() => {
    if (updateData && updateData.holderId) {
      console.log('ffffff');
      verifyLink('email', updateData.holderId);
    } else {
      //verifyLink('email', id);
    }

    if (updateData && updateData.userId) {
      getUserEmail(updateData.userId);
    }
    if (location.state && location.state !== null) {
      if (location.state.data && location.state.data.email) {
        setMailAddress(location.state.data.email);
      }
    }
  }, []);

  //*  ==== OnTap Contune button ====== */

  const onTapContinueButton = () => {
    navigate('/social');
  };

  const handleOpen = () => setModalOpen(true);

  return (
    <div className='otp-screen'>
      <div className='otp-screen-container'>
        <div className='otp-screen-container-left'>
          <div className='otp-screen-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className='otp-screen-container-left-content-fields'>
                <div className='otp-screen-header'>Email verification</div>
                <div className='otp-screen-caption'>
                  <span>
                    a confirmation code has beedn sent your email {mailAddress}
                    <Button
                      variant='text'
                      onClick={handleOpen}
                      className='otp-screen-change-number'>
                      Change email
                    </Button>
                  </span>
                </div>

                <OtpInput
                  onChange={setOtp}
                  value={otp}
                />

                <div className='countdown-text'>
                  {seconds > 0 || minutes > 0 ? (
                    <>
                      <span className='timer-info-text'>
                        You can resend the code in{' '}
                      </span>
                      <span className='timer-value'>
                        {' '}
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    </>
                  ) : (
                    <div className='resend-container'>
                      <span className='resend-info-text'>
                        Didn't recieve code?
                      </span>
                      <button className='resend-button'>Resend OTP</button>
                    </div>
                  )}
                </div>
                {otpError && (
                  <div className='otp-error-container'>
                    <span className='otp-error-message'>{otpError}</span>
                  </div>
                )}

                <div className='continue-button'>
                  <button type='submit'>Verify</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='otp-screen-container-right'>
          <AuthBanner />
        </div>
      </div>
      {modalOpen === true ? (
        <ChangeEmailModal
          open={modalOpen}
          user_id={updateData && updateData.userId}
          email={mailAddress}
          onCloseModal={() => setModalOpen(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default BrandEmailOtpScreen;
