/** @format */

export const CampaignTypes = {
  FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR',

  FETCH_INFLUENCER_REQUEST: 'FETCH_INFLUENCER_REQUEST',
  FETCH_INFLUENCER_SUCCESS: 'FETCH_INFLUENCER_SUCCESS',
  FETCH_INFLUENCER_ERROR: 'FETCH_INFLUENCER_ERROR',

  CAMPAIGN_ADD_REQUEST: 'CAMPAIGN_ADD_REQUEST',
  CAMPAIGN_ADD_SUCCESS: 'CAMPAIGN_ADD_SUCCESS',
  CAMPAIGN_ADD_ERROR: 'CAMPAIGN_ADD_ERROR',

  GETALL_CAMPAIGN_REQUEST: 'GETALL_CAMPAIGN_REQUEST',
  GETALL_CAMPAIGN_SUCCESS: 'GETALL_CAMPAIGN_SUCCESS',
  GETALL_CAMPAIGN_ERROR: 'GETALL_CAMPAIGN_ERROR',

  GET_ITEM_CAMPAIGN_REQUEST: 'GET_ITEM_CAMPAIGN_REQUEST',
  GET_ITEM_CAMPAIGN_SUCCESS: 'GET_ITEM_CAMPAIGN_SUCCESS',
  GET_ITEM_CAMPAIGN_ERROR: 'GET_ITEM_CAMPAIGN_ERROR',

  CAMPAIGN_UPDATE_REQUEST: 'CAMPAIGN_UPDATE_REQUEST',
  CAMPAIGN_UPDATE_SUCCESS: 'CAMPAIGN_UPDATE_SUCCESS',
  CAMPAIGN_UPDATE_ERROR: 'CAMPAIGN_UPDATE_ERROR',

  GET_CAMPAIGN_INFLUENCER_REQUEST: 'GET_CAMPAIGN_INFLUENCER_REQUEST',
  GET_CAMPAIGN_INFLUENCER_SUCCESS: 'GET_CAMPAIGN_INFLUENCER_SUCCESS',
  GET_CAMPAIGN_INFLUENCER_ERROR: 'GET_CAMPAIGN_INFLUENCER_ERROR',

  CAMPAIGN_ADD_INFLUENCER_REQUEST: 'CAMPAIGN_ADD_INFLUENCER_REQUEST',
  CAMPAIGN_ADD_INFLUENCER_SUCCESS: 'CAMPAIGN_ADD_INFLUENCER_SUCCESS',
  CAMPAIGN_ADD_INFLUENCER_ERROR: 'CAMPAIGN_ADD_INFLUENCER_ERROR',

  FETCH_REQUIREMENTS_REQUEST: 'FETCH_REQUIREMENTS_REQUEST',
  FETCH_REQUIREMENTS_SUCCESS: 'FETCH_REQUIREMENTS_SUCCESS',
  FETCH_REQUIREMENTS_ERROR: 'FETCH_REQUIREMENTS_ERROR',

  GET_CAMPAIGN_INFLUENCER_PROFILE_REQUEST:
    'GET_CAMPAIGN_INFLUENCER_PROFILE_REQUEST',
  GET_CAMPAIGN_INFLUENCER_PROFILE_SUCCESS:
    'GET_CAMPAIGN_INFLUENCER_PROFILE_SUCCESS',
  GET_CAMPAIGN_INFLUENCER_PROFILE_ERROR:
    'GET_CAMPAIGN_INFLUENCER_PROFILE_ERROR',

  UPDATE_CAMPAIGN_INFLUENCER_PROFILE_REQUEST:
    'UPDATE_CAMPAIGN_INFLUENCER_PROFILE_REQUEST',
  UPDATE_CAMPAIGN_INFLUENCER_PROFILE_SUCCESS:
    'UPDATE_CAMPAIGN_INFLUENCER_PROFILE_SUCCESS',
  UPDATE_CAMPAIGN_INFLUENCER_PROFILE_ERROR:
    'UPDATE_CAMPAIGN_INFLUENCER_PROFILE_ERROR',
};
