import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import LatestArticles from './latestarticles/LatestArticles'

const Blog = () => {
  return (
    <div className="blog">
      <Navbar/>
      <LatestArticles/>
      <Footer/>
      </div>
  )
}

export default Blog