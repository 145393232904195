/** @format */

import { requirementTypes } from '../../types/admin/RequirementTypes';
import { requirementService } from '../../../services/admin/RequirementService';
import { toast } from 'react-toastify';

export const requirementAction = {
  getAll,
  add,
  edit,
  update,
  deleteRequirement,
};

function getAll() {
  return async (dispatch) => {
    dispatch({ type: requirementTypes.GETALL_REQUIREMENTS_REQUEST });
    try {
      const res = await requirementService.getAll();
      dispatch({
        type: requirementTypes.GETALL_REQUIREMENTS_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: requirementTypes.GETALL_REQUIREMENTS_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}
function add(postData, setErrors, setSubmitting, closeModal) {
  return async (dispatch) => {
    dispatch({ type: requirementTypes.ADD_REQUIREMENT_REQUEST });
    try {
      const res = await requirementService.add(postData);
      dispatch({
        type: requirementTypes.ADD_REQUIREMENT_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        closeModal();
        setTimeout(function () {
          dispatch(requirementAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: requirementTypes.ADD_REQUIREMENT_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function deleteRequirement(code, closeModal) {
  return async (dispatch) => {
    dispatch({ type: requirementTypes.DELETE_REQUIREMENT_REQUEST });
    try {
      const res = await requirementService.deleteRequirement(code);
      dispatch({
        type: requirementTypes.DELETE_REQUIREMENT_SUCCESS,
        payload: res.data.data,
      });
      if (res.data.message) {
        toast.success(res.data.message);
      }
      closeModal();
      setTimeout(function () {
        dispatch(requirementAction.getAll());
      }, 500);
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: requirementTypes.DELETE_REQUIREMENT_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          closeModal();
        }
      }
      //
    }
  };
}
function update(code, postData, setErrors, setSubmitting, closeModal) {
  return async (dispatch) => {
    dispatch({ type: requirementTypes.UPDATE_REQUIREMENT_REQUEST });
    try {
      const res = await requirementService.update(code, postData);
      dispatch({
        type: requirementTypes.UPDATE_REQUIREMENT_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        closeModal();
        setTimeout(function () {
          dispatch(requirementAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: requirementTypes.UPDATE_REQUIREMENT_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function edit(id) {
  return async (dispatch) => {
    dispatch({ type: requirementTypes.EDIT_REQUIREMENT_REQUEST });
    try {
      const res = await requirementService.edit(id);
      dispatch({
        type: requirementTypes.EDIT_REQUIREMENT_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: requirementTypes.EDIT_REQUIREMENT_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}
