/** @format */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Stack,
  Chip,
  Divider,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Pagination,
  TextField,
} from '@mui/material';
import './influencer.scss';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CampaignAction } from '../../store/actions/brand/campaignAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfCampAction } from '../../store/actions/influencer/campaignAction';
import { getInfUserData } from '../../utils/InfluencerAuthConfig';
import { Loader } from '../../utils/Loader';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import _debounce from 'lodash/debounce';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function InfAllCampaigns() {
  const [listData, setListData] = React.useState([]);
  const [value, setValue] = React.useState('1');
  const [pageOption, setPageOption] = useState({ page: 1, limit: 10 });
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(InfCampAction.getAllCampaign(pageOption, searchTerm));
  }, [dispatch, pageOption]);

  const { campaignData, isLoading } = useSelector(
    (state) => state.brandcampaign
  );

  const userInfo = getInfUserData();
  console.log(userInfo, 'userInfo');

  useEffect(() => {
    if (campaignData && campaignData.data) {
      setListData(campaignData.data);
    }
  }, [campaignData]);

  const handleSearch = (newSearchTerm) => {
    // Implement your search logic here, e.g., fetching data based on the newSearchTerm

    console.log('Searching for:', newSearchTerm);
  };

  const delayedAPICall = _debounce((value) => {
    // Trigger the API call with the latest search term
    // Call the internal handleSearch function with the new search term
    dispatch(InfCampAction.getAllCampaign(pageOption, value));
  }, 3000);

  const handleOnChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // Call the debounced function with the new search term
    delayedAPICall(newSearchTerm);
  };

  const handlePageChange = (event, value) => {
    setPageOption({
      ...pageOption,
      page: value,
    });

    // Perform any custom logic here, such as fetching data for the new page
  };

  console.log(listData, 'listData');

  return (
    <div className='mainWrap'>
      <div className='header'>
        <div className='container'>
          {isLoading ? <Loader /> : ''}
          <Button
            variant='text'
            component={Link}
            to='/influencer/dashboard'
            startIcon={<ArrowBackIosNewSharpIcon />}>
            {' '}
            Back
          </Button>
          <Typography
            variant='h2'
            component={'h2'}>
            Campaigns
          </Typography>
        </div>
      </div>
      <Box className='mainContent'>
        <div className='container'>
          <Box
            className='tabsWrap'
            sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <TabList onChange={(event, newValue) => setValue(newValue)}>
                <Tab
                  className='approved'
                  label={`Available (${listData && listData.totalCount})`}
                  value='1'
                />
                <Tab
                  className='pending'
                  label={`Projects`}
                  value='2'
                />
              </TabList>
              <TabPanel value='1'>
                <Card
                  sx={{ mt: 4 }}
                  className='influencerCard'>
                  {/* <CardHeader
                    action={
                      <Button
                        variant='text'
                        endIcon={<KeyboardArrowRightIcon />}
                        aria-label='settings'>
                        All
                      </Button>
                    }
                    title='Recent Campaigns'
                    subheader={'Available ' + (listData && listData.totalCount)}
                  /> */}
                  <CardContent>
                    <TextField
                      label='Search'
                      variant='outlined'
                      sx={{ background: '#fff' }}
                      value={searchTerm}
                      fullWidth
                      //onChange={handleSearch}
                      onChange={handleOnChange}
                    />
                  </CardContent>
                  <CardContent>
                    <Grid
                      container
                      rowSpacing={2}
                      sx={{ mt: 0 }}
                      columnSpacing={3}>
                      {listData &&
                        listData.campaigns &&
                        listData.campaigns.map((list_item, index) => {
                          return (
                            <Grid
                              key={index}
                              className='campaignGrid'
                              item
                              xs={4}
                              md={4}>
                              <Paper
                                component={Link}
                                to={
                                  '/influencer/campaign/detail/' + list_item._id
                                }
                                sx={{
                                  p: 3,
                                  boxShadow: '0px 8px 30px 0px #00000026',
                                  display: 'inline-block',
                                  width: '100%',
                                  boxSizing: 'border-box',
                                  textDecoration: 'none',
                                }}>
                                <Typography
                                  variant='h3'
                                  component={'h3'}>
                                  {list_item.name}
                                </Typography>
                                <Typography
                                  variant='body1'
                                  component={'p'}
                                  dangerouslySetInnerHTML={{
                                    __html: list_item && list_item.description,
                                  }}></Typography>
                                x
                                <Stack
                                  sx={{ mb: 2 }}
                                  direction='row'
                                  spacing={2}>
                                  {list_item.categories &&
                                    list_item.categories.map((item, idx) => {
                                      return (
                                        <Chip
                                          key={idx}
                                          label={item.name}
                                          color='primary'
                                          sx={{
                                            background: item.color,
                                            color: '#fff',
                                          }}
                                        />
                                      );
                                    })}
                                </Stack>
                                <Typography
                                  variant='h4'
                                  component={'h4'}>
                                  Requirements
                                </Typography>
                                <Stack
                                  sx={{ mb: 2 }}
                                  direction='row'
                                  spacing={2}>
                                  {list_item.requirement &&
                                    list_item.requirement.map((item, idx) => {
                                      return (
                                        <Chip
                                          key={idx}
                                          label={item}
                                          color='primary'
                                          sx={{
                                            color: '#fff',
                                          }}
                                          //color='primary'
                                        />
                                      );
                                    })}
                                </Stack>
                                {list_item.budget ? (
                                  <>
                                    <Divider />
                                    <Typography
                                      sx={{ pt: 3 }}
                                      variant='h5'
                                      component={'h5'}>
                                      Campaign budget{' '}
                                      <span>{'₹ ' + list_item.budget}</span>
                                    </Typography>
                                  </>
                                ) : (
                                  ''
                                )}
                              </Paper>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Pagination
                      count={Math.ceil(
                        listData?.totalCount / pageOption?.limit
                      )} // Calculate the total number of pages
                      page={parseInt(pageOption?.page)} // Set the current active page
                      onChange={handlePageChange} // Handle page change
                      color='primary'
                      size='large'
                    />
                  </CardActions>
                </Card>
              </TabPanel>
              <TabPanel value='2'>
                <Card
                  sx={{ mt: 4 }}
                  className='influencerCard'>
                  <CardHeader
                    action={
                      <Button
                        variant='text'
                        endIcon={<KeyboardArrowRightIcon />}
                        aria-label='settings'>
                        All
                      </Button>
                    }
                    title='Projects'
                    subheader={'Available ' + (listData && listData.totalCount)}
                  />
                  <CardContent></CardContent>
                </Card>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default InfAllCampaigns;
