/** @format */

import React from 'react';
import './OtpInput.scss';

const OtpInput = ({ onChange, value }) => {
  const arr = new Array(6).fill('-');
  const validationPattern = /^[0-9]*$/; // Validate that only numbers are entered

  const handleInputChange = (e, index) => {
    const elem = e.target;
    const val = e.target.value;

    // Check if the value is valid
    if (!validationPattern.test(val) || val.length > 1) return;

    // Change the value using onChange props
    const valueArr = value.split('');
    valueArr[index] = val;
    const newVal = valueArr.join('').slice(0, 6);
    onChange(newVal);

    // Focus the next element if there's a value
    if (val) {
      const next = elem.nextElementSibling;
      next?.focus();
    }
  };

  const handleKeyUp = (e) => {
    const current = e.currentTarget;
    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      const prev = current.previousElementSibling;
      prev?.focus();
      prev?.setSelectionRange(0, 1);
      return;
    }

    if (e.key === 'ArrowRight') {
      const next = current.nextSibling;
      next?.focus();
      next?.setSelectionRange(0, 1);
      return;
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const val = e.clipboardData.getData('text').substring(0, 6);
    if (!validationPattern.test(val)) return; // Ensure only numbers are pasted
    onChange(val);
  };

  return (
    <div className='otp-input'>
      <div className='otp-input-container'>
        {arr.map((_, index) => (
          <input
            key={index}
            className='text-field'
            type='text'
            inputMode='numeric'
            autoComplete='one-time-code'
            pattern={validationPattern.source}
            maxLength={1}
            value={value.charAt(index) || ''}
            onChange={(e) => handleInputChange(e, index)}
            onKeyUp={handleKeyUp}
            onPaste={handlePaste}
            //required // Add required attribute for validation
          />
        ))}
        {/* <input key={} type="text" name="" id="" /> */}
      </div>
    </div>
  );
};

export default OtpInput;
