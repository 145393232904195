/** @format */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack, Divider, IconButton, FormHelperText } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, TextField } from '@mui/material';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  getIn,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { InfCampAction } from '../../store/actions/influencer/campaignAction';
import { Add, PlusOne, Remove } from '@mui/icons-material';
/*Modal Style */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 1,
  p: 5,
};

const validationSchema = Yup.object().shape({
  urls: Yup.array().of(
    Yup.string().required('Please, enter URL').url('Please, enter valid URL')
  ),
});

// const Input = ({ field, form: { errors, touched } }) => {
//   const errorMessage = getIn(errors, field.name);
//   const isTouched = getIn(touched, field.name);
//   console.log(isTouched);
//   console.log(errorMessage);
//   return (
//     <>
//       <TextField
//         {...field}
//         fullWidth
//         placeholder='Enter Url'
//         error={isTouched && !!errorMessage}
//       />

//       {isTouched && errorMessage && (
//         <FormHelperText style={{ color: 'red' }}>{errorMessage}</FormHelperText>
//       )}
//     </>
//   );
// };

export default function UploadMedia(props) {
  const [switchData, setSwitchData] = React.useState([]);
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        className='cmn__form__modal'
        keepMounted
        open={props.open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Typography
            id='modal-title'
            variant='h4'
            component='h4'>
            Upload Media
          </Typography>

          <div className='modalform'>
            <Box
              className='innerContainer'
              sx={{}}>
              <Box
                sx={{
                  background: '#fff',
                  padding: '0',
                }}>
                <Paper elevation={0}>
                  <Formik
                    initialValues={{
                      urls: [''],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      // console.log(values, 'values');
                      dispatch(
                        InfCampAction.uploadMediaURL(
                          props.itemID,
                          values,
                          setErrors,
                          setSubmitting,
                          props.onCloseModal
                        )
                      );
                    }}>
                    {({ values, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <FieldArray name='urls'>
                          {({ push, remove }) => (
                            <div>
                              {values.urls && values.urls.length > 0
                                ? values.urls.map((url, index) => (
                                    <Box sx={{ mb: 2 }}>
                                      <Stack
                                        direction={'row'}
                                        sx={{ alignItems: 'center' }}
                                        key={index}
                                        spacing={2}>
                                        <Field
                                          as={TextField}
                                          name={`urls[${index}]`}
                                          label='URL'
                                          variant='outlined'
                                          fullWidth
                                        />
                                        {index !== 0 ? (
                                          <IconButton
                                            sx={{
                                              color: '#fff',
                                              background: '#44bba4',
                                              borderRadius: '0px',
                                              width: '45px',
                                              height: '45px',
                                              '&:hover': {
                                                background: '#44bba4',
                                                color: '#fff',
                                              },
                                            }}
                                            onClick={() => remove(index)}>
                                            <Remove />
                                          </IconButton>
                                        ) : (
                                          ''
                                        )}
                                      </Stack>
                                      <FormHelperText error>
                                        <ErrorMessage
                                          name={`urls[${index}]`}
                                          component='span'
                                        />
                                      </FormHelperText>
                                    </Box>
                                  ))
                                : ''}
                              <Button
                                type='button'
                                variant='text'
                                startIcon={<Add />}
                                color='primary'
                                sx={{ color: '#44bba4' }}
                                fullWidth
                                onClick={() => push('')}>
                                Add URL
                              </Button>
                            </div>
                          )}
                        </FieldArray>
                        <Stack
                          direction='row'
                          spacing={2}
                          sx={{ mt: 3 }}
                          className='button_group'>
                          <Button
                            type='submit'
                            className='save__btn'
                            variant='contained'
                            color='primary'
                            fullWidth
                            size='large'>
                            submit
                          </Button>
                          <Button
                            type='button'
                            className='cancel__btn'
                            variant='contained'
                            color='primary'
                            fullWidth
                            size='large'
                            onClick={(e) => {
                              props.onCloseModal();
                            }}>
                            Cancel
                          </Button>
                        </Stack>
                      </form>
                    )}
                  </Formik>
                </Paper>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
