/** @format */

import { requirementTypes } from '../../types/admin/RequirementTypes';
const initialState = {
  loading: false,
  requirement_data: [],
  requirement_status: false,
  requirementData: [],
  requirementData_status: false,
  activeCategoryData: [],
  activeCategory_status: false,
};
export const requirements = (state = initialState, action) => {
  switch (action.type) {
    case requirementTypes.GETALL_REQUIREMENTS_REQUEST:
      return {
        loading: true,
      };
    case requirementTypes.GETALL_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        requirement_status: true,
        requirement_data: action.payload,
      };

    case requirementTypes.GETALL_REQUIREMENTS_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case requirementTypes.GETALL_ACTIVE_REQUIREMENT_REQUEST:
      return {
        loading: true,
      };
    case requirementTypes.GETALL_ACTIVE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        activeCategory_status: true,
        activeCategoryData: action.data,
      };

    case requirementTypes.GETALL_ACTIVE_REQUIREMENT_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case requirementTypes.ADD_REQUIREMENT_REQUEST:
      return {
        loading: true,
        data: action.data,
      };
    case requirementTypes.ADD_REQUIREMENT_SUCCESS:
      return {
        loading: false,
        item: action.data,
      };
    case requirementTypes.ADD_REQUIREMENT_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case requirementTypes.DELETE_REQUIREMENT_REQUEST:
      return state;
    case requirementTypes.DELETE_REQUIREMENT_SUCCESS:
      return {
        loading: false,
        item: action.data,
        //items:state.items.filter(item => item.id !== action.id)
      };
    case requirementTypes.DELETE_REQUIREMENT_ERROR:
      return {};
    case requirementTypes.EDIT_REQUIREMENT_REQUEST:
      return {
        loading: true,
      };
    case requirementTypes.EDIT_REQUIREMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        requirementData_status: true,
        requirementData: action.payload,
      };
    case requirementTypes.EDIT_REQUIREMENT_ERROR:
      return {
        loading: false,
        error: action.error,
      };

    case requirementTypes.UPDATE_REQUIREMENT_REQUEST:
      return {
        loading: true,
        data: action.data,
      };
    case requirementTypes.UPDATE_REQUIREMENT_SUCCESS:
      return {
        loading: false,
        item: action.data,
      };
    case requirementTypes.UPDATE_REQUIREMENT_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
