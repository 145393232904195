/** @format */

import { categoryTypes } from '../../types/admin/CategoryTypes';
import { categoryService } from '../../../services/admin/CategoryService';
import { toast } from 'react-toastify';

export const categoryAction = {
  getAll,
  add,
  edit,
  update,
  deleteCategory,
};

function getAll() {
  return async (dispatch) => {
    dispatch({ type: categoryTypes.GETALL_CATEGORIES_REQUEST });
    try {
      const res = await categoryService.getAll();
      dispatch({
        type: categoryTypes.GETALL_CATEGORIES_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: categoryTypes.GETALL_CATEGORIES_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}

function add(postData, setErrors, setSubmitting, closeModal) {
  return async (dispatch) => {
    dispatch({ type: categoryTypes.ADD_CATEGORY_REQUEST });
    try {
      const res = await categoryService.add(postData);
      dispatch({ type: categoryTypes.ADD_CATEGORY_SUCCESS, payload: res.data });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        closeModal();
        setTimeout(function () {
          dispatch(categoryAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: categoryTypes.ADD_CATEGORY_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function deleteCategory(code, closeModal) {
  return async (dispatch) => {
    dispatch({ type: categoryTypes.DELETE_CATEGORY_REQUEST });
    try {
      const res = await categoryService.deleteCategory(code);
      dispatch({
        type: categoryTypes.DELETE_CATEGORY_SUCCESS,
        payload: res.data.data,
      });
      if (res.data.message) {
        toast.success(res.data.message);
      }
      closeModal();
      setTimeout(function () {
        dispatch(categoryAction.getAll());
      }, 500);
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: categoryTypes.DELETE_CATEGORY_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          closeModal();
        }
      }
      //
    }
  };
}
function update(code, postData, setErrors, setSubmitting, closeModal) {
  return async (dispatch) => {
    dispatch({ type: categoryTypes.UPDATE_CATEGORY_REQUEST });
    try {
      const res = await categoryService.update(code, postData);
      dispatch({
        type: categoryTypes.UPDATE_CATEGORY_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        closeModal();
        setTimeout(function () {
          dispatch(categoryAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: categoryTypes.UPDATE_CATEGORY_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function edit(id) {
  return async (dispatch) => {
    dispatch({ type: categoryTypes.EDIT_CATEGORY_REQUEST });
    try {
      const res = await categoryService.edit(id);
      dispatch({
        type: categoryTypes.EDIT_CATEGORY_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: categoryTypes.EDIT_CATEGORY_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}
