/** @format */

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLoggedInToken } from '../utils/AuthConfig';

const PrivateBrandRoute = () => {
  const isLoggedIn = getLoggedInToken();
  //console.log(Outlet, 'Outlet');
  // if (isLoggedIn) {
  //   return children;
  // }
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to='/brand/login'
      replace
    />
  );
};

export default PrivateBrandRoute;
