/** @format */

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Loader = (props) => {
  return (
    <Box
      className='fullPageLoader'
      sx={{
        position: 'fixed',
        top: '0',
        background: 'rgba(255, 255, 255, 1)',
        width: '100%',
        left: '0',
        height: '100%',
        zIndex: '99998',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          '& > :not(style)': {
            m: 1,
          },
        }}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export const MiniLoader = (props) => {
  return (
    <Box
      className='fullPageLoader'
      sx={{
        position: 'absolute',
        top: '0',
        background: 'rgba(255, 255, 255, 1)',
        width: '100%',
        left: '0',
        height: '100%',
        zIndex: '99998',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          '& > :not(style)': {
            m: 1,
          },
        }}>
        <CircularProgress />
      </Box>
    </Box>
  );
};
