/** @format */

import { approveInfluencerTypes } from '../../types/admin/ApproveInfluencerTypes';
import { approveInfluencerService } from '../../../services/admin/ApproveInfluencerService';
import { toast } from 'react-toastify';

export const approveInfluencerAction = {
  getAll,
  edit,
  update,
};

function getAll() {
  return async (dispatch) => {
    dispatch({
      type: approveInfluencerTypes.GETALL_APPROVE_INFLUENCER_REQUEST,
    });
    try {
      const res = await approveInfluencerService.getAll();
      dispatch({
        type: approveInfluencerTypes.GETALL_APPROVE_INFLUENCER_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveInfluencerTypes.GETALL_APPROVE_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}

function update(code, postData, setErrors, setSubmitting, navigate) {
  return async (dispatch) => {
    dispatch({
      type: approveInfluencerTypes.UPDATE_APPROVE_INFLUENCER_REQUEST,
    });
    try {
      const res = await approveInfluencerService.update(code, postData);
      dispatch({
        type: approveInfluencerTypes.UPDATE_APPROVE_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        navigate('/admin/influencer');
        setTimeout(function () {
          dispatch(approveInfluencerAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveInfluencerTypes.UPDATE_APPROVE_INFLUENCER_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function edit(id) {
  return async (dispatch) => {
    dispatch({ type: approveInfluencerTypes.EDIT_APPROVE_INFLUENCER_REQUEST });
    try {
      const res = await approveInfluencerService.edit(id);
      dispatch({
        type: approveInfluencerTypes.EDIT_APPROVE_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveInfluencerTypes.EDIT_APPROVE_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}
