import React, { useState } from 'react';
import './InfluencersPromoSection.scss'
import InfluencersBanner from '../../assets/influencer-promo-banner.svg'
import Hand from '../../assets/hand-shake.svg'
import Card from '../../assets/credit-card.svg'
import Point from '../../assets/hand-pointing.svg'
import Bulb from '../../assets/bulb.svg'

const promos = [
    {
        heading: "You choose brands",
        icon: Point,
        description: "You can now select well-known brands to work with. You can earn as much money by promoting them as you like."
    },
    {
        heading: "Easy weekly payout",
        icon: Card,
        description: "You can now select well-known brands to work with. You can earn as much money by promoting them as you like."
    },
    {
        heading: "It is a match!",
        icon: Hand,
        description: "You can now select well-known brands to work with. You can earn as much money by promoting them as you like."
    },
    {
        heading: "Ideas for publications",
        icon: Bulb,
        description: "You can now select well-known brands to work with. You can earn as much money by promoting them as you like."
    }
]


const InfluencersPromoSection = () => {
    
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="influencersPromoSection">
        <div className="container">
            <div className="header">Platform for influencers to join</div>
            <div className="title">Social Bottle collaborates with more than 200 brands around the world. Regardless of your followers, you can still make money with us.</div>
            <div className="promoContainer">
                <div className="left">
                    <img src={InfluencersBanner} alt="banner" />
                </div>
                <div className="right">
                    {
                        promos.map((promo, index)=>(
                            <InfluencersPromoCard id={index} isSelected={index===selectedIndex} promo={promo} onClick={()=>{setSelectedIndex(index)}}/>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

const InfluencersPromoCard = ({promo, isSelected, onClick})=>{
   return(
    <div className= {isSelected ? "influencerPromoCard selected" : "influencerPromoCard"} onClick={onClick}>
        <div className="promoCardContainer">
            <div className="logo">
                <img src={promo.icon} alt="" />
            </div>
            <div className="content">
                <div className="heading">{promo.heading}</div>
                {
                    isSelected && <div className="promoContent">{promo.description}</div>
                }
                
            </div>
        </div>
    </div>
   )
}

export default InfluencersPromoSection