/** @format */
import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ImageSec1 from '../../assets/brand_image_sec1.png';
import ImageSec2 from '../../assets/brand_image_sec2.png';
import ImageSec3 from '../../assets/brand_image_sec3.png';

const BrandImageSection = () => {
  return (
    <Box className='brandImageSection'>
      <div className='container'>
        <Grid
          container
          alignItems={'center'}
          spacing={2}>
          <Grid
            item
            xs={6}
            md={6}>
            <Typography
              component={'h2'}
              variant={'h2'}>
              Everything is on us
            </Typography>
            <Typography
              component={'p'}
              variant={'body1'}>
              The platform will select influencers and then check, analyse,
              optimise and scale your campaign
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ textAlign: 'center' }}>
            <img src={ImageSec1} />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={'center'}
          spacing={2}>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ textAlign: 'center' }}>
            <img src={ImageSec1} />
          </Grid>
          <Grid
            item
            xs={6}
            md={6}>
            <Typography
              component={'h2'}
              variant={'h2'}>
              Smart segmentation
            </Typography>
            <Typography
              component={'p'}
              variant={'body1'}>
              The platform will select bloggers and then check, analyse,
              optimise and scale your campaign
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={'center'}
          spacing={2}>
          <Grid
            item
            xs={6}
            md={6}>
            <Typography
              component={'h2'}
              variant={'h2'}>
              Brand-safety and more
            </Typography>
            <Typography
              component={'p'}
              variant={'body1'}>
              See all the influencers and their publications about your brand
            </Typography>
            <Button variant='contained'>Schedule a demo</Button>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{ textAlign: 'center' }}>
            <img src={ImageSec1} />
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default BrandImageSection;
