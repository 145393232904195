/** @format */

import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import './EmailOtpScreen.scss';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '../../components/textfield/TextField';
import validator from 'validator';
import { authAction } from '../../actions/AuthAction';
import { IconButton } from '@mui/material';
import { Remove, RemoveCircle, HighlightOff } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initialUserValues = {
  email: '',
};

const initialErrors = {
  email: '',
};

export default function ChangeEmailModal(props) {
  const [user, setUser] = useState(initialUserValues);

  const [errors, setErrors] = useState(initialErrors);

  function emailValidation() {
    const email = user.email;
    if (validator.isEmpty(email)) {
      return 'Please, enter Email';
    } else if (!validator.isEmail(email)) {
      return 'Please, enter valid Email!';
    }
    return '';
  }

  useEffect(() => {
    setUser((prevValues) => ({ ...prevValues, email: props.email }));
  }, []);

  const onTapEmailButton = () => {
    setErrors((values) => ({
      ...values,
      email: emailValidation(),
    }));
    if (emailValidation() === '') {
      authAction.brandUpdateEmail(props.user_id, user, props.onCloseModal);
    }
  };
  const handleOnBlurTextField = (e) => {};
  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <Modal
        open={props.open}
        //onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={style}
          className='changeEmailWrap'>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'>
            Change Email
          </Typography>
          <IconButton
            aria-label='delete'
            onClick={(e) => {
              props.onCloseModal();
            }}>
            <HighlightOff />
          </IconButton>
          <div className='changeEmail-container'>
            <TextField
              name='email'
              placeholder='Email'
              hintText='Email'
              variant='email'
              value={user.email}
              onChange={handleTextFieldChange}
              onBlur={handleOnBlurTextField}
            />
            {errors.email !== null && errors.email !== '' && (
              <div className='error-message-container'>{errors.email}</div>
            )}
            <div className='changeEmail-button'>
              <button onClick={onTapEmailButton}>Update</button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
