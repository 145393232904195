import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

const About = () => {
  return (
    <div className="about">
      <Navbar/>
      <Footer/>
    </div>
  )
}

export default About