/** @format */

import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import Home from '../pages/home/Home';
import About from '../pages/about/About';
import Brand from '../pages/brands/Brand';
import Influencers from '../pages/influencers/Influencers';
import Contact from '../pages/contact/Contact';
import Help from '../pages/help/Help';
import Support from '../pages/support/Support';
import Terms from '../pages/terms/Terms';
import Privacy from '../pages/privacy/Privacy';
import Blog from '../pages/blog/Blog';
import BlogDetail from '../pages/blog/BlogDetails';
import NewBrand from '../pages/new-brand/NewBrand';
import ThankYouScreen from '../pages/thank-you/ThankYouScreen';
import BrandRouter from './BrandRouter';
import InfluencerRouter from './InfluencerRouter';

function UserRouter() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route
              index
              element={<Home />}
            />
            {/* Top bar Routes */}
            <Route
              path='/influencer'
              element={<Influencers />}
            />
            <Route
              path='/brand'
              element={<Brand />}
            />
            {/* Footer Routing */}
            <Route
              path='help'
              element={<Help />}
            />
            <Route
              path='blog'
              element={<Blog />}
            />
            <Route
              path='blog-details'
              element={<BlogDetail />}
            />
            <Route
              path='support'
              element={<Support />}
            />
            <Route
              path='terms'
              element={<Terms />}
            />
            <Route
              path='privacy'
              element={<Privacy />}
            />
            <Route
              path='contact'
              element={<Contact />}
            />
            <Route
              path='about'
              element={<About />}
            />
          </Route>
          <Route
            path='/influencer/*'
            element={<InfluencerRouter />}
          />

          <Route
            path='/brand/*'
            element={<BrandRouter />}
          />

          <Route
            path='new-brand'
            element={<NewBrand />}
          />
          <Route
            path='thank-you'
            element={<ThankYouScreen />}
          />

          {/* Unknown Routes */}
          {/* <Route
              path='*'
              element={<Brand />}
            /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default UserRouter;
