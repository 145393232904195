/** @format */

import React from 'react';
import '../footer/footer.scss';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo-white.svg';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container'>
        <div className='logo'>
          <img
            src={Logo}
            alt=''
          />
        </div>
        <div className='links'>
          <div className='linkContainer'>
            <Link
              to='/help'
              className='link'>
              <span>Help</span>
            </Link>
            <Link
              to='/support'
              className='link'>
              <span>Support</span>
            </Link>
            <Link
              to='/blog'
              className='link'>
              <span>Blog</span>
            </Link>
            <Link
              to='/terms'
              className='link'>
              <span>Terms of Services</span>
            </Link>
            <Link
              to='/privacy'
              className='link'>
              <span>Privacy Policy</span>
            </Link>
            <Link
              to='/contact'
              className='link'>
              <span>Contact Us</span>
            </Link>
            <Link
              to='/about'
              className='link'>
              <span>About Us</span>
            </Link>
          </div>
          <div className='iconsContainer'>
            <Link to='/'>
              <span className='icon'>
                <FacebookIcon />
              </span>
            </Link>
            <span className='icon'>
              <TwitterIcon />
            </span>
            <span className='icon'>
              <InstagramIcon />
            </span>
            <span className='icon'>
              <LinkedInIcon />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
