import React from "react";
import {Link, useNavigate} from 'react-router-dom'
import './articles.scss'

const Articles = ({article}) => {
    return(
        <div className='article-container'>
            {article.map((item) => (
            <div className='article' key={item.id}>
                <Link to="/blog-details">
                    <img src={item.image} alt="imag"/>
                </Link>
                <div className='content-box'>
                    <div className='article-title'>
                        {item.title}
                    </div>
                    <div className='date'> 
                        <span>08</span>
                        <span>Oct 2022</span>
                    </div>
                </div>
                <div className='author'>{item.author}</div>
            </div>
            ))}
        </div>
    );
}

export default Articles