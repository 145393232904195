/** @format */

import api from './HttpService';
import { AUTH } from '../configs/ApiActionUrl';
// import { getLoggedInToken } from '../utils/AuthConfig';

// const token = getLoggedInToken();

//console.log(token, "token");

export const authService = {
  login,
  OtpSendPhone,
  OtpVerifyPhone,
  brandRegister,
  OtpVerifyEmail,
  brandUpdateEmail,
  brandContactUs,
  // brandCategory,
  // getVerifyLink,
  // logout,
  // forgetPassword,
  // getResetPassword,
  // resetPassword,
};

async function login(postData) {
  const res = await api.post(AUTH.LOGIN, postData);
  return res;
}

async function OtpSendPhone(postData) {
  const res = await api.post(AUTH.OTP_SEND_PHONE, postData);
  return res;
}

async function OtpVerifyPhone(postData, loginVerify) {
  let res; // Declare res outside of the condition

  try {
    if (loginVerify) {
      res = await api.post(AUTH.OTP_VERIFY_PHONE_LOGIN, postData);
    } else {
      res = await api.post(AUTH.OTP_VERIFY_PHONE, postData);
    }

    // Return the response data
    return res.data;
  } catch (error) {
    // Handle errors here, you can log or rethrow the error as needed
    console.error('Error:', error);
    throw error; // Rethrow the error to handle it in the calling code
  }
}

// async function OtpVerifyPhone(postData) {
//   const res = await api.post(AUTH.OTP_VERIFY_PHONE, postData);
//   return res;
// }

async function OtpVerifyEmail(postData) {
  const res = await api.post(AUTH.OTP_VERIFY_EMAIL, postData);
  return res;
}

async function brandRegister(postData) {
  // Convert the categories field
  const convertedCategories = postData.categories.map(
    (category) => category.value
  );
  // Create the updated object
  const updatedData = {
    ...postData,
    categories: convertedCategories,
  };

  const res = await api.post(AUTH.BRAND_REGISTER, updatedData);
  return res;
}

async function brandUpdateEmail(code, postData) {
  const res = await api.put(AUTH.UPDATE_USER_EMAIL + '/' + code, postData);
  return res;
}

async function brandContactUs(postData) {
  const res = await api.post(AUTH.CONTACT_US, postData);
  return res;
}

// async function brandCategory() {
//   const res = await api.get(AUTH.CATEGORY);
//   return res;
// }

// async function getVerifyLink(module, code) {
//   const res = await api.get(AUTH.VERIFY_LINK + '/module/' + code);
//   return res;
// }

// async function forgetPassword(postData) {
//   const res = await api.post(AUTH.FORGOT_PASSWORD, postData);
//   return res;
// }

// async function getResetPassword(code, key) {
//   const res = await api.get(
//     AUTH.GET_RESET_PASSWORD + "?token=" + code + "&email=" + key
//   );
//   return res;
// }

// async function resetPassword(postData) {
//   const res = await api.post(AUTH.RESET_PASSWORD, postData);
//   return res;
// }

// async function logout(postData) {
//   const res = await api.post(AUTH.LOGOUT, postData);
//   return res;
// }
