import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./NewBrand.scss";
import Logo from "../../assets/logo-black.svg";
import AuthBanner from "../../components/authbanner/AuthBanner";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '../../components/textfield/TextField';
import SelectField from '../../components/selectfield/SelectField';


const handleTextFieldChange = (e)=>{
}

const handleOnBlurTextField = (e)=>{
}

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const NewBrand = () => {
  const navigate = useNavigate()
  const [isRememberMe, setRememberMe]= useState(false)
  const [selectedOption, setSelectedOption] = useState(null);
  const onTapContinueButton = ()=>{
    navigate('/verify-otp')
  }
  
  return (
    <div className="new-brand">
      <div className="wrapper">
        <div className="left-sec">
          <div className="container">
            <div className="logo">
              <img src={Logo} alt="app-logo" />
            </div>
            <div className="role-sec">
              <div className="title">
                Describe your brand
              </div>
              <div className="brand-info">
                <div className="profile-name-sec">
                  <TextField name="firstName" placeholder="FirstName" hintText="First Name" variant="normal" onChange={handleTextFieldChange} />
                  <TextField name="lastName"  placeholder="Last Name" hintText="Last Name" variant="normal" onChange={handleTextFieldChange}/>
                </div>
                <TextField name="companyName" placeholder="Company Name" hintText="Company Name" variant="normal" onChange={handleTextFieldChange} />
                
                <SelectField 
                  hintText="Category"
                  name="category"
                  placeholder="Please Select Category"
                  defaultValue={selectedOption}
                  options={options}
                  onChange={setSelectedOption}
                />
                <TextField name="email" placeholder="e.g john@mail.com" hintText="Email" variant="normal" onChange={handleTextFieldChange} />
                <TextField name="password"  placeholder="Password" hintText="Password" variant="password" onChange={handleTextFieldChange}/>
                <div className="password-hint-sec">
                    <span className="password-hint-text">
                        The password must contain at least 8 characters, include letters in different case, as well as at least one number and one special character: @#$%&*~0+=-,
                    </span>
                </div>
                <div className="terms-and-condtions-sec">
                    <div className="terms-and-condtions-checkbox">{isRememberMe ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}</div>
                    <div className="terms-and-condtions-container-text">I accept <span className="terms-link">the offer agreement </span>and give <span className="terms-link">consent</span> to the pyocessing of my personal data</div>
                </div>
                <div className="next-button">
                  <button onClick={onTapContinueButton}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-sec">
          <AuthBanner />
        </div>
      </div>
    </div>
  );
  };
export default NewBrand;
