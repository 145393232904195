import React from 'react'
import './MarketingDataSection.scss'

const infos = [
    {
        title: "Total accounts in database",
        data: "50.5M+"
    },
    {
        title: "New Influencers discovered daily",
        data: "19K"
    },
    {
        title: "Total report requests processed",
        data: "5M+"
    },
    {
        title: "Of all known fraud activity detected",
        data: "96.3%"
    },
]

const MarketingDataSection = () => {
  return (
    <div className="marketSection">
        <div className="container">
            <div className="header">We deliver industry standard data & insights for</div>
            <div className="title">Success in Influencer marketing</div>
            <div className="marketing">
                {
                    infos.map((info, index)=>(
                        <MarketingInfoCard info={info}/>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

const MarketingInfoCard = ({info})=>{
    return (
        <div className="infoCard">
            <div className="data">{info.data}</div>
            <div className="info">{info.title}</div>
        </div>

    )
}

export default MarketingDataSection