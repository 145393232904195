/** @format */
import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import BannerRight from '../../assets/brand_banner_rgt.png';

const BrandBanner = () => {
  return (
    <Box className='bannerSection'>
      <div className='container'>
        <Grid
          container
          alignItems={'center'}
          spacing={2}>
          <Grid
            item
            xs={6}
            md={6}>
            <Typography
              component={'h2'}
              variant={'h2'}>
              Boost your brand awareness
            </Typography>
            <Typography
              component={'p'}
              variant={'body1'}>
              Utilize social media platforms to increase visibility and
              engagement with your target audience.Make profit and build your
              individual brand.
            </Typography>
            <Button variant='contained'>Get started as Brand</Button>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}>
            <img src={BannerRight} />
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default BrandBanner;
