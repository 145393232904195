/** @format */
import React from 'react';
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserRouter from './router/UserRouter';
import { useLocation } from 'react-router-dom';
import AdminRouter from './router/AdminRouter';
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
    palette: {
      primary: {
        main: '#44bba4',
        text: '#ffffff',
      },
      secondary: {
        main: '#263238',
      },
      // teritary: {
      //   main: '#df7a5e',
      // },
      // info: {
      //   main: '#df7a5e',
      // },
      // lightYellow: {
      //   main: '#f4f1de',
      // },
    },
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '16px',
            boxShadow: 'none',
            fontWeight: '500',
            color: '#fff',
            borderRadius: '5px',
            textTransform: 'none',
          },
          containedPrimary: {
            color: '#ffffff',
            background: '#44bba4',
            '& i.icon-plus': {
              fontSize: '15px',
            },
            // outline: "2px solid rgba(234,232,220,0.3)",
            '&:hover': {
              backgroundColor: '#fff !important',
              //border:"1px solid #df7a5e",
              outline: '2px solid #44bba4',
              color: '#44bba4',
              boxShadow: 'none',
            },
          },
          containedSecondary: {
            background: '#3c405b',
            border: '1px solid #3c405b',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
              border: '1px solid #3c405b',
              color: '#3c405b',
              boxShadow: 'none',
            },
          },
          containedInfo: {
            background: '#f4f1de',
            border: '1px solid #f4f1de',
            color: '#000000',
            '&:hover': {
              backgroundColor: '#fff',
              border: '1px solid #f4f1de',
              color: '#000000',
              boxShadow: 'none',
            },
          },
          containedTeritary: {
            background: '#81b39a',
            border: '1px solid #81b39a',
            color: '#000000',
            '&:hover': {
              backgroundColor: '#fff',
              border: '1px solid #81b39a',
              color: '#81b39a',
              boxShadow: 'none',
            },
          },
        },
      },
      // MuiIconButton: {
      //   styleOverrides: {
      //     // Name of the slot
      //     root: {
      //       // Some CSS
      //       fontSize: '18px',
      //       boxShadow: 'none',
      //       borderRadius: '5px',
      //       padding: '8px',
      //       textTransform: 'none',
      //     },
      //     colorPrimary: {
      //       padding: '0px',
      //       fontSize: '22px',
      //       width: '32px',
      //       height: '32px',
      //       background: '#df7a5e',
      //       border: '1px solid #df7a5e',
      //       color: '#000000',
      //       '& i.icon-user-active': {
      //         fontSize: '18px',
      //       },
      //       '&:hover': {
      //         backgroundColor: '#fff',
      //         border: '1px solid #df7a5e',
      //         color: '#df7a5e',
      //         boxShadow: 'none',
      //       },
      //     },
      //     colorSecondary: {
      //       padding: '0px',
      //       width: '32px',
      //       height: '32px',
      //       background: '#81b39a',
      //       border: '1px solid rgba(20,20,20,0.2)',
      //       color: '#000000',
      //       '&:hover': {
      //         backgroundColor: '#fff',
      //         border: '1px solid #81b39a',
      //         color: '#000000',
      //         boxShadow: 'none',
      //       },
      //     },
      //   },
      // },
      // MuiDatePicker: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: 'red',
      //     },
      //   },
      // },
    },
  });
  const location = window.location.pathname;
  const route = location.split('/');

  console.log(route, 'route');

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position='top-right'
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {route[1] === 'admin' ? <AdminRouter /> : <UserRouter />}
      </ThemeProvider>
    </div>
  );
}

export default App;
