import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import BlogDetails from './details/BlogDetails'

const BlogDetail = () => {
  return (
    <div className="blog-details">
      <Navbar/>
      <BlogDetails/>
      <Footer/>
      </div>
  )
}

export default BlogDetail