/** @format */

import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import LoginForm from '../admin/auth/LoginForm';
import { getAdminLoggedInToken } from '../utils/AdminAuthConfig';
import { HeaderLayout } from '../admin/layout/Header';

function AdminRouter() {
  const AdminPrivateRoute = () => {
    const auth = getAdminLoggedInToken();
    return auth ? <Outlet /> : <Navigate to='/admin/dashboard' />;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            index
            path='/admin'
            element={<LoginForm />}
          />
          <Route
            path='/'
            element={<AdminPrivateRoute />}>
            <Route
              path='/admin/*'
              index
              element={<HeaderLayout />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AdminRouter;
