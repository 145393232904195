/** @format */

import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import './Contact.scss';
// import TextField from '../../components/textfield/TextField';
import {
  Box,
  Grid,
  Paper,
  Button,
  CircularProgress,
  TextField,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { authAction } from '../../actions/AuthAction';

const ContactValidate = Yup.object().shape({
  name: Yup.string()
    .max(128, 'Name cannot exceed 128 characters')
    .required('Name cannot be empty'),
  message: Yup.string()
    .max(2048, 'Message cannot exceed 2048 characters')
    .required('Message cannot be empty'),
  email: Yup.string()
    .email('Enter a valid Email Address')
    .max(64, 'Email Address cannot exceed 64 characters')
    .min(8, 'Email Address cannot be less than 8 characters')
    .required('Email Address cannot be empty'),
});

const Contact = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className='contactWrap'>
        <Navbar />
        <Box className='form-container'>
          <Grid
            container
            rowSpacing={3}
            sx={{
              mt: 0,
              alignItems: 'center',
              '.MuiChip-root': {
                '.MuiChip-deleteIcon': {
                  color: '#fff',
                  fontSize: '18px',
                },
              },
            }}
            columnSpacing={3}>
            <Grid
              className='left-sec'
              sx={{}}
              item
              xs={6}
              md={6}>
              <div className='title-part'>
                <div className='title'>
                  Let’s level up your <br></br> brand, togather
                </div>
                <div className='tagline'>
                  you can reach us anytime via <span>hi@socialbottle.com</span>
                </div>
              </div>
              <div className='form-part'>
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    ph_number: '',
                    message: '',
                  }}
                  // enableReinitialize
                  validationSchema={ContactValidate}
                  onSubmit={(
                    values,
                    { setErrors, setSubmitting, resetForm }
                  ) => {
                    authAction.brandContactUs(
                      values,
                      setErrors,
                      setSubmitting,
                      resetForm
                    );
                  }}>
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className='form_wrapper'>
                        <Box className=''>
                          <Paper
                            elevation={0}
                            sx={{ background: 'none' }}>
                            <Box sx={{ mb: 3 }}>
                              <FormHelperText htmlFor='my-input'>
                                Name
                              </FormHelperText>
                              <TextField
                                error={errors.name && touched.name}
                                // label='Name'
                                hiddenLabel
                                placeholder='Your Full Name'
                                size='large'
                                fullWidth
                                name='name'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.name && touched.name && errors.name
                                }
                                margin='none'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                              />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                              <FormHelperText htmlFor='my-input'>
                                Email Address
                              </FormHelperText>
                              <TextField
                                error={errors.email && touched.email}
                                // label='Email Address'
                                hiddenLabel
                                placeholder='Enter Email Address'
                                size='large'
                                fullWidth
                                name='email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.email && touched.email && errors.email
                                }
                                margin='none'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                              />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                              <FormHelperText htmlFor='my-input'>
                                Phone number
                              </FormHelperText>
                              <TextField
                                id='filled-ph_number-input'
                                fullWidth
                                variant='outlined'
                                hiddenLabel
                                name='ph_number'
                                size='large'
                                margin='none'
                                placeholder='xxx xxx xxx'
                                error={errors.ph_number && touched.ph_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.ph_number &&
                                  touched.ph_number &&
                                  errors.ph_number
                                }
                                required={false}
                                InputLabelProps={{ required: true }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      +91 |
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                              <FormHelperText htmlFor='my-input'>
                                How can we help you?
                              </FormHelperText>
                              <TextField
                                id='filled-message-input'
                                fullWidth
                                multiline
                                minRows={3}
                                row={4}
                                variant='outlined'
                                hiddenLabel
                                name='message'
                                size='large'
                                margin='none'
                                // placeholder='xxx xxx xxx'
                                error={errors.message && touched.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.message &&
                                  touched.message &&
                                  errors.message
                                }
                                required={false}
                                InputLabelProps={{ required: true }}
                              />
                            </Box>
                            <Box sx={{}}>
                              <Button
                                type='submit'
                                className='submit_btn'
                                variant='contained'
                                sx={{ mt: 3, mb: 1 }}
                                fullWidth
                                size='large'
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  'Submit'
                                )}
                              </Button>
                            </Box>
                          </Paper>
                        </Box>
                      </div>
                    </form>
                  )}
                </Formik>
                {/* <TextField
                  name='name'
                  placeholder='Yout Full Name'
                  hintText='Name'
                  variant='normal'
                  onChange={handleTextFieldChange}
                />
                <TextField
                  name='email'
                  placeholder='e.g john@mail.com'
                  hintText='Email'
                  variant='normal'
                  onChange={handleTextFieldChange}
                />
                <TextField
                  name='phoneNumber'
                  placeholder='xxx xxx xxx'
                  hintText='Phone number'
                  variant='phone'
                  onChange={handleTextFieldChange}
                />
                <TextField
                  name='name'
                  placeholder='Add your comment here..'
                  hintText='How can we help you?'
                  variant='textarea'
                />
                <div className='submit-button'>
                  <button>Submit</button>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      {/* <div className='right-sec'>
          <div className='map-part'>
            <img
              src={Map}
              alt='map'
            />
          </div>
          <div className='map-gradient'></div>
        </div> */}

      <Footer />
    </div>
  );
};

export default Contact;
