/** @format */

import { adminAuthService } from '../../../services/admin/AdminAuthService';
import {
  clearAdminLocalStorage,
  setAdminAccessToken,
  setAdminRefreshToken,
  setAdminUserData,
} from '../../../utils/AdminAuthConfig';
import { adminAuthTypes } from '../../types/admin/AdminAuthTypes';
import { toast } from 'react-toastify';

export const adminAuthAction = {
  login,
  logout,
};

function login(postData, setErrors, setSubmitting) {
  return async (dispatch) => {
    dispatch({ type: adminAuthTypes.ADMIN_LOGIN_REQUEST });
    try {
      const res = await adminAuthService.login(postData);
      dispatch({ type: adminAuthTypes.ADMIN_LOGIN_SUCCESS, payload: res.data });
      if (res.data) {
        if (res.data.data) {
          // navigate('/influencer/dashboard');
          if (res.data.data.accessToken) {
            setAdminAccessToken(res.data.data.accessToken);
          }
          if (res.data.data.refreshToken) {
            setAdminRefreshToken(res.data.data.refreshToken);
          }
          if (res.data.data.userData) {
            setAdminUserData(res.data.data.userData);
          }
        }
        setSubmitting(false);
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: adminAuthTypes.ADMIN_LOGIN_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function logout() {
  //localStorage.clear();
  clearAdminLocalStorage();
  window.location.href = '/admin';
  toast.success('Logged out successfully');
}
