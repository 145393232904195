/** @format */

const setRegisterData = (data) => {
  localStorage.setItem('registerData', JSON.stringify(data));
};

const setAccessToken = (accessToken) => {
  localStorage.setItem('accessToken', accessToken);
};

const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refreshToken', refreshToken);
};

const setUserData = (data) => {
  localStorage.setItem('userData', JSON.stringify(data));
};

const clearLocalStorage = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userData');
};

// Gets the logged in access token from local session
const getRefreshToken = () => {
  const user = localStorage.getItem('refreshToken');
  if (user) return JSON.parse(user);
  return null;
};

// Gets the logged in refresh token from local session
const getLoggedInToken = () => {
  const token = localStorage.getItem('accessToken');
  if (token) return token;
  return false;
};
// Gets the logged in user data from local session
const getUserData = () => {
  const user = localStorage.getItem('userData');
  if (user) return JSON.parse(user);
  return null;
};

// Gets the logged in access token from local session
const getRegisterData = () => {
  const registerData = localStorage.getItem('registerData');
  if (registerData) return JSON.parse(registerData);
  return null;
};

export {
  setAccessToken,
  setRefreshToken,
  setUserData,
  clearLocalStorage,
  getRefreshToken,
  getUserData,
  getLoggedInToken,
  setRegisterData,
  getRegisterData,
};
