/** @format */

import { ADMIN_CATEGORIES } from '../../configs/ApiActionUrl';
import adminApi from './AdminHttpService';
export const categoryService = {
  getAll,
  add,
  edit,
  update,
  deleteCategory,
  updateStatus,
  getActiveAll,
};

async function getAll() {
  const res = await adminApi.get(ADMIN_CATEGORIES.LIST);
  if (res.status) {
  }
  return res;
}
async function getActiveAll() {
  const res = await adminApi.get(
    ADMIN_CATEGORIES.GETALL_ACTIVE + '/activeCategorys'
  );

  return res;
}

async function add(data) {
  const res = await adminApi.post(ADMIN_CATEGORIES.ADD, data);
  return res;
}
async function edit(id) {
  const res = await adminApi.get(ADMIN_CATEGORIES.GET_ITEM + '/' + id);
  return res;
}
async function update(code, data) {
  const res = await adminApi.put(
    ADMIN_CATEGORIES.UPDATE_INDIVIDUAL + '/' + code,
    data
  );
  return res;
}

async function updateStatus(code, data) {
  const res = await adminApi.put(
    ADMIN_CATEGORIES.UPDATE_STATUS + '/status/' + code,
    data
  );
  return res;
}

async function deleteCategory(code) {
  const res = await adminApi.delete(ADMIN_CATEGORIES.DELETE + '/' + code, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
}
