/** @format */

import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { authentication } from './reducers/influencer/auth.reducers';
import { brandcampaign } from './reducers/brand/campaign.reducer';
import { adminauthentication } from './reducers/admin/adminauth.reducers';
import { categories } from './reducers/admin/category.reducer';
import { requirements } from './reducers/admin/requirement.reducer';
import { approveInfluencers } from './reducers/admin/approveInfluencer.reducer';
import { approveBrands } from './reducers/admin/approveBrand.reducer';
import { influencercampaign } from './reducers/influencer/campaign.reducer';

export const rootReducer = {
  authentication: authentication,
  brandcampaign: brandcampaign,
  adminauthentication: adminauthentication,
  categories: categories,
  requirements: requirements,
  approveInfluencers: approveInfluencers,
  approveBrands: approveBrands,
  influencercampaign: influencercampaign,
};
export const store = configureStore(
  { reducer: rootReducer },
  applyMiddleware(thunkMiddleware)
);
