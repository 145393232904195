/** @format */

export const adminAuthTypes = {
  ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
  ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  ADMIN_LOGIN_ERROR: 'ADMIN_LOGIN_ERROR',

  // LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  // LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  // LOGOUT_ERROR: 'LOGOUT_ERROR',

  // FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  // FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  // FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

  // GET_RESET_PASSWORD_REQUEST: 'GET_RESET_PASSWORD_REQUEST',
  // GET_RESET_PASSWORD_SUCCESS: 'GET_RESET_PASSWORD_SUCCESS',
  // GET_RESET_PASSWORD_ERROR: 'GET_RESET_PASSWORD_ERROR',

  // RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  // RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  // RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
};
