/** @format */

import React, { useState, useEffect } from 'react';
import './topics.scss';
import AuthBanner from '../../components/authbanner/AuthBanner';
import Logo from '../../assets/logo-black.svg';
import TextField from '../../components/textfield/TextField';
import SelectField from '../../components/selectfield/SelectField';
import validator from 'validator';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../store/actions/influencer/authAction';
import { Loader } from '../../utils/Loader';

const initialUserValues = {
  topics: '',
  language: '',
};

const initialErrors = {
  topics: '',
  language: '',
};

const options = [{ value: 'newdelhi', label: 'NewDelhi' }];

const TopicAndLanguageInput = () => {
  const [user, setUser] = useState(initialUserValues);
  const [errors, setErrors] = useState(initialErrors);
  const [options, setOptions] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [langOptions, setLangOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    // JavaScript logic here (the code you provided)
    const sourceDiv = document.getElementById('container-left');
    const targetDiv = document.getElementById('container-right');

    const sourceDivHeight = sourceDiv.getBoundingClientRect().height;
    console.log(sourceDivHeight);
    targetDiv.style.maxHeight = sourceDivHeight + 'px';
  }, []);

  useEffect(() => {
    dispatch(authAction.fetchTopicsOptions());
    dispatch(authAction.fetchLanguageOptions());
    //dispatch(authAction.verifyLink('register', id, navigate));
  }, [dispatch]);

  const { language, topics, isLoading } = useSelector(
    (state) => state.authentication
  );
  useEffect(() => {
    setUser((prevValues) => ({ ...prevValues, holderId: id }));

    if (topics && topics.data) {
      const transformedData = topics.data.map((item) => ({
        value: item._id,
        label: item.name,
      }));
      setTopicOptions(transformedData);
    }

    if (language && language.data) {
      const transformedData = language.data.map((item) => ({
        value: item._id,
        label: item.name,
      }));
      setLangOptions(transformedData);
    }
  }, [topics, language]);

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOnBlurTextField = (e) => {};

  const [selectedOption, setSelectedOption] = useState(null);

  const onTabNextButton = () => {
    setErrors((values) => ({
      ...values,
      topics: topicsValidation(),
      language: languageValidation(),
    }));
    const paramValue = 'social';
    if (topicsValidation() === '' && languageValidation() === '') {
      console.log(user, 'user');

      const transformedData = {
        topics: user.topics.map((topic) => topic.value),
        language: user.language.map((lang) => lang.value),
      };

      console.log(transformedData);

      dispatch(authAction.topicLanguagePost(id, transformedData, navigate));
    }
  };

  function topicsValidation() {
    if (!user.topics) {
      return (errors.topics = 'Please select a topics');
    } else if (user.topics.length > 3) {
      return (errors.topics = 'Topics not more than 3');
    }
    return '';
  }

  function languageValidation() {
    if (!user.language) {
      return (errors.language = 'Please select a language');
    }
    return '';
  }

  function handleSelectFieldChange(name, selectedOption) {
    setUser((prevValues) => ({ ...prevValues, [name]: selectedOption }));
  }
  return (
    <div className='profile-input'>
      {/* {isLoading ? <Loader /> : ''} */}
      <div className='profile-input-container'>
        <div
          id='container-left'
          className='profile-input-container-left'>
          <div className='profile-input-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='profile-input-container-left-content-fields'>
              <div className='profile-input-header'>Describe yourself</div>
              <div className='profile-name-fileds-container'>
                <div className='selectDropdownTextField'>
                  <SelectField
                    label={'Topics (not more than 3)'}
                    name='topics'
                    options={topicOptions}
                    selectedItem={user.topics}
                    multiple={true}
                    placeholder={
                      <div className='placeholder-text'>Select Topics</div>
                    }
                    setselectedItem={(selectedOption) =>
                      handleSelectFieldChange('topics', selectedOption)
                    }
                  />
                  {errors.topics !== null && errors.topics !== '' && (
                    <div className='error-message-container'>
                      {errors.topics}
                    </div>
                  )}
                </div>
                <div className='selectDropdownTextField'>
                  <SelectField
                    label={'Language'}
                    name='language'
                    options={langOptions}
                    multiple={true}
                    selectedItem={user.language}
                    placeholder={
                      <div className='placeholder-text'>Select Language</div>
                    }
                    setselectedItem={(selectedOption) =>
                      handleSelectFieldChange('language', selectedOption)
                    }
                  />
                  {errors.language !== null && errors.language !== '' && (
                    <div className='error-message-container'>
                      {errors.language}
                    </div>
                  )}
                </div>
              </div>
              <div className='next-button'>
                <button onClick={onTabNextButton}>Finish</button>
              </div>
            </div>
          </div>
        </div>
        <div
          id='container-right'
          className='profile-input-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default TopicAndLanguageInput;
