import React from 'react'
import './PromoBanner.scss'
import Banner from '../../assets/promo_banner.svg'
const PromoBanner = () => {
  return (
    <div className="promoBanner">
        <div className="container">
            <div className="left">
                <div className="content">
                    <div className="header">Brand promotions via influencers</div>
                    <div className="title">Enhance brand exposure, drive sales, and draw in new clients.</div>
                    <button className="brandButton">Get started as Brand</button>
                </div>
            </div>
            <div className="right">
                <img src={Banner} alt="Banner" />
            </div>
        </div>
    </div>
  )
}


export default PromoBanner