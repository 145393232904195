/** @format */

import adminApi from './AdminHttpService';
import { ADMIN_AUTH } from '../../configs/ApiActionUrl';
// import { getLoggedInToken } from '../utils/INFLUENCER_AUTHConfig';

// const token = getLoggedInToken();

//console.log(token, "token");

export const adminAuthService = {
  login,
  // brandCategory,
  // getVerifyLink,
  // logout,
  // forgetPassword,
  // getResetPassword,
  // resetPassword,
};

async function login(postData) {
  const res = await adminApi.post(ADMIN_AUTH.LOGIN, postData);
  return res;
}

// async function brandCategory() {
//   const res = await api.get(AUTH.CATEGORY);
//   return res;
// }

// async function getVerifyLink(module, code) {
//   const res = await api.get(AUTH.VERIFY_LINK + '/module/' + code);
//   return res;
// }

// async function forgetPassword(postData) {
//   const res = await api.post(AUTH.FORGOT_PASSWORD, postData);
//   return res;
// }

// async function getResetPassword(code, key) {
//   const res = await api.get(
//     AUTH.GET_RESET_PASSWORD + "?token=" + code + "&email=" + key
//   );
//   return res;
// }

// async function resetPassword(postData) {
//   const res = await api.post(AUTH.RESET_PASSWORD, postData);
//   return res;
// }

// async function logout(postData) {
//   const res = await api.post(AUTH.LOGOUT, postData);
//   return res;
// }
