import React from 'react'
import Banner from '../../assets/auth/auth-banner.svg'

const AuthBanner = () => {
  return (
    // <div className="auth-banner">
         <img src={Banner} alt="auth-banner1" />
    // </div>
  )
}

export default AuthBanner