/** @format */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';
import { categoryAction } from '../../../store/actions/admin/categoryAction';
import { MiniLoader } from '../../../utils/Loader';
import { CategoriesValidation } from '../../../utils/AdminValidation';

/*Modal Style */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 450,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 1,
  p: 6,
};

export default function CategoryEdit(props) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.category_id) {
      dispatch(categoryAction.edit(props.category_id));
    }
  }, [dispatch, props.category_id]);

  const { categoryData, categoryData_status, loading } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    if (categoryData_status === true) {
      setData(categoryData);
    }
  }, [categoryData_status, categoryData]);

  return (
    <div>
      <Modal
        className='cmn__form__modal'
        keepMounted
        open={props.openmodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          {loading === true ? <MiniLoader /> : ''}
          <Typography
            id='modal-title'
            variant='h4'
            component='h2'>
            Edit Category
          </Typography>
          <Formik
            initialValues={{
              name: data && data.name ? data.name : '',
            }}
            enableReinitialize
            validationSchema={CategoriesValidation}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              dispatch(
                categoryAction.update(
                  props.category_id,
                  values,
                  setErrors,
                  setSubmitting,
                  props.onCloseModal
                )
              );
            }}>
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              isSubmitting,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='modalform'>
                  <Box
                    className='innerContainer'
                    sx={{}}>
                    <Box
                      sx={{
                        background: '#fff',
                        padding: '0',
                      }}>
                      <Paper elevation={0}>
                        <TextField
                          id='name'
                          fullWidth
                          label='Category Name'
                          name='name'
                          placeholder='Enter Category Name'
                          margin='normal'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={(e) => {
                            const val = e.target.value
                              .trim()
                              .replace(/\s+/g, ' ');
                            setFieldValue(e.target.name, val);
                            handleBlur(e);
                          }}
                          error={errors.name && touched.name}
                          helperText={
                            errors.name && touched.name && errors.name
                          }
                          required={false}
                          InputLabelProps={{ required: true }}
                        />
                      </Paper>
                      <Stack
                        direction='row'
                        spacing={2}
                        className='button_group'>
                        <Button
                          type='submit'
                          className='save__btn'
                          variant='contained'
                          color='secondary'
                          fullWidth
                          disabled={isSubmitting}
                          size='large'>
                          {isSubmitting ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: '#fff' }}
                            />
                          ) : (
                            'Update'
                          )}
                        </Button>
                        <Button
                          type='button'
                          className='cancel__btn'
                          variant='contained'
                          color='info'
                          fullWidth
                          size='large'
                          onClick={(e) => {
                            props.onCloseModal();
                            handleReset();
                          }}>
                          Cancel
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </div>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
