/** @format */
import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import VideoImage from '../../assets/brand_video_sec.png';
import infLogo1 from '../../assets/inf_logo1.svg';
import infLogo2 from '../../assets/inf_logo2.svg';
import infLogo3 from '../../assets/inf_logo3.svg';
import infLogo4 from '../../assets/inf_logo4.svg';
import infLogo5 from '../../assets/inf_logo5.svg';

const BrandVideoSec = () => {
  return (
    <Box className='BrandVideoSec'>
      <div className='container'>
        <Typography
          variant='h2'
          component={'h2'}>
          We offer you access to a huge database of influencers on Social Bottle
        </Typography>
        <Typography
          variant='body1'
          component={'p'}>
          Our cutting-edge analytical technology chooses influencers who are the
          ideal fit for your brand. Our 1,000+ influencers hunt down fresh
          micro-influencers to spread the word about your company.
        </Typography>
        <Box
          className='vidoeWrap'
          sx={{ mt: 8 }}>
          <img
            src={VideoImage}
            alt='Video Image'
          />
        </Box>
        <Box
          className='logoWrap'
          sx={{ py: 8 }}>
          <Typography
            variant='h3'
            component={'h3'}>
            more than 7 million leads have been produced globally.
          </Typography>
          <Typography
            variant='body1'
            component={'p'}>
            Trusted by:
          </Typography>
          <Stack
            sx={{ my: 5 }}
            alignItems={'center'}
            justifyContent={'center'}
            direction={'row'}
            spacing={10}>
            <img
              src={infLogo1}
              alt='Logo Image 1'
            />
            <img
              src={infLogo5}
              alt='Logo Image 5'
            />

            <img
              src={infLogo3}
              alt='Logo Image 3'
            />
            <img
              src={infLogo2}
              alt='Logo Image 2'
            />
            <img
              src={infLogo4}
              alt='Logo Image 4'
            />
          </Stack>
        </Box>
      </div>
    </Box>
  );
};

export default BrandVideoSec;
