/** @format */

import React from 'react';
import Select from 'react-select';

// const cityOptions = [
//   { value: '01', label: 'NewDelhi' },
//   { value: '02', label: 'Chennai' },
//   { value: '03', label: 'Bangalore' },
// ];

const SelectField = ({
  label,
  name,
  options,
  selectedItem,
  setselectedItem,
  placeholder,
  multiple,
}) => {
  const handleItemChange = (selectedOption) => {
    setselectedItem(selectedOption);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 54,
      minHeight: 54,
      border: '1px solid #DBDBDB',
      borderRadius: '8px',
    }),
  };

  return (
    <div className='select-field'>
      <div className='text-field'>
        <div className='text-field-container'>
          <div className='helper-text'>{label}</div>
          <Select
            name={name}
            className='select'
            value={selectedItem}
            onChange={handleItemChange}
            isMulti={multiple}
            options={options}
            styles={customStyles}
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectField;
