/** @format */

import React from 'react';
import { useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridActionsCellItem,
  //GridLinkOperator,
} from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import CategoryAdd from './add';
import CategoryEdit from './edit';
import ConfirmModal from './confirmModal';
import { categoryAction } from '../../../store/actions/admin/categoryAction';

// import LoadingSkeleton from '../../../helpers/LoadingSkeleton';
// import CustomNoRowsOverlay from '../../../helpers/NoRowOverlay';

function QuickSearchToolbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  return (
    <Box
      className='tableTopWrap page__title'
      sx={{
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 3,
      }}>
      <Stack
        direction='row'
        sx={{ ml: 'auto' }}
        spacing={2}
        className='hierarchy_list_btn'>
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput
              .split(',')
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }
          variant='outlined'
          size='small'
          sx={{ pb: 0 }}
        />
        <Button
          className='button_primary'
          variant='contained'
          startIcon={<i className='icon-plus'></i>}
          onClick={handleOpen}>
          Add Category
        </Button>
      </Stack>
      {open === true ? (
        <CategoryAdd
          openmodal={open}
          onCloseModal={() => setOpen(false)}
        />
      ) : (
        ''
      )}
    </Box>
  );
}

function CategoriesList() {
  const [editOpen, setEditOpen] = React.useState(false);
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [itemId, setItemID] = React.useState('');
  const [dataList, setDataList] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    action: false,
  });
  const [itemInfo, setItemInfo] = React.useState({
    id: '',
    status: '',
    confirm_status: '',
  });

  const handleEditOpen = () => setEditOpen(true);
  const handleDialogClickOpen = (id) => {
    setDialogOpen(true);
  };
  const dispatch = useDispatch();

  const initFetch = useCallback(() => {
    dispatch(categoryAction.getAll());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const { category_data, category_status } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    if (category_status === true) {
      setDataList(category_data);
    }
  }, [category_data, category_status]);

  useEffect(() => {
    setLoader(true);
    // if (dataList && dataList.length > 0) {
    //   setTableData(dataList);
    // }

    const timeoutId = setTimeout(() => {
      setLoader(false);
      setTableData(dataList);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dataList]);

  const dataRow = [
    {
      field: 'id',
      headerName: 'S.No',
      filterable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'name',
      headerName: 'Category Name',
      flex: 1,
      renderCell: (params) => {
        if (params && params.row) {
          return <div className='name'>{params.row.name}</div>;
        } else return null;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          className='actionBtn'
          label='Delete'
          onClick={() => {
            handleEditOpen();
            setItemID(params.id);
          }}
          //onClick={deleteUser(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          className='actionBtn'
          label='Toggle Admin'
          onClick={() => {
            handleDialogClickOpen();
            setItemInfo({
              id: params.id,
              status: 3,
              confirm_status: 'delete',
            });
          }}
          //onClick={toggleAdmin(params.id)}
          //showInMenu
        />,
      ],
    },
  ];

  return (
    <Box
      sx={{ mb: 3 }}
      className='cardWrapper'>
      <div className='pageTitleWrap'>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'>
          <Typography
            className=''
            component={'h2'}
            variant='h2'>
            Category List
          </Typography>
        </Stack>
      </div>
      <Box
        sx={{ bgcolor: 'white' }}
        className='dataTable__wrap'>
        <DataGrid
          columns={dataRow}
          rows={tableData}
          disableColumnFilter
          rowHeight={55}
          disableMultipleRowSelection={true}
          getRowId={(row) => row._id}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          autoHeight
          sx={{ minHeight: 650 }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          slots={{
            toolbar: QuickSearchToolbar,
            // loadingOverlay: LoadingSkeleton,
            // noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={
            {
              // toolbar: { addPermission: designModuleAcesss },
            }
          }
          loading={loader}
          //componentsProps={{ toolbar: { groups__data: groupsList } }}
        />
      </Box>
      {editOpen === true ? (
        <CategoryEdit
          openmodal={editOpen}
          category_id={itemId}
          onCloseModal={() => setEditOpen(false)}
        />
      ) : (
        ''
      )}
      {dialogopen === true ? (
        <ConfirmModal
          dialogopen={dialogopen}
          category_info={itemInfo}
          onCloseModal={() => setDialogOpen(false)}
        />
      ) : (
        ''
      )}
    </Box>
  );
}

export default CategoriesList;
