/** @format */

const setInfRegisterData = (data) => {
  localStorage.setItem('registerData', JSON.stringify(data));
};

const setInfAccessToken = (accessToken) => {
  localStorage.setItem('influencer-accessToken', accessToken);
};

const setInfRefreshToken = (refreshToken) => {
  localStorage.setItem('influencer-refreshToken', refreshToken);
};

const setInfUserData = (data) => {
  localStorage.setItem('influencer-userData', JSON.stringify(data));
};

const clearInfLocalStorage = () => {
  localStorage.removeItem('influencer-accessToken');
  localStorage.removeItem('influencer-refreshToken');
  localStorage.removeItem('influencer-userData');
};

// Gets the logged in access token from local session
const getRefreshToken = () => {
  const user = localStorage.getItem('influencer-refreshToken');
  if (user) return JSON.parse(user);
  return null;
};

// Gets the logged in refresh token from local session
const getInfLoggedInToken = () => {
  const token = localStorage.getItem('influencer-accessToken');
  if (token) return token;
  return false;
};
// Gets the logged in user data from local session
const getInfUserData = () => {
  const user = localStorage.getItem('influencer-userData');
  if (user) return JSON.parse(user);
  return null;
};

// Gets the logged in access token from local session
const getInfRegisterData = () => {
  const registerData = localStorage.getItem('influencer-registerData');
  if (registerData) return JSON.parse(registerData);
  return null;
};

export {
  setInfAccessToken,
  setInfRefreshToken,
  setInfUserData,
  clearInfLocalStorage,
  getRefreshToken,
  getInfUserData,
  getInfLoggedInToken,
  setInfRegisterData,
  getInfRegisterData,
};
