/** @format */
const setAdminAccessToken = (accessToken) => {
  localStorage.setItem('admin-accessToken', accessToken);
};

const setAdminRefreshToken = (refreshToken) => {
  localStorage.setItem('admin-refreshToken', refreshToken);
};

const setAdminUserData = (data) => {
  localStorage.setItem('admin-userData', JSON.stringify(data));
};

const clearAdminLocalStorage = () => {
  localStorage.removeItem('admin-accessToken');
  localStorage.removeItem('admin-refreshToken');
  localStorage.removeItem('admin-userData');
};

// Gets the logged in access token from local session
const getAdminRefreshToken = () => {
  const user = localStorage.getItem('admin-refreshToken');
  if (user) return JSON.parse(user);
  return null;
};

// Gets the logged in refresh token from local session
const getAdminLoggedInToken = () => {
  const token = localStorage.getItem('admin-accessToken');
  if (token) return token;
  return false;
};
// Gets the logged in user data from local session
const getAdminUserData = () => {
  const user = localStorage.getItem('admin-userData');
  if (user) return JSON.parse(user);
  return null;
};

export {
  setAdminAccessToken,
  setAdminRefreshToken,
  setAdminUserData,
  clearAdminLocalStorage,
  getAdminRefreshToken,
  getAdminUserData,
  getAdminLoggedInToken,
};
