/** @format */

import { approveBrandTypes } from '../../types/admin/ApproveBrandTypes';
import { approveBrandService } from '../../../services/admin/ApproveBrandService';
import { toast } from 'react-toastify';

export const approveBrandAction = {
  getAll,
  add,
  edit,
  update,
  deleteApproveBrand,
};

function getAll() {
  return async (dispatch) => {
    dispatch({ type: approveBrandTypes.GETALL_APPROVE_BRAND_REQUEST });
    try {
      const res = await approveBrandService.getAll();
      dispatch({
        type: approveBrandTypes.GETALL_APPROVE_BRAND_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveBrandTypes.GETALL_APPROVE_BRAND_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}
function add(postData, setErrors, setSubmitting, navigate) {
  return async (dispatch) => {
    dispatch({ type: approveBrandTypes.ADD_APPROVE_BRAND_REQUEST });
    try {
      const res = await approveBrandService.add(postData);
      dispatch({
        type: approveBrandTypes.ADD_APPROVE_BRAND_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        navigate('/admin/brand');
        setTimeout(function () {
          dispatch(approveBrandAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveBrandTypes.ADD_APPROVE_BRAND_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function deleteApproveBrand(code, closeModal) {
  return async (dispatch) => {
    dispatch({ type: approveBrandTypes.DELETE_APPROVE_BRAND_REQUEST });
    try {
      const res = await approveBrandService.deleteApproveBrand(code);
      dispatch({
        type: approveBrandTypes.DELETE_APPROVE_BRAND_SUCCESS,
        payload: res.data.data,
      });
      if (res.data.message) {
        toast.success(res.data.message);
      }
      closeModal();
      setTimeout(function () {
        dispatch(approveBrandAction.getAll());
      }, 500);
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveBrandTypes.DELETE_APPROVE_BRAND_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          closeModal();
        }
      }
      //
    }
  };
}
function update(code, postData, setErrors, setSubmitting, navigate) {
  return async (dispatch) => {
    dispatch({ type: approveBrandTypes.UPDATE_APPROVE_BRAND_REQUEST });
    try {
      const res = await approveBrandService.update(code, postData);
      dispatch({
        type: approveBrandTypes.UPDATE_APPROVE_BRAND_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        setSubmitting(false);
        navigate('/admin/brand');
        setTimeout(function () {
          dispatch(approveBrandAction.getAll());
        }, 500);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveBrandTypes.UPDATE_APPROVE_BRAND_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            if (error.response.data.error) {
              setErrors(error.response.data.error);
            }
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function edit(id) {
  return async (dispatch) => {
    dispatch({ type: approveBrandTypes.EDIT_APPROVE_BRAND_REQUEST });
    try {
      const res = await approveBrandService.edit(id);
      dispatch({
        type: approveBrandTypes.EDIT_APPROVE_BRAND_SUCCESS,
        payload: res.data.data,
      });
      if (res && res.data) console.log(res.data, 'res.data');
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: approveBrandTypes.EDIT_APPROVE_BRAND_ERROR,
          error: error.response,
        });
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
      //
    }
  };
}
