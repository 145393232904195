/** @format */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './EmailOtpScreen.scss';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import OtpInput from '../../components/otpfield/OtpInput';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../store/actions/influencer/authAction';
import { getRegisterData } from '../../utils/AuthConfig';
import { Button } from '@mui/material';
import ChangeEmailModal from './ChangeEmailModal';
import { Loader } from '../../utils/Loader';

const EmailOtpScreen = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [mailAddress, setMailAddress] = useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const updateData = getRegisterData();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const { mailData, isLoading } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (updateData && updateData.holderId) {
      //verifyLink('email', updateData.holderId);
    }
    if (updateData && updateData.userId) {
      dispatch(authAction.getUserEmail(updateData.userId));
      //getUserEmail(updateData.userId);
    }
    if (location.state && location.state !== null) {
      if (location.state.data && location.state.data.email) {
        setMailAddress(location.state.data.email);
      }
    }
    dispatch(authAction.verifyLink('email', id, navigate));
  }, [dispatch]);

  console.log(mailData, 'mailData');

  useEffect(() => {
    if (mailData && mailData.data && mailData.data.email) {
      setMailAddress(mailData.data.email);
    }
  }, [mailData]);

  // Handling otp field text chanages
  const handleTextFieldChange = (e) => {
    console.log(e);
    setOtp(e);
  };

  //*  ==== OnTap Contune button ====== */

  const handleSubmit = (e) => {
    e.preventDefault();

    // Custom validation logic
    if (otp.length !== 6) {
      setOtpError('Please enter a valid 6-digit OTP.');
      return;
    }

    let formData = {
      holderId: id,
      otp: otp,
    };
    dispatch(authAction.otpVerifyEmail(formData, navigate));
    //authAction.OtpVerifyEmail(formData, navigate);
    // If validation passes, you can submit the OTP value
    // (e.g., send it to the server or perform any other action)
    // Here, we'll just log it for demonstration purposes.

    //console.log('Submitted OTP:', otp);

    // Reset error state
    setOtpError('');
  };

  const handleOpen = () => setModalOpen(true);

  const onTapContinueButton = () => {
    navigate('/social');
  };

  return (
    <div className='otp-screen'>
      {isLoading ? <Loader /> : ''}
      <div className='otp-screen-container'>
        <div className='otp-screen-container-left'>
          <div className='otp-screen-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className='otp-screen-container-left-content-fields'>
                <div className='otp-screen-header'>Email verification</div>
                <div className='otp-screen-caption'>
                  <span>
                    a confirmation code has beedn sent your email {mailAddress}
                    <Button
                      variant='text'
                      onClick={handleOpen}
                      className='otp-screen-change-number'>
                      Change email
                    </Button>
                  </span>
                </div>

                <OtpInput
                  onChange={setOtp}
                  value={otp}
                />

                <div className='countdown-text'>
                  {seconds > 0 || minutes > 0 ? (
                    <>
                      <span className='timer-info-text'>
                        You can resend the code in{' '}
                      </span>
                      <span className='timer-value'>
                        {' '}
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    </>
                  ) : (
                    <div className='resend-container'>
                      <span className='resend-info-text'>
                        Didn't recieve code?
                      </span>
                      <button className='resend-button'>Resend OTP</button>
                    </div>
                  )}
                </div>
                {otpError && (
                  <div className='otp-error-container'>
                    <span className='otp-error-message'>{otpError}</span>
                  </div>
                )}

                <div className='continue-button'>
                  <button type='submit'>Verify</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='otp-screen-container-right'>
          <AuthBanner />
        </div>
      </div>
      {modalOpen === true ? (
        <ChangeEmailModal
          open={modalOpen}
          user_id={updateData && updateData.userId}
          email={mailAddress}
          onCloseModal={() => setModalOpen(false)}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default EmailOtpScreen;
