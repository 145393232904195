/** @format */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Stack,
  Chip,
  Divider,
  Modal,
} from '@mui/material';
import './brand.scss';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import AddIcon from '@mui/icons-material/Add';
import { CampaignAction } from '../../store/actions/brand/campaignAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../utils/Loader';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CampaignList() {
  const [listData, setListData] = React.useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CampaignAction.getAllCampaign());
  }, [dispatch]);

  const { campaignData, isLoading } = useSelector(
    (state) => state.brandcampaign
  );

  useEffect(() => {
    if (campaignData && campaignData.data) {
      setListData(campaignData.data);
    }
  }, [campaignData]);

  console.log(listData, 'listData');

  return (
    <div className='mainWrap'>
      <div className='header'>
        <div className='container'>
          <Button
            variant='text'
            component={Link}
            to='/brand'
            startIcon={<ArrowBackIosNewSharpIcon />}>
            {' '}
            Back
          </Button>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='stretch'>
            <Typography
              variant='h2'
              component={'h2'}>
              All Campaign
            </Typography>
            <Button
              variant='contained'
              component={Link}
              to='/brand/campaign'
              startIcon={<AddIcon />}>
              {' '}
              Add Campaign
            </Button>
          </Stack>
        </div>
      </div>
      <Box
        className='mainContent'
        sx={{ pb: 8 }}>
        {isLoading ? <Loader /> : ''}
        <div className='container'>
          <Grid
            container
            rowSpacing={12}
            sx={{ mt: 0 }}
            columnSpacing={3}>
            {listData && listData.campaigns && listData.campaigns.length > 0 ? (
              listData.campaigns.map((list_item, index) => {
                return (
                  <Grid
                    key={index}
                    className='campaignGrid'
                    item
                    xs={4}
                    md={4}>
                    <Paper
                      component={Link}
                      to={'/brand/campaign/detail/' + list_item._id}
                      sx={{
                        p: 3,
                        boxShadow: '0px 8px 30px 0px #00000026',
                        display: 'inline-block',
                        width: '100%',
                        boxSizing: 'border-box',
                        textDecoration: 'none',
                      }}>
                      <Typography
                        variant='h3'
                        component={'h3'}>
                        {list_item.name}
                      </Typography>
                      <Typography
                        variant='body1'
                        component={'p'}
                        dangerouslySetInnerHTML={{
                          __html: list_item && list_item.description,
                        }}></Typography>
                      <Stack
                        sx={{ mb: 2 }}
                        direction='row'
                        spacing={2}>
                        {list_item.categories &&
                          list_item.categories.map((item, idx) => {
                            return (
                              <Chip
                                key={idx}
                                label={item.name}
                                sx={{
                                  background: item.color,
                                  color: '#fff',
                                }}
                                //color='primary'
                              />
                            );
                          })}
                      </Stack>
                      <Typography
                        variant='h4'
                        component={'h4'}>
                        Requirements
                      </Typography>
                      <Stack
                        sx={{ mb: 2 }}
                        direction='row'
                        spacing={2}>
                        {list_item.requirementType &&
                          list_item.requirementType.map((item, idx) => {
                            return (
                              <Chip
                                key={idx}
                                label={item.name}
                                color='primary'
                                sx={{
                                  color: '#fff',
                                }}
                                //color='primary'
                              />
                            );
                          })}
                      </Stack>
                      <Divider />
                      <Typography
                        sx={{ pt: 3 }}
                        variant='h5'
                        component={'h5'}>
                        Campaign budget <span> {'₹ ' + list_item.budget}</span>
                      </Typography>
                    </Paper>
                  </Grid>
                );
              })
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography
                  variant='h2'
                  component={'h2'}>
                  No Records
                </Typography>
              </Box>
            )}
          </Grid>
        </div>
      </Box>
    </div>
  );
}

export default CampaignList;
