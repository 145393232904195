/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import BigLogo from '../../assets/biglogo.png';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Card, CardContent, CardHeader } from '@mui/material';
import './adminAuth.scss';
import { adminAuthAction } from '../../store/actions/admin/adminAuthAction';
import { LoginValidate } from '../../utils/AdminValidation';

const LoginForm = (props) => {
  let navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.adminauthentication);
  const dispatch = useDispatch();

  console.log(loggedIn, 'loggedIn');

  useEffect(() => {
    if (loggedIn) {
      navigate('/admin/dashboard');
    }
  }, [loggedIn, navigate]);

  return (
    <div>
      {/* {isLoading === true ? <Loader /> : ""} */}
      <Box className='AdminAuthWrap'>
        <div className='container'>
          <Grid
            container
            rowSpacing={3}
            sx={{
              mt: 0,
              alignItems: 'center',
              '.MuiChip-root': {
                '.MuiChip-deleteIcon': {
                  color: '#fff',
                  fontSize: '18px',
                },
              },
            }}
            columnSpacing={3}>
            <Grid
              className=''
              sx={{ textAlign: 'left' }}
              item
              xs={6}
              md={6}>
              <img
                src={BigLogo}
                alt='logo'
              />
            </Grid>
            <Grid
              className=''
              sx={{}}
              item
              xs={6}
              md={6}>
              <Card className='parentCardWrap'>
                <CardHeader title='Login' />
                <CardContent>
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    enableReinitialize
                    validationSchema={LoginValidate}
                    onSubmit={(values, { setErrors, setSubmitting }, e) => {
                      dispatch(
                        adminAuthAction.login(values, setErrors, setSubmitting)
                      );
                    }}>
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form_wrapper'>
                          <Box className='login__wrap'>
                            <Paper
                              elevation={0}
                              sx={{ background: 'none' }}>
                              <TextField
                                error={errors.email && touched.email}
                                label='Email Address'
                                placeholder='Enter Email Address'
                                size='large'
                                fullWidth
                                name='email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.email && touched.email && errors.email
                                }
                                margin='normal'
                                variant='outlined'
                                required={false}
                                InputLabelProps={{ required: true }}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position='start'>
                                //       <i className='icon-mail'></i>
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                              <TextField
                                id='filled-password-input'
                                fullWidth
                                variant='outlined'
                                label='Password'
                                name='password'
                                type={'password'}
                                size='large'
                                margin='normal'
                                placeholder='Enter Password'
                                error={errors.password && touched.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  errors.password &&
                                  touched.password &&
                                  errors.password
                                }
                                required={false}
                                InputLabelProps={{ required: true }}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position='start'>
                                //       <i className='icon-password'></i>
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                              <Button
                                type='submit'
                                className='submit_btn'
                                variant='contained'
                                sx={{ mt: 3, mb: 1 }}
                                fullWidth
                                size='large'
                                disabled={isSubmitting}>
                                {isSubmitting ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  'Login'
                                )}
                              </Button>
                            </Paper>
                          </Box>
                        </div>
                      </form>
                    )}
                  </Formik>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};
export default LoginForm;
