/** @format */

import { getAdminLoggedInToken } from '../../../utils/AdminAuthConfig';
import { adminAuthTypes } from '../../types/admin/AdminAuthTypes';
let accessToken = getAdminLoggedInToken();
const initialState = accessToken
  ? { loggedIn: true, isLoading: true }
  : {
      loggedIn: false,
    };

export const adminauthentication = (state = initialState, action) => {
  switch (action.type) {
    case adminAuthTypes.ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case adminAuthTypes.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isLoading: false,
        data: action.data,
      };
    case adminAuthTypes.ADMIN_LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        isLoading: false,
        //error: action.error,
      };

    default:
      return state;
  }
};
