/** @format */

import api from './HttpService';
import { BRAND_CAMPAIGN } from '../configs/ApiActionUrl';

export const CampaignServices = {
  fetchCategoriesOptions,
  fetchInfluencerOptions,
  add,
  getAllCampaign,
  getItemCampaign,
  getCampaignInfluencer,
  addInfluencer,
  fetchInRequirementsOptions,
  getCampaignInfluencerProfile,
  updaeCampaignInfluencerProfile,
};

async function fetchCategoriesOptions() {
  const res = await api.get(BRAND_CAMPAIGN.CATEGORIES);
  return res;
}
async function fetchInfluencerOptions() {
  const res = await api.get(BRAND_CAMPAIGN.INFLUENCER);
  return res;
}

async function fetchInRequirementsOptions() {
  const res = await api.get(BRAND_CAMPAIGN.REQUIREMENTS);
  return res;
}

async function getAllCampaign() {
  const res = await api.get(BRAND_CAMPAIGN.GET_ALL);
  return res;
}

async function add(postData) {
  const res = await api.post(BRAND_CAMPAIGN.ADD, postData);
  return res;
}

async function getItemCampaign(code) {
  const res = await api.get(BRAND_CAMPAIGN.GET_ITEM + '/' + code);
  return res;
}

async function getCampaignInfluencer(code) {
  const res = await api.get(BRAND_CAMPAIGN.GET_ITEM + '/influencer/' + code);
  return res;
}

async function getCampaignInfluencerProfile(campaignID, influencerID) {
  const res = await api.get(
    BRAND_CAMPAIGN.GET_INF_PROFILE + '/' + campaignID + '/' + influencerID
  );
  return res;
}

async function updaeCampaignInfluencerProfile(campaignID, influencerID, data) {
  const res = await api.put(
    BRAND_CAMPAIGN.UPDATE_INF_PROFILE + '/' + campaignID + '/' + influencerID,
    data
  );
  return res;
}

async function addInfluencer(code, postData) {
  const res = await api.put(
    BRAND_CAMPAIGN.ADD_INFLUENCER + '/influencer/' + code,
    postData
  );
  return res;
}
