/** @format */
import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import VideoImage from '../../assets/vidoe_img.png';
import infLogo1 from '../../assets/inf_logo1.svg';
import infLogo2 from '../../assets/inf_logo2.svg';
import infLogo3 from '../../assets/inf_logo3.svg';
import infLogo4 from '../../assets/inf_logo4.svg';
import infLogo5 from '../../assets/inf_logo5.svg';

const InfluencerVideoSec = () => {
  return (
    <Box className='videoSection'>
      <div className='container'>
        <Typography
          variant='h2'
          component={'h2'}>
          How does Social Bottle work for influencers?
        </Typography>
        <Typography
          variant='body1'
          component={'p'}>
          You get to pick which brands to collaborate with, even if you only
          have 1500 followers. Internationally recognised brands are waiting for
          you to support them. You can profit from every sale you make thanks to
          your devoted customers.
        </Typography>
        <Box
          className='vidoeWrap'
          sx={{ mt: 8 }}>
          <img
            src={VideoImage}
            alt='Video Image'
          />
        </Box>
        <Box
          className='logoWrap'
          sx={{ py: 8 }}>
          <Typography
            variant='h3'
            component={'h3'}>
            250K+ influencers have joined us already.
          </Typography>
          <Typography
            variant='body1'
            component={'p'}>
            We work with:
          </Typography>
          <Stack
            sx={{ my: 5 }}
            alignItems={'center'}
            justifyContent={'center'}
            direction={'row'}
            spacing={10}>
            <img
              src={infLogo1}
              alt='Logo Image 1'
            />
            <img
              src={infLogo5}
              alt='Logo Image 5'
            />

            <img
              src={infLogo3}
              alt='Logo Image 3'
            />
            <img
              src={infLogo2}
              alt='Logo Image 2'
            />
            <img
              src={infLogo4}
              alt='Logo Image 4'
            />
          </Stack>
        </Box>
      </div>
    </Box>
  );
};

export default InfluencerVideoSec;
