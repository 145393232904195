/** @format */

import api from './HttpService';
import { INFLUENCER_CAMPAIGN } from '../../configs/ApiActionUrl';

export const InfCampServices = {
  fetchCategoriesOptions,
  fetchInfluencerOptions,
  add,
  getAllCampaign,
  getItemCampaign,
  getCampaignInfluencer,
  addInfluencer,
  uploadMediaURL,
};

async function fetchCategoriesOptions() {
  const res = await api.get(INFLUENCER_CAMPAIGN.CATEGORIES);
  return res;
}
async function fetchInfluencerOptions() {
  const res = await api.get(INFLUENCER_CAMPAIGN.INFLUENCER);
  return res;
}

async function getAllCampaign(pageOption, searchTerm) {
  const res = await api.get(INFLUENCER_CAMPAIGN.GET_ALL, {
    params: {
      page: pageOption?.page,
      limit: pageOption?.limit,
      search: searchTerm, // Add searchTerm to the API call if needed
    },
  });
  return res;
}

async function add(postData) {
  const res = await api.post(INFLUENCER_CAMPAIGN.ADD, postData);
  return res;
}

async function getItemCampaign(code) {
  const res = await api.get(INFLUENCER_CAMPAIGN.GET_ITEM + '/' + code);
  return res;
}

async function getCampaignInfluencer(code) {
  const res = await api.get(
    INFLUENCER_CAMPAIGN.GET_ITEM + '/influencer/' + code
  );
  return res;
}

async function addInfluencer(code, postData) {
  const res = await api.put(
    INFLUENCER_CAMPAIGN.ADD_INFLUENCER + '/influencer/' + code,
    postData
  );
  return res;
}

async function uploadMediaURL(code, postData) {
  const res = await api.post(
    INFLUENCER_CAMPAIGN.UPLOAD_MEDIA + '/' + code,
    postData
  );
  return res;
}
