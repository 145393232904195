/** @format */

import { CampaignServices } from '../../../services/campaignServices';
import { CampaignTypes } from '../../types/brand/campaignTypes';
import { toast } from 'react-toastify';

export const CampaignAction = {
  fetchCategoriesOptions,
  fetchInfluencerOptions,
  add,
  getAllCampaign,
  getItemCampaign,
  getCampaignInfluencer,
  addInfluencer,
  fetchInRequirementsOptions,
  getCampaignInfluencerProfile,
  updaeCampaignInfluencerProfile,
};

function fetchCategoriesOptions() {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.FETCH_CATEGORIES_REQUEST });
    try {
      const res = await CampaignServices.fetchCategoriesOptions();
      dispatch({
        type: CampaignTypes.FETCH_CATEGORIES_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.FETCH_CATEGORIES_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function fetchInfluencerOptions() {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.FETCH_INFLUENCER_REQUEST });
    try {
      const res = await CampaignServices.fetchInfluencerOptions();
      console.log(res, 'res');
      dispatch({
        type: CampaignTypes.FETCH_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.FETCH_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function fetchInRequirementsOptions() {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.FETCH_REQUIREMENTS_REQUEST });
    try {
      const res = await CampaignServices.fetchInRequirementsOptions();
      dispatch({
        type: CampaignTypes.FETCH_REQUIREMENTS_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.FETCH_REQUIREMENTS_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function add(postData, setErrors, setSubmitting, navigate) {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.CAMPAIGN_ADD_REQUEST });
    try {
      const res = await CampaignServices.add(postData);
      dispatch({ type: CampaignTypes.CAMPAIGN_ADD_SUCCESS, payload: res.data });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        navigate('/brand/dashboard');
        setSubmitting(false);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.CAMPAIGN_ADD_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function getAllCampaign() {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.GETALL_CAMPAIGN_REQUEST });
    try {
      const res = await CampaignServices.getAllCampaign();
      dispatch({
        type: CampaignTypes.GETALL_CAMPAIGN_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.GETALL_CAMPAIGN_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function getItemCampaign(code) {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.GET_ITEM_CAMPAIGN_REQUEST });
    try {
      const res = await CampaignServices.getItemCampaign(code);
      dispatch({
        type: CampaignTypes.GET_ITEM_CAMPAIGN_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.GET_ITEM_CAMPAIGN_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function getCampaignInfluencer(code) {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.GET_CAMPAIGN_INFLUENCER_REQUEST });
    try {
      const res = await CampaignServices.getCampaignInfluencer(code);
      dispatch({
        type: CampaignTypes.GET_CAMPAIGN_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.GET_CAMPAIGN_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function addInfluencer(code, postData) {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.CAMPAIGN_ADD_INFLUENCER_REQUEST });
    try {
      const res = await CampaignServices.addInfluencer(code, postData);
      dispatch({
        type: CampaignTypes.CAMPAIGN_ADD_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.CAMPAIGN_ADD_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function getCampaignInfluencerProfile(campaignID, influencerID) {
  return async (dispatch) => {
    dispatch({ type: CampaignTypes.GET_CAMPAIGN_INFLUENCER_PROFILE_REQUEST });
    try {
      const res = await CampaignServices.getCampaignInfluencerProfile(
        campaignID,
        influencerID
      );
      dispatch({
        type: CampaignTypes.GET_CAMPAIGN_INFLUENCER_PROFILE_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.GET_CAMPAIGN_INFLUENCER_PROFILE_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function updaeCampaignInfluencerProfile(
  campaignID,
  influencerID,
  postData,
  setErrors,
  setSubmitting,
  closeModal
) {
  return async (dispatch) => {
    dispatch({
      type: CampaignTypes.UPDATE_CAMPAIGN_INFLUENCER_PROFILE_REQUEST,
    });
    try {
      const res = await CampaignServices.updaeCampaignInfluencerProfile(
        campaignID,
        influencerID,
        postData
      );
      dispatch({
        type: CampaignTypes.UPDATE_CAMPAIGN_INFLUENCER_PROFILE_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        closeModal();
        setSubmitting(false);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: CampaignTypes.UPDATE_CAMPAIGN_INFLUENCER_PROFILE_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}
