/** @format */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Stack,
  Chip,
  Divider,
  Modal,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import './influencer.scss';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CampaignAction } from '../../store/actions/brand/campaignAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfCampAction } from '../../store/actions/influencer/campaignAction';
import { getInfUserData } from '../../utils/InfluencerAuthConfig';
import { Loader } from '../../utils/Loader';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function InfluencerCampaignList() {
  const [listData, setListData] = React.useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(InfCampAction.getAllCampaign());
  }, [dispatch]);

  const { campaignData, isLoading } = useSelector(
    (state) => state.influencercampaign
  );

  const userInfo = getInfUserData();
  console.log(userInfo, 'userInfo');

  useEffect(() => {
    if (campaignData && campaignData.data) {
      setListData(campaignData.data);
    }
  }, [campaignData]);

  console.log(listData, 'listData');

  return (
    <div className='mainWrap'>
      <div className='header'>
        <div className='container'>
          {isLoading ? <Loader /> : ''}
          {/* <Button
            variant='text'
            //onClick={navigate(-1)}
            startIcon={<ArrowBackIosNewSharpIcon />}>
            Back
          </Button> */}
          <Box className='userInfoWrap'>
            <Typography
              variant='h1'
              component={'h1'}>
              Welcome back, <span>{userInfo && userInfo.name}</span>
            </Typography>
            <Typography
              variant='body1'
              component={'p'}>
              on Social Bottle, where you may interact with leading companies
              and engage in productive partnerships.
            </Typography>
          </Box>
        </div>
      </div>
      <Box className='mainContent'>
        <div className='container'>
          <Card
            sx={{ mt: 4 }}
            className='influencerCard'>
            <CardHeader
              action={
                <Button
                  variant='text'
                  component={Link}
                  to='/influencer/all-campaigns'
                  endIcon={<KeyboardArrowRightIcon />}
                  aria-label='settings'>
                  All
                </Button>
              }
              title='Recent Campaigns'
              subheader={'Available ' + (listData && listData.totalCount)}
            />
            <CardContent>
              <Grid
                container
                rowSpacing={2}
                sx={{ mt: 0 }}
                columnSpacing={3}>
                {listData &&
                  listData.campaigns &&
                  listData.campaigns.map((list_item, index) => {
                    return (
                      <Grid
                        key={index}
                        className='campaignGrid'
                        item
                        xs={4}
                        md={4}>
                        <Paper
                          component={Link}
                          to={'/influencer/campaign/detail/' + list_item._id}
                          sx={{
                            p: 3,
                            boxShadow: '0px 8px 30px 0px #00000026',
                            display: 'inline-block',
                            width: '100%',
                            boxSizing: 'border-box',
                            textDecoration: 'none',
                          }}>
                          <Typography
                            variant='h3'
                            component={'h3'}>
                            {list_item.name}
                          </Typography>
                          <Typography
                            variant='body1'
                            component={'p'}
                            dangerouslySetInnerHTML={{
                              __html: list_item && list_item.description,
                            }}></Typography>
                          <Stack
                            sx={{ mb: 2 }}
                            direction='row'
                            spacing={2}>
                            {list_item.categories &&
                              list_item.categories.map((item, idx) => {
                                return (
                                  <Chip
                                    key={idx}
                                    label={item.name}
                                    color='primary'
                                    sx={{
                                      background: item.color,
                                      color: '#fff',
                                    }}
                                  />
                                );
                              })}
                          </Stack>
                          <Typography
                            variant='h4'
                            component={'h4'}>
                            Requirements
                          </Typography>
                          <Stack
                            sx={{ mb: 2 }}
                            direction='row'
                            spacing={2}>
                            {list_item.requirement &&
                              list_item.requirement.map((item, idx) => {
                                return (
                                  <Chip
                                    key={idx}
                                    label={item}
                                    color='primary'
                                    sx={{
                                      color: '#fff',
                                    }}
                                    //color='primary'
                                  />
                                );
                              })}
                          </Stack>
                          {list_item.budget ? (
                            <>
                              <Divider />
                              <Typography
                                sx={{ pt: 3 }}
                                variant='h5'
                                component={'h5'}>
                                Campaign budget{' '}
                                <span>{'₹ ' + list_item.budget}</span>
                              </Typography>
                            </>
                          ) : (
                            ''
                          )}
                        </Paper>
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
}

export default InfluencerCampaignList;
