/** @format */

import { approveBrandTypes } from '../../types/admin/ApproveBrandTypes';
const initialState = {
  loading: false,
  approveBrand_data: [],
  approveBrand_status: false,
  approveBrandData: [],
  approveBrandData_status: false,
};
export const approveBrands = (state = initialState, action) => {
  switch (action.type) {
    case approveBrandTypes.GETALL_APPROVE_BRAND_REQUEST:
      return {
        loading: true,
      };
    case approveBrandTypes.GETALL_APPROVE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        approveBrand_status: true,
        approveBrand_data: action.payload,
      };

    case approveBrandTypes.GETALL_APPROVE_BRAND_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case approveBrandTypes.EDIT_APPROVE_BRAND_REQUEST:
      return {
        loading: true,
      };
    case approveBrandTypes.EDIT_APPROVE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        approveBrandData_status: true,
        approveBrandData: action.payload,
      };
    case approveBrandTypes.EDIT_APPROVE_BRAND_ERROR:
      return {
        loading: false,
        error: action.error,
      };

    case approveBrandTypes.UPDATE_APPROVE_BRAND_REQUEST:
      return {
        loading: true,
        data: action.data,
      };
    case approveBrandTypes.UPDATE_APPROVE_BRAND_SUCCESS:
      return {
        loading: false,
        item: action.data,
      };
    case approveBrandTypes.UPDATE_APPROVE_BRAND_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
