/** @format */

import { authService } from '../services/AuthService';
import {
  setAccessToken,
  setRefreshToken,
  setUserData,
  setRegisterData,
  clearLocalStorage,
} from '../utils/AuthConfig';
import { toast } from 'react-toastify';

export const authAction = {
  login,
  OtpSendPhone,
  OtpVerifyPhone,
  brandRegister,
  OtpVerifyEmail,
  brandUpdateEmail,
  logout,
  brandContactUs,
  // forgetPassword,
  // getResetPassword,
  // resetPassword,
};

async function login(postData, navigate) {
  try {
    const res = await authService.login(postData);
    if (res.data) {
      console.log(res.data, 'res.data');
      if (res.data.holderId) {
        navigate('/brand/verify-otp/' + res.data.holderId, {
          state: { data: postData, login: 'true' },
        });
      }
      if (res.data.message) {
        toast.success(res.data.message);
      }
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 422) {
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
  }
}

async function OtpSendPhone(postData, navigate) {
  try {
    const res = await authService.OtpSendPhone(postData);
    if (res.data) {
      console.log(res.data, 'res.data');

      setRegisterData(res.data);

      if (!res.data.isPhoneVerify) {
        navigate('/brand/verify-otp/' + res.data.holderId, {
          state: { data: postData },
        });
      } else if (!res.data.isRegister) {
        navigate('/brand/profile-input/' + res.data.holderId);
      } else if (!res.data.isEmailVerify) {
        navigate('/brand/verify-email-otp/' + res.data.holderId);
      }
      if (res.data.message) {
        toast.success(res.data.message);
      }
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 422) {
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
  }
}

async function OtpVerifyPhone(postData, navigate, loginVerify) {
  try {
    const res = await authService.OtpVerifyPhone(postData, loginVerify);
    if (res.message) {
      toast.success(res.message);
    }
    console.log(res, 'res');
    if (res) {
      if (loginVerify) {
        navigate('/brand/dashboard');
        if (res.data.accessToken) {
          setAccessToken(res.data.accessToken);
        }
        if (res.data.refreshToken) {
          setRefreshToken(res.data.refreshToken);
        }
        if (res.data.userData) {
          setUserData(res.data.userData);
        }
      } else {
        console.log('ddd');
        if (!res.isPhoneVerify) {
          navigate('/brand/verify-otp/' + res.holderId, {
            state: { data: postData },
          });
        } else if (!res.isRegister) {
          navigate('/brand/profile-input/' + res.holderId);
        } else if (!res.data.isEmailVerify) {
          navigate('/brand/verify-email-otp/' + res.holderId);
        }
        setRegisterData(res.data);
      }
    }
  } catch (error) {
    if (error && error.response) {
      //console.log(error.response.data, 'error.response');
      if (error.response.status === 400) {
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
          // navigate('/brand/signup');
        }
      }
      // if (error.response.status === 422) {
      //   if (error.response.data && error.response.data.errors) {
      //     toast.error(error.response.data.message);
      //     //setErrors(error.response.data.errors);
      //   }
      // }
    }
  }
}

async function OtpVerifyEmail(postData, navigate) {
  try {
    const res = await authService.OtpVerifyEmail(postData);
    if (res.data) {
      setRegisterData(res.data);
      navigate('/thank-you');
      // if (res.data.holderId) {
      //   navigate('/brand/role/' + res.data.holderId);
      // }
      if (res.data.message) {
        toast.success(res.data.message);
      }
    }
  } catch (error) {
    if (error && error.response) {
      //console.log(error.response.data, 'error.response');
      if (error.response.status === 400) {
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
          //navigate('/brand/signup');
        }
      }
    }
  }
}

async function brandRegister(postData, navigate) {
  try {
    const res = await authService.brandRegister(postData);
    if (res.data) {
      console.log(res.data, 'res.data');

      setRegisterData(res.data);
      if (!res.data.isPhoneVerify) {
        navigate('/brand/verify-otp/' + res.data.holderId, {
          state: { data: postData },
        });
      } else if (!res.data.isRegister) {
        navigate('/brand/role/' + res.data.holderId);
      } else if (!res.data.isEmailVerify) {
        navigate('/brand/verify-email-otp/' + res.data.holderId);
      }
      if (res.data.message) {
        toast.success(res.data.message);
      }
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 422) {
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      } else if (error.response.status === 409) {
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }
      }
    }
  }
}

async function brandUpdateEmail(code, postData, closeModal) {
  try {
    const res = await authService.brandUpdateEmail(code, postData);
    if (res.data) {
      console.log(res.data, 'res.data');
      setRegisterData(res.data);
      closeModal();
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 422) {
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
  }
}

async function brandContactUs(postData, setErrors, setSubmitting, resetForm) {
  try {
    const res = await authService.brandContactUs(postData);
    if (res.data) {
      if (res.data.message) {
        toast.success(res.data.message);
      }
      setSubmitting(false);
      //console.log(resetForm, 'resetForm');
      window.location.href = '/contact';
    }
  } catch (error) {
    if (error && error.response) {
      if (error.response.status === 422) {
        if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
          setErrors(error.response.data.error);
        }
      }
      setSubmitting(false);
    }
  }
}

function logout() {
  //localStorage.clear();
  clearLocalStorage();
  window.location.href = '/brand/login';
  toast.success('Logged out successfully');
}
