/** @format */

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    {/* <GoogleOAuthProvider clientId='950956680098-93bbmmifpr1oj5aq2cd3a09mba199snu.apps.googleusercontent.com'> */}
    <App />
  </Provider>

  // const root = ReactDOM.createRoot(document.getElementById('root'));
  // root.render(
  //   <GoogleOAuthProvider clientId='500431361620-3h5f5jlll4rn6lgfc1o68e6p1e1uriot.apps.googleusercontent.com'>
  //     {/* <React.StrictMode> */}
  //     <App />
  //     {/* </React.StrictMode> */}
  //   </GoogleOAuthProvider>
);
