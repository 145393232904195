/** @format */

import api from './HttpService';
import { INFLUENCER_AUTH } from '../../configs/ApiActionUrl';
// import { getLoggedInToken } from '../utils/INFLUENCER_AUTHConfig';

// const token = getLoggedInToken();

//console.log(token, "token");

export const authService = {
  login,
  otpSendPhone,
  otpVerifyPhone,
  influencerRegister,
  otpVerifyEmail,
  influencerUpdateEmail,
  fetchCitiesOptions,
  getUserEmail,
  verifyLink,
  fetchTopicsOptions,
  fetchLanguageOptions,
  socialConnect,
  topicLanguagePost,
  fetchRequirementOptions,
  // brandCategory,
  // getVerifyLink,
  // logout,
  // forgetPassword,
  // getResetPassword,
  // resetPassword,
};

async function login(postData) {
  const res = await api.post(INFLUENCER_AUTH.LOGIN, postData);
  return res;
}

async function otpSendPhone(postData) {
  const res = await api.post(INFLUENCER_AUTH.OTP_SEND_PHONE, postData);
  return res;
}

async function otpVerifyPhone(postData, loginVerify) {
  let res; // Declare res outside of the condition

  try {
    if (loginVerify) {
      res = await api.post(INFLUENCER_AUTH.OTP_VERIFY_PHONE_LOGIN, postData);
    } else {
      res = await api.post(INFLUENCER_AUTH.OTP_VERIFY_PHONE, postData);
    }

    // Return the response data
    return res.data;
  } catch (error) {
    // Handle errors here, you can log or rethrow the error as needed
    console.error('Error:', error);
    throw error; // Rethrow the error to handle it in the calling code
  }
}

// async function OtpVerifyPhone(postData) {
//   const res = await api.post(INFLUENCER_AUTH.OTP_VERIFY_PHONE, postData);
//   return res;
// }

async function otpVerifyEmail(postData) {
  const res = await api.post(INFLUENCER_AUTH.OTP_VERIFY_EMAIL, postData);
  return res;
}

async function influencerRegister(postData) {
  // Convert the city field
  const city = postData.city && postData.city.value;

  // Convert the requirementType field
  const requirementType =
    postData.requirementType && postData.requirementType.value;

  // Create the updated object with converted fields
  const updatedData = {
    ...postData,
    city,
    requirementType,
  };

  console.log(postData, 'postData');
  const res = await api.post(INFLUENCER_AUTH.REGISTER, updatedData);
  return res;
}

async function influencerUpdateEmail(code, postData) {
  const res = await api.put(
    INFLUENCER_AUTH.UPDATE_USER_EMAIL + '/' + code,
    postData
  );
  return res;
}

async function fetchCitiesOptions() {
  const res = await api.get(INFLUENCER_AUTH.CITIES);
  return res;
}

async function getUserEmail(code) {
  const res = await api.get(INFLUENCER_AUTH.GET_USER_EMAIL + '/' + code);
  return res;
}

async function verifyLink(module, code) {
  const res = await api.get(
    INFLUENCER_AUTH.VERIFY_LINK + '/' + module + '/' + code
  );
  return res;
}

async function fetchTopicsOptions() {
  const res = await api.get(INFLUENCER_AUTH.TOPICS);
  return res;
}

async function fetchLanguageOptions() {
  const res = await api.get(INFLUENCER_AUTH.LANGUAGE);
  return res;
}

async function fetchRequirementOptions() {
  const res = await api.get(INFLUENCER_AUTH.REQUIREMENT);
  return res;
}

async function socialConnect(code, postData) {
  const res = await api.post(
    INFLUENCER_AUTH.SOCIAL_CONNECT + '/' + code,
    postData
  );
  return res;
}

async function topicLanguagePost(code, postData) {
  const res = await api.post(
    INFLUENCER_AUTH.POST_TOPIC_LANG + '/' + code,
    postData
  );
  return res;
}

// async function brandCategory() {
//   const res = await api.get(AUTH.CATEGORY);
//   return res;
// }

// async function getVerifyLink(module, code) {
//   const res = await api.get(AUTH.VERIFY_LINK + '/module/' + code);
//   return res;
// }

// async function forgetPassword(postData) {
//   const res = await api.post(AUTH.FORGOT_PASSWORD, postData);
//   return res;
// }

// async function getResetPassword(code, key) {
//   const res = await api.get(
//     AUTH.GET_RESET_PASSWORD + "?token=" + code + "&email=" + key
//   );
//   return res;
// }

// async function resetPassword(postData) {
//   const res = await api.post(AUTH.RESET_PASSWORD, postData);
//   return res;
// }

// async function logout(postData) {
//   const res = await api.post(AUTH.LOGOUT, postData);
//   return res;
// }
