/** @format */

import { categoryTypes } from '../../types/admin/CategoryTypes';
const initialState = {
  loading: false,
  category_data: [],
  category_status: false,
  categoryData: [],
  categoryData_status: false,
  activeCategoryData: [],
  activeCategory_status: false,
};
export const categories = (state = initialState, action) => {
  switch (action.type) {
    case categoryTypes.GETALL_CATEGORIES_REQUEST:
      return {
        loading: true,
      };
    case categoryTypes.GETALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        category_status: true,
        category_data: action.payload,
      };

    case categoryTypes.GETALL_CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case categoryTypes.GETALL_ACTIVE_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case categoryTypes.GETALL_ACTIVE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        activeCategory_status: true,
        activeCategoryData: action.data,
      };

    case categoryTypes.GETALL_ACTIVE_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case categoryTypes.ADD_CATEGORY_REQUEST:
      return {
        loading: true,
        data: action.data,
      };
    case categoryTypes.ADD_CATEGORY_SUCCESS:
      return {
        loading: false,
        item: action.data,
      };
    case categoryTypes.ADD_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    case categoryTypes.DELETE_CATEGORY_REQUEST:
      return state;
    case categoryTypes.DELETE_CATEGORY_SUCCESS:
      return {
        loading: false,
        item: action.data,
        //items:state.items.filter(item => item.id !== action.id)
      };
    case categoryTypes.DELETE_CATEGORY_ERROR:
      return {};
    case categoryTypes.EDIT_CATEGORY_REQUEST:
      return {
        loading: true,
      };
    case categoryTypes.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryData_status: true,
        categoryData: action.payload,
      };
    case categoryTypes.EDIT_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.error,
      };

    case categoryTypes.UPDATE_CATEGORY_REQUEST:
      return {
        loading: true,
        data: action.data,
      };
    case categoryTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        loading: false,
        item: action.data,
      };
    case categoryTypes.UPDATE_CATEGORY_ERROR:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
