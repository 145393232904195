/** @format */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { Stack, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
// import { MiniLoader } from '../../../utils/Loader';
import { CampaignAction } from '../../store/actions/brand/campaignAction';
/*Modal Style */
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  width: '100%',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 1,
  p: 5,
};

export default function InfApproveReject(props) {
  const [switchData, setSwitchData] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      CampaignAction.getCampaignInfluencerProfile(
        props.campaignID,
        props.itemID
      )
    );
  }, [dispatch, props.campaignID, props.itemID]);

  const { campaignInfProfile } = useSelector((state) => state.brandcampaign);

  return (
    <div>
      <Modal
        className='cmn__form__modal'
        keepMounted
        open={props.open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <Typography
            id='modal-title'
            variant='h4'
            component='h4'>
            Update Influencer
          </Typography>

          <div className='modalform'>
            <Box
              className='innerContainer'
              sx={{}}>
              <Box
                sx={{
                  background: '#fff',
                  padding: '0',
                }}>
                <Paper elevation={0}>
                  <Formik
                    initialValues={{
                      status: campaignInfProfile?.data?.status || null, // Initialize status with data from API
                    }}
                    enableReinitialize
                    //validationSchema={StatusValidation}
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      console.log(values);
                      dispatch(
                        CampaignAction.updaeCampaignInfluencerProfile(
                          props.campaignID,
                          props.itemID,
                          values,
                          setErrors,
                          setSubmitting,
                          props.onCloseModal
                        )
                      );
                    }}>
                    {({
                      isSubmitting,
                      values,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <Card
                          variant='outlined'
                          className='formWrapper'>
                          {/* <CardHeader
                            title={`${campaignInfProfile?.data?.influencer?.firstName} ${campaignInfProfile?.data?.influencer?.lastName}`}
                          /> */}
                          <CardContent>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>Name</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        {
                                          campaignInfProfile?.data?.influencer
                                            ?.firstName
                                        }{' '}
                                        {
                                          campaignInfProfile?.data?.influencer
                                            ?.lastName
                                        }
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {/* <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Last Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        {
                                          campaignInfProfile?.data?.influencer
                                            ?.lastName
                                        }
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                                  {/* <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Email
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        {
                                          campaignInfProfile?.data?.influencer
                                            ?.email
                                        }
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                                  {/* <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Status
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        {campaignInfProfile?.data?.status}
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                                  {/* <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>City</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        {
                                          campaignInfProfile?.data?.influencer
                                            ?.cityId[0]?.name
                                        }
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                                  {/* <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Is Connect Instagram
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {campaignInfProfile?.data?.isConnectInstagram
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Is Connect YouTube
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {campaignInfProfile?.data?.isConnectYouTube
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>
                            Is Connect Twitter
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {campaignInfProfile?.data?.isConnectTwitter
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow> */}
                                  {/* <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Budget
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        {campaignInfProfile?.data?.budget}
                                      </Typography>
                                    </TableCell>
                                  </TableRow> */}
                                  <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Requirement Type
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {campaignInfProfile?.data?.influencer?.requirementType.map(
                                        (require) => (
                                          <Chip
                                            sx={{
                                              mr: 1,
                                              color: 'primary.text',
                                            }}
                                            color='primary'
                                            key={require._id}
                                            label={require.name}
                                          />
                                        )
                                      )}
                                      {/* <Typography gutterBottom>
                                        {
                                          campaignInfProfile?.data?.influencer
                                            ?.requirementType
                                        }
                                      </Typography> */}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Topics
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {campaignInfProfile?.data?.influencer?.topics.map(
                                        (topic) => (
                                          <Chip
                                            sx={{
                                              mr: 1,
                                              color: 'primary.text',
                                            }}
                                            color='primary'
                                            key={topic._id}
                                            label={topic.name}
                                          />
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>
                                        Publication Languages
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      {campaignInfProfile?.data?.influencer?.pubLang.map(
                                        (lang) => (
                                          <Chip
                                            sx={{
                                              mr: 1,
                                              color: 'primary.text',
                                            }}
                                            color='primary'
                                            key={lang._id}
                                            label={lang.name}
                                          />
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <Typography gutterBottom>URLS</Typography>
                                    </TableCell>
                                    <TableCell>
                                      {campaignInfProfile?.data?.urls.map(
                                        (item, index) => (
                                          <Chip
                                            sx={{
                                              mr: 1,
                                              mb: 1,
                                              color: 'primary.text',
                                            }}
                                            color='primary'
                                            key={index}
                                            label={item}
                                          />
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                          {campaignInfProfile?.data?.influencer
                            ?.isConnectYouTube ? (
                            <CardContent>
                              <Typography
                                sx={{ my: 1.5 }}
                                variant='h6'
                                gutterBottom>
                                Connected YouTube Channels:
                              </Typography>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>User Info</TableCell>
                                      <TableCell>Channel Info</TableCell>
                                      <TableCell>Subscriber Count</TableCell>
                                      <TableCell>View Count</TableCell>
                                      <TableCell>Video Count</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {campaignInfProfile?.data.connectedAccount?.map(
                                      (account) => (
                                        <TableRow key={account._id}>
                                          <TableCell>
                                            <Stack
                                              direction='row'
                                              spacing={2}
                                              alignItems={'center'}>
                                              <Avatar
                                                alt={account.userinfo?.name}
                                                src={account.userinfo?.picture}
                                              />
                                              <Typography variant='subtitle1'>
                                                {account.userinfo?.name}
                                              </Typography>
                                            </Stack>
                                          </TableCell>
                                          <TableCell>
                                            <Stack
                                              direction='row'
                                              spacing={2}
                                              alignItems={'center'}>
                                              <Avatar
                                                alt='Channel Thumbnail'
                                                src={
                                                  account.channelinfo[0]
                                                    ?.snippet.thumbnails.medium
                                                    .url
                                                }
                                              />
                                              <Typography variant='subtitle1'>
                                                Custom URL:{' '}
                                                {
                                                  account.channelinfo[0]
                                                    ?.snippet.customUrl
                                                }
                                              </Typography>
                                            </Stack>
                                          </TableCell>
                                          <TableCell>
                                            <Typography variant='subtitle1'>
                                              {
                                                account.channelinfo[0]
                                                  ?.statistics.subscriberCount
                                              }
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography variant='subtitle1'>
                                              {
                                                account.channelinfo[0]
                                                  ?.statistics.viewCount
                                              }
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography variant='subtitle1'>
                                              {
                                                account.channelinfo[0]
                                                  ?.statistics.videoCount
                                              }
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </CardContent>
                          ) : (
                            ''
                          )}
                          <CardContent sx={{ maxWidth: '400px' }}>
                            <Field
                              className='autoCompleteField'
                              as={Autocomplete}
                              name='status'
                              fullWidth
                              value={values.status}
                              options={['approved', 'pending', 'rejected']}
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              getOptionLabel={(option) => option}
                              onChange={(e, newvalue) => {
                                setFieldValue('status', newvalue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Status'
                                  error={errors.status && touched.status}
                                  helperText={
                                    errors.status &&
                                    touched.status &&
                                    errors.status
                                  }
                                />
                              )}
                            />
                          </CardContent>
                          <CardActions sx={{ p: 2 }}>
                            <Button
                              type='submit'
                              variant='contained'
                              color='primary'
                              disabled={isSubmitting}>
                              {isSubmitting ? (
                                <CircularProgress size={24} />
                              ) : (
                                'Submit'
                              )}
                            </Button>
                            <Button
                              variant='contained'
                              color='secondary'
                              onClick={() => {
                                props.onCloseModal();
                              }}>
                              Cancel
                            </Button>
                          </CardActions>
                        </Card>
                      </Form>
                    )}
                  </Formik>
                </Paper>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
