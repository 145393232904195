/** @format */

import React from 'react';
import './brand.scss';
import Footer from '../../components/footer/Footer';
import Header from '../../views/layout/Header';
import BrandBanner from './BrandBanner';
import BrandVideoSec from './VideoSection';
import BrandPromoSection from './BrandPromoSection';
import BrandImageSection from './BrandImageSection';

const Brand = () => {
  return (
    <div className='brandWrapper'>
      <Header />
      <BrandBanner />
      <BrandVideoSec />
      <BrandPromoSection />
      <BrandImageSection />
      <Footer />
    </div>
  );
};

export default Brand;
