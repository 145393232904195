/** @format */

import { REQUIREMENT } from '../../configs/ApiActionUrl';
import adminApi from './AdminHttpService';
export const requirementService = {
  getAll,
  add,
  edit,
  update,
  deleteRequirement,
};

async function getAll() {
  const res = await adminApi.get(REQUIREMENT.LIST);
  if (res.status) {
  }
  return res;
}

async function add(data) {
  const res = await adminApi.post(REQUIREMENT.ADD, data);
  return res;
}
async function edit(id) {
  const res = await adminApi.get(REQUIREMENT.GET_ITEM + '/' + id);
  return res;
}
async function update(code, data) {
  const res = await adminApi.put(
    REQUIREMENT.UPDATE_INDIVIDUAL + '/' + code,
    data
  );
  return res;
}

async function deleteRequirement(code) {
  const res = await adminApi.delete(REQUIREMENT.DELETE + '/' + code, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
}
