/** @format */

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';

import Brand from '../pages/brands/Brand';
import BrandLogin from '../brandAuth/login/Login';
import BrandSignUp from '../brandAuth/signup/SignUp';
import BrandOtpScreen from '../brandAuth/otp/BrandOtpScreen';
import BrandEmailOtpScreen from '../brandAuth/email-otp/EmailOtpScreen';
import BrandProfileInput from '../brandAuth/profile-input/ProfileInput';
import RoleScreen from '../brandAuth/role/RoleScreen';
import BrandLayout from '../views/layout/Layout';
import PrivateBrandRoute from './PrivateBrandRouter';

function BrandRouter() {
  return (
    <>
      <Routes>
        <Route
          index
          path='/login'
          element={<BrandLogin />}
        />
        <Route
          path='/signup'
          element={<BrandSignUp />}
        />
        <Route
          path='/verify-otp/:id'
          element={<BrandOtpScreen />}
        />
        <Route
          path='/verify-email-otp/:id'
          element={<BrandEmailOtpScreen />}
        />
        {/* <Route
          path='/role/:id'
          element={<RoleScreen />}
        /> */}
        <Route
          path='/profile-input/:id'
          element={<BrandProfileInput />}
        />
        <Route
          path='/'
          element={<PrivateBrandRoute />}>
          <Route
            path='/*'
            element={<BrandLayout />}
          />
        </Route>
      </Routes>
    </>
  );
}
export default BrandRouter;
