/** @format */

import { InfCampServices } from '../../../services/influencer/campaignServices';
import { InfCampTypes } from '../../types/influencer/campaignTypes';
import { toast } from 'react-toastify';

export const InfCampAction = {
  fetchCategoriesOptions,
  fetchInfluencerOptions,
  add,
  getAllCampaign,
  getItemCampaign,
  getCampaignInfluencer,
  addInfluencer,
  uploadMediaURL,
};

function fetchCategoriesOptions() {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.FETCH_CATEGORIES_REQUEST });
    try {
      const res = await InfCampServices.fetchCategoriesOptions();
      dispatch({
        type: InfCampTypes.FETCH_CATEGORIES_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.FETCH_CATEGORIES_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function fetchInfluencerOptions() {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.FETCH_INFLUENCER_REQUEST });
    try {
      const res = await InfCampServices.fetchInfluencerOptions();
      console.log(res, 'res');
      dispatch({
        type: InfCampTypes.FETCH_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.FETCH_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function add(postData, setErrors, setSubmitting, navigate) {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.CAMPAIGN_ADD_REQUEST });
    try {
      const res = await InfCampServices.add(postData);
      dispatch({
        type: InfCampTypes.CAMPAIGN_ADD_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        navigate('/brand/dashboard');
        setSubmitting(false);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.CAMPAIGN_ADD_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function getAllCampaign(pageOption, searchTerm) {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.GETALL_CAMPAIGN_REQUEST });
    try {
      const res = await InfCampServices.getAllCampaign(pageOption, searchTerm);
      dispatch({
        type: InfCampTypes.GETALL_CAMPAIGN_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.GETALL_CAMPAIGN_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function getItemCampaign(code) {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.GET_ITEM_CAMPAIGN_REQUEST });
    try {
      const res = await InfCampServices.getItemCampaign(code);
      dispatch({
        type: InfCampTypes.GET_ITEM_CAMPAIGN_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.GET_ITEM_CAMPAIGN_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function getCampaignInfluencer(code) {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.GET_CAMPAIGN_INFLUENCER_REQUEST });
    try {
      const res = await InfCampServices.getCampaignInfluencer(code);
      dispatch({
        type: InfCampTypes.GET_CAMPAIGN_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.GET_CAMPAIGN_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.data && error.response.data.errors) {
          toast.error(error.response.data.message);
          //setErrors(error.response.data.errors);
        }
      }
    }
    return null;
  };
}

function addInfluencer(code, postData) {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.CAMPAIGN_ADD_INFLUENCER_REQUEST });
    try {
      const res = await InfCampServices.addInfluencer(code, postData);
      dispatch({
        type: InfCampTypes.CAMPAIGN_ADD_INFLUENCER_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.CAMPAIGN_ADD_INFLUENCER_ERROR,
          error: error.response,
        });
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }
        }
      }
    }
  };
}

function uploadMediaURL(code, postData, setErrors, setSubmitting, closeModal) {
  return async (dispatch) => {
    dispatch({ type: InfCampTypes.CAMPAIGN_UPLOAD_MEDIA_REQUEST });
    try {
      const res = await InfCampServices.uploadMediaURL(code, postData);
      dispatch({
        type: InfCampTypes.CAMPAIGN_UPLOAD_MEDIA_SUCCESS,
        payload: res.data,
      });
      if (res.data) {
        if (res.data.message) {
          toast.success(res.data.message);
        }
        closeModal();
        setSubmitting(false);
      }
    } catch (error) {
      if (error && error.response) {
        dispatch({
          type: InfCampTypes.CAMPAIGN_UPLOAD_MEDIA_ERROR,
          error: error.response,
        });
        setSubmitting(false);
        if (error.response.status === 422) {
          if (error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            setErrors(error.response.data.error);
          }
        }
      }
    }
  };
}
