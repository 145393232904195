import './SocialBtn.scss';


const SocialBtn =  ({ title, imageSrc, onClick, isSelected }) => {
    return (
        <div className={`custom-card ${isSelected ? 'selected' : ''}`} onClick={onClick}>
        <img className="card-image" src={imageSrc} alt="Social" />
        <div className="card-title">{title}</div>
      </div>
    );
  };
  
  export default SocialBtn;

