/** @format */

import React, { useState, useEffect } from 'react';
import './ProfileInput.scss';
import AuthBanner from '../../components/authbanner/AuthBanner';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Logo from '../../assets/logo-black.svg';
import TextField from '../../components/textfield/TextField';
import SelectField from '../../components/selectfield/SelectField';
import validator from 'validator';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../store/actions/influencer/authAction';
import { Loader } from '../../utils/Loader';
// import { getRegisterData } from '../../utils/AuthConfig';

// const registerData = getRegisterData();

// console.log(registerData, 'registerData');

const initialUserValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  city: '',
  requirementType: '',
  budget: '',
  //password: '',
};

const initialErrors = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  city: '',
  requirementType: '',
  budget: '',
  //password: '',
};

const options = [{ value: 'newdelhi', label: 'NewDelhi' }];

const ProfileInput = () => {
  const [user, setUser] = useState(initialUserValues);
  const [errors, setErrors] = useState(initialErrors);
  const [isAcceptTerms, setAcceptTerms] = useState(false);
  const [options, setOptions] = useState([]);
  const [reqOptions, setReqOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    // JavaScript logic here (the code you provided)
    const sourceDiv = document.getElementById('container-left');
    const targetDiv = document.getElementById('container-right');

    const sourceDivHeight = sourceDiv.getBoundingClientRect().height;
    console.log(sourceDivHeight);
    targetDiv.style.maxHeight = sourceDivHeight + 'px';
  }, []);

  useEffect(() => {
    dispatch(authAction.fetchCitiesOptions());
    dispatch(authAction.fetchRequirementOptions());
    dispatch(authAction.verifyLink('register', id, navigate));
  }, [dispatch]);

  const { cities, isLoading, requirementType } = useSelector(
    (state) => state.authentication
  );
  useEffect(() => {
    setUser((prevValues) => ({ ...prevValues, holderId: id }));

    if (cities && cities.data) {
      const transformedData = cities.data.map((item) => ({
        value: item._id,
        label: item.name,
      }));
      setOptions(transformedData);
    }

    if (requirementType && requirementType.data) {
      const reqData = requirementType.data.map((item) => ({
        value: item._id,
        label: item.name,
      }));
      setReqOptions(reqData);
    }
  }, [cities, requirementType]);

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleOnBlurTextField = (e) => {};

  const onTabNextButton = () => {
    setErrors((values) => ({
      ...values,
      firstName: firstNameValidation(),
      lastName: lastNameValidation(),
      email: emailValidation(),
      phoneNumber: phoneNumberValidation(),
      city: cityValidation(),
      requirementType: requirementValidation(),
      budget: budgetValidation(),
    }));
    if (
      firstNameValidation() === '' &&
      lastNameValidation() === '' &&
      emailValidation() === '' &&
      phoneNumberValidation() === '' &&
      cityValidation() === '' &&
      requirementValidation() === '' &&
      budgetValidation() === ''
    ) {
      console.log(user, 'user');
      dispatch(authAction.influencerRegister(user, navigate));
    }
  };

  function firstNameValidation() {
    const firstName = user.firstName;
    if (validator.isEmpty(firstName)) {
      return 'Please, enter First Name';
    }
    return '';
  }
  function lastNameValidation() {
    const lastName = user.lastName;
    if (validator.isEmpty(lastName)) {
      return 'Please, enter Last Name';
    }
    return '';
  }
  function emailValidation() {
    const email = user.email;
    if (validator.isEmpty(email)) {
      return 'Please, enter Email';
    } else if (!validator.isEmail(email)) {
      return 'Please, enter valid Email!';
    }
    return '';
  }

  function budgetValidation() {
    const budget = user.budget;
    if (validator.isEmpty(budget)) {
      return 'Please, enter budget';
    } else if (!validator.isNumeric(budget)) {
      return 'Please, enter valid budget';
    }
    return '';
  }

  function phoneNumberValidation() {
    const phoneNumber = user.phoneNumber;
    if (validator.isEmpty(phoneNumber)) {
      return 'Please, enter phone number';
    } else if (!validator.isNumeric(phoneNumber) && phoneNumber.length !== 10) {
      return 'Please, enter valid phone number';
    }
    return '';
  }
  function cityValidation() {
    if (!user.city) {
      return (errors.city = 'Please select a city');
    }
    return '';
  }
  function requirementValidation() {
    if (!user.requirementType) {
      return (errors.requirementType = 'Please select a requirement');
    }
    return '';
  }

  // function passwordValidation() {
  //   const password = user.password;
  //   if (validator.isEmpty(password)) {
  //     return 'Password required';
  //   } else if (password.length < 8) {
  //     return 'Password must be atleast 8 letter';
  //   } else if (password.length > 15) {
  //     return 'Password must be 8-15 letters';
  //   }
  //   return '';
  // }

  const onTapAccept = () => {
    setAcceptTerms(!isAcceptTerms);
  };

  function handleSelectFieldChange(name, selectedOption) {
    console.log(selectedOption, 'selectedOption');

    setUser((prevValues) => ({ ...prevValues, [name]: selectedOption }));
  }
  return (
    <div className='profile-input'>
      {isLoading ? <Loader /> : ''}
      <div className='profile-input-container'>
        <div
          id='container-left'
          className='profile-input-container-left'>
          <div className='profile-input-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='profile-input-container-left-content-fields'>
              <div className='profile-input-header'>Describe yourself</div>
              <div className='profile-name-fileds-container'>
                <div className='name-sec'>
                  <div className='firstname'>
                    <TextField
                      name='firstName'
                      placeholder='FirstName'
                      hintText='First Name'
                      variant='normal'
                      onChange={handleTextFieldChange}
                      onBlur={handleOnBlurTextField}
                    />
                    {errors.firstName !== null && errors.firstName !== '' && (
                      <div className='error-message-container'>
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className='lasttname'>
                    <TextField
                      name='lastName'
                      placeholder='Last Name'
                      hintText='LastName'
                      variant='normal'
                      onChange={handleTextFieldChange}
                      onBlur={handleOnBlurTextField}
                    />
                    {errors.lastName !== null && errors.lastName !== '' && (
                      <div className='error-message-container'>
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                </div>
                <div className='emailTextField'>
                  <TextField
                    name='email'
                    placeholder='e.g john@mail.com'
                    hintText='Email'
                    variant='normal'
                    onChange={handleTextFieldChange}
                    onBlur={handleOnBlurTextField}
                  />
                  {errors.email !== null && errors.email !== '' && (
                    <div className='error-message-container'>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className='phoneTextField'>
                  <TextField
                    name='phoneNumber'
                    placeholder='xxx xxx xxx'
                    hintText='Phone number'
                    variant='phone'
                    onChange={handleTextFieldChange}
                    onBlur={handleOnBlurTextField}
                  />
                  {errors.phoneNumber !== null && errors.phoneNumber !== '' && (
                    <div className='error-message-container'>
                      {errors.phoneNumber}
                    </div>
                  )}
                </div>

                <div className='categoryTextField'>
                  <SelectField
                    name='city'
                    options={options}
                    selectedItem={user.city}
                    placeholder={
                      <div className='placeholder-text'>Select city</div>
                    }
                    setselectedItem={(selectedOption) =>
                      handleSelectFieldChange('city', selectedOption)
                    }
                  />
                  {errors.city !== null && errors.city !== '' && (
                    <div className='error-message-container'>{errors.city}</div>
                  )}
                </div>
                <div className='categoryTextField'>
                  <SelectField
                    name='requirementType'
                    options={reqOptions}
                    selectedItem={user.requirementType}
                    placeholder={
                      <div className='placeholder-text'>Select Requirement</div>
                    }
                    setselectedItem={(selectedOption) =>
                      handleSelectFieldChange('requirementType', selectedOption)
                    }
                  />
                  {errors.requirementType !== null &&
                    errors.requirementType !== '' && (
                      <div className='error-message-container'>
                        {errors.requirementType}
                      </div>
                    )}
                </div>
                <div className='categoryTextField'>
                  <TextField
                    name='budget'
                    placeholder='Enter budget '
                    hintText='Budget'
                    variant='budget'
                    onChange={handleTextFieldChange}
                    onBlur={handleOnBlurTextField}
                  />
                  {errors.budget !== null && errors.budget !== '' && (
                    <div className='error-message-container'>
                      {errors.budget}
                    </div>
                  )}
                </div>
                {/* <TextField
                  name='password'
                  placeholder='Password'
                  hintText='Password'
                  variant='password'
                  onChange={handleTextFieldChange}
                  onBlur={handleOnBlurTextField}
                />
                {errors.password !== null && errors.password !== '' && (
                  <div className='error-message-container'>
                    {errors.password}
                  </div>
                )}
                <div className='password-hint-container'>
                  <span className='password-hint-text'>
                    The password must contain at least 8 characters, include
                    letters in different case, as well as at least one number
                    and one special character: @#$%&*~0+=-,
                  </span>
                </div> */}
                <div className='terms-and-condtions-container'>
                  <div
                    className='terms-and-condtions-checkbox'
                    onClick={onTapAccept}>
                    {isAcceptTerms ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </div>
                  <div className='terms-and-condtions-container-text'>
                    I accept{' '}
                    <span className='terms-link'>the offer agreement </span>and
                    give <span className='terms-link'>consent</span> to the
                    pyocessing of my personal data
                  </div>
                </div>
              </div>

              <div className='next-button'>
                <button onClick={onTabNextButton}>Next</button>
              </div>
            </div>
          </div>
        </div>
        <div
          id='container-right'
          className='profile-input-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default ProfileInput;
