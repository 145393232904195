/** @format */

import React from 'react';
import { useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridActionsCellItem,
  //GridLinkOperator,
} from '@mui/x-data-grid';
import { Chip, IconButton } from '@mui/material';
import InfluencerEdit from './edit';
import { approveInfluencerAction } from '../../../store/actions/admin/approveInfluencerAction';

// import LoadingSkeleton from '../../../helpers/LoadingSkeleton';
// import CustomNoRowsOverlay from '../../../helpers/NoRowOverlay';

function QuickSearchToolbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  return (
    <Box
      className='tableTopWrap page__title'
      sx={{
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 3,
      }}>
      <Stack
        direction='row'
        sx={{ ml: 'auto' }}
        spacing={2}
        className='hierarchy_list_btn'>
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput
              .split(',')
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }
          variant='outlined'
          size='small'
          sx={{ pb: 0 }}
        />
      </Stack>
    </Box>
  );
}

function ApproveInfluencerList() {
  const [editOpen, setEditOpen] = React.useState(false);
  const [dialogopen, setDialogOpen] = React.useState(false);
  const [itemId, setItemID] = React.useState('');
  const [dataList, setDataList] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    action: false,
  });
  const [itemInfo, setItemInfo] = React.useState({
    id: '',
    status: '',
    confirm_status: '',
  });

  const handleEditOpen = () => setEditOpen(true);
  const handleDialogClickOpen = (id) => {
    setDialogOpen(true);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initFetch = useCallback(() => {
    dispatch(approveInfluencerAction.getAll());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const { approveInfluencer_data, approveInfluencer_status } = useSelector(
    (state) => state.approveInfluencers
  );

  useEffect(() => {
    if (approveInfluencer_status === true) {
      if (approveInfluencer_data) {
        setDataList(approveInfluencer_data);
      }
    }
  }, [approveInfluencer_data, approveInfluencer_status]);

  useEffect(() => {
    setLoader(true);
    // if (dataList && dataList.length > 0) {
    //   setTableData(dataList);
    // }

    const timeoutId = setTimeout(() => {
      setLoader(false);
      if (dataList && dataList?.influencers) {
        setTableData(dataList.influencers);
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dataList]);

  console.log(tableData, 'tableData');

  const dataRow = [
    {
      field: 'id',
      headerName: 'S.No',
      filterable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      renderCell: (params) => {
        if (params && params.value) {
          return <div className='name'>{params.value}</div>;
        } else return null;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      renderCell: (params) => {
        if (params && params.value) {
          return <div className='name'>{params.value}</div>;
        } else return null;
      },
    },
    {
      field: 'email',
      headerName: 'Email ID',
      flex: 1,
      renderCell: (params) => {
        if (params && params.value) {
          return <div className='name'>{params.value}</div>;
        } else return null;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        if (params && params.value) {
          if (params.value === 'approved') {
            return (
              <Chip
                color='success'
                label={params.value}
                sx={{ textTransform: 'capitalize' }}
              />
            );
          } else if (params.value === 'pending') {
            return (
              <Chip
                color='warning'
                label={params.value}
                sx={{ textTransform: 'capitalize' }}
              />
            );
          } else {
            return (
              <Chip
                color='error'
                label={params.value}
                sx={{ textTransform: 'capitalize' }}
              />
            );
          }
        }
        return null;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          className='actionBtn'
          label='Delete'
          onClick={() => {
            navigate('/admin/influencer/edit/' + params.id);
            // setItemID(params.id);
          }}
          //onClick={deleteUser(params.id)}
        />,
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   className='actionBtn'
        //   label='Toggle Admin'
        //   onClick={() => {
        //     handleDialogClickOpen();
        //     setItemInfo({
        //       id: params.id,
        //       status: 3,
        //       confirm_status: 'delete',
        //     });
        //   }}
        //   //onClick={toggleAdmin(params.id)}
        //   //showInMenu
        // />,
      ],
    },
  ];

  return (
    <Box
      sx={{ mb: 3 }}
      className='cardWrapper'>
      <div className='pageTitleWrap'>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'>
          <Typography
            className=''
            component={'h2'}
            variant='h2'>
            Influencer List
          </Typography>
        </Stack>
      </div>
      <Box
        sx={{ bgcolor: 'white' }}
        className='dataTable__wrap'>
        <DataGrid
          columns={dataRow}
          rows={tableData}
          disableColumnFilter
          rowHeight={55}
          disableMultipleRowSelection={true}
          getRowId={(row) => row._id}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          autoHeight
          sx={{ minHeight: 650 }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          slots={{
            toolbar: QuickSearchToolbar,
            // loadingOverlay: LoadingSkeleton,
            // noRowsOverlay: CustomNoRowsOverlay,
          }}
          // slotProps={}
          loading={loader}
        />
      </Box>
      {/* {editOpen === true ? (
        <InfluencerEdit
          openmodal={editOpen}
          approveInfluencer_id={itemId}
          onCloseModal={() => setEditOpen(false)}
        />
      ) : (
        ''
      )} */}
    </Box>
  );
}

export default ApproveInfluencerList;
