/** @format */

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './OtpScreen.scss';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import OtpInput from '../../components/otpfield/OtpInput';
import { authAction } from '../../actions/AuthAction';
import api from '../../services/HttpService';
import { AUTH } from '../../configs/ApiActionUrl';
import { toast } from 'react-toastify';

const BrandOtpScreen = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [hideNumber, setHideNumber] = useState('');
  const location = useLocation();

  //let { data } = location.state !== null ? location.state : null;
  const { id } = useParams();

  const maskingFunction = (phoneNumber) => {
    let subNum = phoneNumber.toString().substring(0, 3);
    subNum = subNum + 'XXXXXXX';
    setHideNumber(subNum);
  };

  async function verifyLink(module, code) {
    try {
      const res = await api.get(AUTH.VERIFY_LINK + '/' + module + '/' + code);
      if (res.data && res.data.data) {
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (location.state && location.state.login) {
          navigate('/brand/login');
        } else {
          navigate('/brand/signup');
        }
        toast.error('Link Expired');
        // You can handle the error here, e.g., toast.error(error.response.data.message);
      }
    }
    return null;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  useEffect(() => {
    verifyLink('phone', id);
  }, []);

  useEffect(() => {
    if (location.state && location.state !== null) {
      if (location.state.data && location.state.data.phone_number) {
        maskingFunction(location.state.data.phone_number);
      }
    }
  }, []);

  // Handling otp field text chanages
  const handleTextFieldChange = (e) => {
    console.log(e);
    setOtp(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Custom validation logic
    if (otp.length !== 6) {
      setOtpError('Please enter a valid 6-digit OTP.');
      return;
    }

    let formData = {
      holderId: id,
      otp: otp,
    };

    if (location.state && location.state !== null) {
      console.log(location.state, 'location.state');

      if (location.state.login !== undefined) {
        console.log(location.state.login, 'location.state.login');
        authAction.OtpVerifyPhone(formData, navigate, location.state.login);
      } else {
        console.log(location.state, 'location.state regiter');
        authAction.OtpVerifyPhone(formData, navigate, false);
      }
    }
    //console.log('Submitted OTP:', otp);

    // Reset error state
    setOtpError('');
  };

  //*  ==== OnTap Contune button ====== */

  const onTapContinueButton = () => {
    navigate('/profile-input');
  };

  //console.log(hideNumber, 'hideNumber');

  return (
    <div className='otp-screen'>
      <div className='otp-screen-container'>
        <div className='otp-screen-container-left'>
          <div className='otp-screen-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <form onSubmit={handleSubmit}>
              <div className='otp-screen-container-left-content-fields'>
                <div className='otp-screen-header'>
                  Phone number
                  <br />
                  verification
                </div>
                <div className='otp-screen-caption'>
                  <span>
                    Your phone has received a confirmation code at +91{' '}
                    {hideNumber}
                    <span className='otp-screen-change-number'>
                      <Link to='/brand/signup'>Change number</Link>
                    </span>
                  </span>
                </div>

                <OtpInput
                  onChange={setOtp}
                  value={otp}
                />

                <div className='countdown-text'>
                  {seconds > 0 || minutes > 0 ? (
                    <>
                      <span className='timer-info-text'>
                        You can resend the code in{' '}
                      </span>
                      <span className='timer-value'>
                        {' '}
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    </>
                  ) : (
                    <div className='resend-container'>
                      <span className='resend-info-text'>
                        Didn't recieve code?
                      </span>
                      <button className='resend-button'>Resend OTP</button>
                    </div>
                  )}
                </div>
                {otpError && (
                  <div className='otp-error-container'>
                    <span className='otp-error-message'>{otpError}</span>
                  </div>
                )}

                <div className='continue-button'>
                  <button type='submit'>Verify</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='otp-screen-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default BrandOtpScreen;
