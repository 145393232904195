/** @format */

export const categoryTypes = {
  GETALL_CATEGORIES_REQUEST: 'GETALL_CATEGORIES_REQUEST',
  GETALL_CATEGORIES_SUCCESS: 'GETALL_CATEGORIES_SUCCESS',
  GETALL_CATEGORIES_ERROR: 'GETALL_CATEGORIES_ERROR',

  GETALL_ACTIVE_CATEGORY_REQUEST: 'GETALL_ACTIVE_CATEGORY_REQUEST',
  GETALL_ACTIVE_CATEGORY_SUCCESS: 'GETALL_ACTIVE_CATEGORY_SUCCESS',
  GETALL_ACTIVE_CATEGORY_ERROR: 'GETALL_ACTIVE_CATEGORY_ERROR',

  ADD_CATEGORY_REQUEST: 'ADD_CATEGORY_REQUEST',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',

  EDIT_CATEGORY_REQUEST: 'EDIT_CATEGORY_REQUEST',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_ERROR: 'EDIT_CATEGORY_ERROR',

  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',

  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

  UPDATE_STATUS_CATEGORY_REQUEST: 'UPDATE_STATUS_CATEGORY_REQUEST',
  UPDATE_STATUS_CATEGORY_SUCCESS: 'UPDATE_STATUS_CATEGORY_SUCCESS',
  UPDATE_STATUS_CATEGORY_ERROR: 'UPDATE_STATUS_CATEGORY_ERROR',
};
