import React from 'react'
import './PartnersSection.scss'

import ForbesLogo from '../../assets/clients/forbes.svg'
import WiredLogo from '../../assets/clients/wired.svg'
import PRWeekLogo from '../../assets/clients/pr.svg'
import AdAge from '../../assets/clients/adage.svg'

const paths = [ForbesLogo, WiredLogo, PRWeekLogo, AdAge, WiredLogo,  ForbesLogo]

const PartnersSection = () => {
  return (
    <div className="partnersSection">
        <div className="container">
            <div className="header">Our clients get real results</div>
            <div className="title">Clients we're taking care of</div>
            <div className="partners">
                {
                    paths.map((path, index)=>(
                        <PartnerLogo path={path}/>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

const PartnerLogo = ({path})=>{
    return(
        <img src={path} alt="" />
    )
}

export default PartnersSection