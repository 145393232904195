/** @format */

import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import './social.scss';
import SocialBtn from '../../components/socialbutton/SocialBtn';
import instagram from '../../assets/instagram.png';
import youtube from '../../assets/youtube.svg';
import twitter from '../../assets/twitter.svg';
import axios from 'axios';
import {
  LoginSocialInstagram,
  LoginSocialTwitter,
  LoginSocialGoogle,
} from 'reactjs-social-login';
import { getRegisterData } from '../../utils/AuthConfig';

const CLIENT_ID = 'YOUR_CLIENT_ID';
const Social = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null);
  // const [subscriberCount, setSubscriberCount] = useState(0);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [youtubeAccount, setYouTubeAccount] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [googleUser, setGoogleUser] = useState([]);

  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState(null);

  const REDIRECT_URI = window.location.href;
  const { id } = useParams();

  const onLoginStart = useCallback(() => {
    //alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    //alert('logout success');
  }, []);

  const dataa = getRegisterData();

  //console.log(dataa.userId, 'dataa');

  // function getYoutubeData() {
  //   const channelId = 'UCilHcqDNkRrkOWyp9cx4ofQ'; // Replace with the target channel ID
  //   const apiKey = 'AIzaSyAVM9b0MKAI9_39mV4lcHQqFGKT-f6vvVM'; // Replace with your YouTube API key
  //   const apiUrl = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${apiKey}`;
  //   console.log(apiUrl);
  //   axios
  //     .get(apiUrl)
  //     .then((response) => {
  //       const subscriberCount =
  //         response.data.items[0].statistics.subscriberCount;
  //       console.log(' data:', subscriberCount);
  //       setSubscriberCount(subscriberCount);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }

  // const handleLogin = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     console.log(codeResponse);
  //     setGoogleUser(codeResponse);
  //   },
  //   onError: (error) => console.log('Login Failed:', error),
  // });
  // console.log(channelData, 'channelData');
  // console.log(profile, 'profile');
  // console.log(provider, 'provider');

  const handleGetYouTubeInfo = (profile) => () => {};

  // useEffect(() => {
  //   // if (googleUser && googleUser.length > 0) {
  //   // }
  //   axios
  //     .get(`https://www.googleapis.com/youtube/v3/channels`, {
  //       params: {
  //         part: 'snippet',
  //         mine: true,
  //       },
  //       headers: {
  //         Authorization: `Bearer ya29.a0AfB_byBeIvP3ePf9GUboOSaoQFhxLqGC2MEOq3G6EZEjTANttrRu9mXxwufoVpjgCn0VRKF2yYODKBOD6DPdHBk5TW6tg2nX1qyMTqO5Hcow4JMBMDwyv88iN4X3dr0pVzl_vG11dce5MxEeOG2gCfeZeTFK94MATIAaCgYKAWQSARMSFQGOcNnCIueXxXyQ9ue581LV9K8UqA0170`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response.data, 'dfsdfs');
  //       setChannelData(response.data.items[0]);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching YouTube channel data:', error);
  //     });
  // }, []);

  // const handleCardClick = (title) => () => {
  //   console.log(`Selected title: ${title}`);
  //   setSelectedButton(title);
  //   getYoutubeData();

  //   // You can perform any action with the selected title
  // };
  console.log(provider, 'provider');
  console.log(profile, 'profile');

  return (
    <div className='social'>
      <div className='social-container'>
        <div className='social-container-left'>
          <div className='social-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='social-container-left-content-fields'>
              <div className='social-header'>Connect your account</div>
              <div className='sub-message-container'>
                Connect any social media accounts you use for advertising.
              </div>
              <div className='button-group-container'>
                <LoginSocialInstagram
                  //isOnlyGetCode
                  //isOnlyGetToken
                  client_id={'977377980092912'}
                  client_secret={'774f3a8a432d7e23f00b24682a4d4392'}
                  redirect_uri={'https://socialshock.in/'}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    handleGetYouTubeInfo(provider);
                    setProvider(data);
                    setProfile(data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}>
                  <SocialBtn
                    title='Instagram'
                    imageSrc={instagram}
                    //onClick={handleCardClick('Instagram')}
                    isSelected={selectedButton === 'Instagram'}
                  />
                </LoginSocialInstagram>
                <LoginSocialGoogle
                  isOnlyGetToken
                  client_id={
                    '382917083923-7pnpb05nfb0g84uhv1lepevf4um6oucb.apps.googleusercontent.com'
                  }
                  onLoginStart={onLoginStart}
                  scope={'https://www.googleapis.com/auth/youtube.readonly'}
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data);
                    navigate('/influencer/social/' + provider + '/' + id, {
                      state: { data: data },
                    });
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}>
                  <SocialBtn
                    title='Youtube'
                    imageSrc={youtube}
                    //onClick={handleLogin}
                    isSelected={selectedButton === 'Youtube'}
                  />
                </LoginSocialGoogle>

                <LoginSocialTwitter
                  isOnlyGetToken
                  client_id={'LUlmQkFMWlZrNVNpVkNDU1oweEc6MTpjaQ'}
                  // client_secret={
                  //   'WnTJ6BrYe8yK1lpVCTVLG-GTz9-kB7-DA7SuZq-jXP3jyCrrsa'
                  // }
                  redirect_uri={REDIRECT_URI}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}>
                  <SocialBtn
                    title='Twitter'
                    imageSrc={twitter}
                    //onClick={handleCardClick('Twitter')}
                    isSelected={selectedButton === 'Twitter'}
                  />
                </LoginSocialTwitter>
              </div>
              {/* <p>Subscriber Count: {subscriberCount}</p> */}
            </div>
            <div>
              {/* {isLoggedIn && (
                <div>
                  <h1>Welcome, {youtubeAccount.snippet.title}!</h1>
                  <p>Your YouTube account email is: {email}</p>
                  <p>
                    Your YouTube account subscriber count is: {subscriberCount}
                  </p>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className='social-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default Social;
