/** @format */

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './ThankYouScreen.scss';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import ThankYou from '../../assets/thankyou.png';
import { Button, Typography } from '@mui/material';

const ThankYouScreen = () => {
  const navigate = useNavigate();

  return (
    <div className='thanks-screen'>
      <div className='thanks-screen-container'>
        <div className='thanks-screen-container-left'>
          <div className='thanks-screen-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='thanks-content-wrap'>
              <div className='thanks-content-img'>
                <img
                  src={ThankYou}
                  alt='ThankYou'
                />
              </div>
              <Typography
                variant='h3'
                component={'h3'}>
                Congratulations
              </Typography>
              <Typography
                variant='body1'
                component={'p'}>
                You've successfully completed your registration
              </Typography>
              <Button
                variant='contained'
                component={Link}
                to='/brand/login'>
                Done
              </Button>
            </div>
          </div>
        </div>
        <div className='thanks-screen-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default ThankYouScreen;
