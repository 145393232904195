/** @format */

import React from 'react';
import { useState } from 'react';
import './TextField.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const TextField = ({
  name,
  placeholder,
  hintText,
  variant,
  onChange,
  onFocus,
  onBlur,
}) => {
  // Fetch textfield variant
  const textFieldVariant = variant.toLowerCase();

  // Password visibility
  const [visibility, setVisibility] = useState(textFieldVariant !== 'password');

  // Tap event for eye icon
  const onTapEyeIcon = () => {
    setVisibility(!visibility);
  };

  return (
    <div className='text-field'>
      <div className='text-field-container'>
        <div className='helper-text'>{hintText}</div>
        {textFieldVariant == 'textarea' ? (
          <div className='textarea-container'>
            <textarea
              name={name}
              id={name}
              placeholder={placeholder}
              rows='4'
              cols='4'></textarea>
          </div>
        ) : (
          <div className='field-container'>
            {textFieldVariant.toLowerCase() === 'phone' ? (
              <>
                {' '}
                <div className='std-code'>+91</div>
                <div className='vl'></div>
              </>
            ) : null}
            {textFieldVariant.toLowerCase() === 'budget' ? (
              <>
                {' '}
                <div className='std-code'>₹</div>
                <div className='vl'></div>
              </>
            ) : null}
            <div className='field'>
              <input
                type={
                  textFieldVariant === 'password' && !visibility
                    ? 'password'
                    : 'text'
                }
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </div>
            {textFieldVariant.toLowerCase() === 'password' && (
              <div
                className='password-icon'
                onClick={onTapEyeIcon}>
                {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}{' '}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
