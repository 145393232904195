import React from 'react'
import './joincards.scss'
import JoinCard from '../../../components/joincard/JoinCard'


const onTapJoinInfluencer = () =>{
    alert('Taped Influecncer')
}

const onTapJoinBrand = () =>{
    alert('Taped Brand')
}

const JoinCards = () => {
  return (
    <div className="joincards">
        <div className="container">
            <div className="title">Join 750,000+ influencers and businesses using Social Bottle today</div>
            <div className="cards">
                <JoinCard 
                    header="Join as an Influencer"
                    content="Create a public profile specifically for you; this will help marketers better understand you and quickly book you."
                    buttonTitle="Join for free today"
                    onTap={onTapJoinInfluencer}
                    />
                <JoinCard
                    header="Sign up as a Brand" 
                    content="Manage your reservations and interact directly with your favourite creators. Grow and scale with our expertise." 
                    buttonTitle="Join today"
                    onTap={onTapJoinBrand}
                    />
            </div>
        </div>
    </div>
  )
}

export default JoinCards