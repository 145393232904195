/** @format */

import React from 'react';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './login.scss';
import Logo from '../../assets/logo-black.svg';
import GoogleIcon from '../../assets/auth/google-icon.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import TextField from '../../components/textfield/TextField';
import validator from 'validator';

import axios from 'axios';
import { authAction } from '../../actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInToken } from '../../utils/AuthConfig';

const initialUserValues = {
  phone_number: '',
};

const initialErrors = {
  phone_number: '',
};

const BrandLogin = () => {
  // For dynamic navigatioj
  const navigate = useNavigate();

  const loggedIn = getLoggedInToken();
  //const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn) {
      navigate('/brand/dashboard');
    }
  }, [loggedIn, navigate]);

  // {useState} for user object
  const [user, setUser] = useState(initialUserValues);

  const [errors, setErrors] = useState(initialErrors);
  // {useState} for remember me
  const [isRememberMe, setRememberMe] = useState(false);

  function usernameValidation() {
    const phoneNumber = user.phone_number;
    if (validator.isEmpty(phoneNumber)) {
      return 'Please, enter valid phone number';
    } else if (validator.isNumeric(phoneNumber) && phoneNumber.length !== 10) {
      return 'Please, enter valid phone number';
    }
    return '';
  }

  // function passwordValidation() {
  //   const password = user.password;
  //   if (validator.isEmpty(password)) {
  //     return 'Password required';
  //   } else if (password.length < 8) {
  //     return 'Password must be atleast 8 letter';
  //   } else if (password.length > 15) {
  //     return 'Password must be 8-15 letters';
  //   }
  //   return '';
  // }

  // Tap events
  const onTapRememberMe = () => {
    setRememberMe(!isRememberMe);
  };

  const onTapLoginButton = () => {
    setErrors((values) => ({
      ...values,
      phone_number: usernameValidation(),
    }));
    if (usernameValidation() === '') {
      authAction.login(user, navigate, 'login');
      //navigate(`/verify-otp/`);
    }
  };

  const onTapForgotPasswordButton = () => {
    alert('onTapForgotPasswordButton');
  };

  const onTapSignUpButton = () => {
    navigate('/brand/signup');
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnBlurTextField = (e) => {};

  return (
    <div className='login'>
      <div className='login-container'>
        <div className='login-container-left'>
          <div className='login-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='login-container-left-content-fields'>
              <div className='login-header'>Login to Social Bottle</div>
              <TextField
                name='phone_number'
                placeholder='Phone number'
                hintText='Phone number'
                variant='phone'
                onChange={handleTextFieldChange}
                onBlur={handleOnBlurTextField}
              />
              {errors.phone_number !== null && errors.phone_number !== '' && (
                <div className='error-message-container'>
                  {errors.phone_number}
                </div>
              )}
              {/* <TextField
                name='password'
                placeholder='Enter password'
                hintText='Password'
                variant='password'
                onChange={handleTextFieldChange}
                onBlur={handleOnBlurTextField}
              />
              {errors.password !== null && errors.password !== '' && (
                <div className='error-message-container'>{errors.password}</div>
              )} */}
              <div className='remember-container'>
                <div
                  className='remember-me'
                  onClick={onTapRememberMe}>
                  <div className='remember-me-checkbox'>
                    {isRememberMe ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </div>
                  <div className='remember-me-text'>Remember me</div>
                </div>
                <button
                  className='forgot-password'
                  onClick={onTapForgotPasswordButton}>
                  Forgot password?
                </button>
              </div>
              <div className='login-button'>
                <button onClick={onTapLoginButton}>Login</button>
              </div>
              <div className='dont-have-account-container'>
                <span className='dont-have-account-text'>
                  {' '}
                  Don’t have an account?
                </span>
                <span
                  className='register-button'
                  onClick={onTapSignUpButton}>
                  Register now
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='login-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default BrandLogin;
