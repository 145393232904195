/** @format */

import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { approveBrandAction } from '../../../store/actions/admin/approveBrandAction';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { MiniLoader } from '../../../utils/Loader';
import { StatusValidation } from '../../../utils/AdminValidation';

export default function BrandEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();

  useEffect(() => {
    dispatch(approveBrandAction.edit(code));
  }, [dispatch, code]);

  const { approveBrandData, loading } = useSelector(
    (state) => state.approveBrands
  );

  return (
    <Box
      sx={{ mb: 3, position: 'relative', overflow: 'hidden' }}
      className='cardWrapper'>
      {loading ? <MiniLoader /> : ''}
      <div className='pageTitleWrap'>
        <Typography
          component='h2'
          variant='h2'>
          Edit Brand
        </Typography>
      </div>
      <Formik
        initialValues={{
          status: approveBrandData?.status || '', // Initialize status with data from API
        }}
        enableReinitialize
        validationSchema={StatusValidation}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          console.log(values);
          dispatch(
            approveBrandAction.update(
              code,
              values,
              setErrors,
              setSubmitting,
              navigate
            )
          );
        }}>
        {({ isSubmitting, values, setFieldValue, errors, touched }) => (
          <Form>
            <Card
              variant='outlined'
              className='formWrapper'>
              <CardHeader
                title={`${approveBrandData?.firstName} ${approveBrandData?.lastName}`}
              />
              <CardContent>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>First Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveBrandData?.firstName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Last Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveBrandData?.lastName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveBrandData?.email}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveBrandData?.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Company</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography gutterBottom>
                            {approveBrandData?.company}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography gutterBottom>Categories</Typography>
                        </TableCell>
                        <TableCell>
                          {approveBrandData?.categories &&
                            approveBrandData?.categories.map((categ) => (
                              <Chip
                                key={categ._id}
                                label={categ.name}
                                sx={{
                                  background: categ.color,
                                  color: '#fff',
                                  mr: 1,
                                }}
                              />
                            ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>

              <CardContent sx={{ maxWidth: '400px' }}>
                <Field
                  className='autoCompleteField'
                  as={Autocomplete}
                  name='status'
                  fullWidth
                  value={values.status}
                  options={['approved', 'pending', 'rejected']}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  onChange={(e, newvalue) => {
                    setFieldValue('status', newvalue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Status'
                      error={errors.status && touched.status}
                      helperText={
                        errors.status && touched.status && errors.status
                      }
                    />
                  )}
                />
              </CardContent>
              <CardActions sx={{ p: 2 }}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    navigate('/admin/brand');
                  }}>
                  Back
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
