/** @format */

import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Logo from '../../assets/logo-black.svg';
import AuthBanner from '../../components/authbanner/AuthBanner';
import './social.scss';
import SocialBtn from '../../components/socialbutton/SocialBtn';
import instagram from '../../assets/instagram.png';
import youtube from '../../assets/youtube.svg';
import twitter from '../../assets/twitter.svg';
import axios from 'axios';
import {
  LoginSocialInstagram,
  LoginSocialTwitter,
  LoginSocialGoogle,
} from 'reactjs-social-login';
import { toast } from 'react-toastify';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { authAction } from '../../store/actions/influencer/authAction';

const CLIENT_ID = 'YOUR_CLIENT_ID';
const SocialProvider = () => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [channelData, setChannelData] = useState(null);
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;

  const REDIRECT_URI = window.location.href;
  const { provider, id } = useParams();

  const handleGetYouTubeInfo = (profile) => () => {};

  useEffect(() => {
    if (state && state !== null) {
      console.log(state, 'state');
      if (state.data) {
        axios
          .get(`https://www.googleapis.com/oauth2/v3/userinfo`, {
            headers: {
              Authorization: `Bearer ${state.data.access_token}`,
            },
          })
          .then((response) => {
            console.log(response.data, 'dfsdfs');
            setProfile(response.data);
          })
          .catch((error) => {
            console.error('Error fetching YouTube channel data:', error);
            if (error && error.response) {
              if (error.response.status === 401) {
                console.log('xcazsdsa');
                //navigate('/influencer/social/' + id);
                toast.error(
                  'Session Expired!!! Please connect the google again'
                );
              }
            }
          });
        axios
          .get(`https://www.googleapis.com/youtube/v3/channels`, {
            params: {
              part: 'snippet,statistics',
              mine: true,
            },
            headers: {
              Authorization: `Bearer ${state.data.access_token}`,
            },
          })
          .then((response) => {
            console.log(response.data, 'dfsdfs');
            setChannelData(response.data.items);
          })
          .catch((error) => {
            console.error('Error fetching YouTube channel data:', error);
            if (error && error.response) {
              if (error.response.status === 401) {
                console.log('xcazsdsa');
                navigate('/influencer/social/' + id);
                toast.error(
                  'Session Expired!!! Please connect the google again'
                );
              }
            }
          });
      }
    } else {
      navigate('/influencer/social/' + id);
    }
  }, []);

  // const handleCardClick = (title) => () => {
  //   console.log(`Selected title: ${title}`);
  //   setSelectedButton(title);
  //   getYoutubeData();

  //   // You can perform any action with the selected title
  // };
  console.log(profile, 'profile');

  const handleSaveClick = () => {
    let formData = {
      userinfo: profile,
      channelinfo: channelData,
      provider: provider,
    };
    dispatch(authAction.socialConnect(id, formData, navigate));
  };

  return (
    <div className='social'>
      <div className='social-container'>
        <div className='social-container-left'>
          <div className='social-container-left-content'>
            <div className='logo'>
              <img
                src={Logo}
                alt='app-logo'
              />
            </div>
            <div className='social-container-left-content-fields'>
              <Box sx={{ textAlign: 'center', my: 20 }}>
                {profile && profile !== null ? (
                  <>
                    <Avatar
                      alt='Remy Sharp'
                      src={profile.picture}
                      sx={{
                        m: 'auto',
                        width: 80,
                        height: 80,
                        borderRadius: '25px',
                      }}
                    />
                    <Typography
                      className='social-header'
                      component={'h3'}
                      variant={'h3'}>
                      {'You are logged in to ' +
                        provider +
                        ' as “' +
                        profile.name +
                        '”'}
                    </Typography>
                  </>
                ) : (
                  ''
                )}
                <div>
                  <List>
                    {channelData &&
                      channelData.map((item, index) => {
                        return (
                          <ListItem
                            sx={{ gap: '16px' }}
                            secondaryAction={
                              <IconButton
                                edge='end'
                                aria-label='delete'></IconButton>
                            }>
                            <ListItemAvatar>
                              <Avatar
                                src={
                                  item.snippet.thumbnails &&
                                  item.snippet.thumbnails.default &&
                                  item.snippet.thumbnails.default.url
                                }
                                sx={{
                                  mr: '2',
                                  width: 60,
                                  height: 60,
                                }}></Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ pr: 3 }}
                              primary={item.snippet.title}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: 'inline', mr: 1 }}
                                    component='span'
                                    variant='body2'
                                    color='text.primary'>
                                    Subscribers :
                                  </Typography>
                                  {item.statistics &&
                                    item.statistics.subscriberCount}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </div>
                <div className='continue-button'>
                  <Button
                    variant='contained'
                    onClick={handleSaveClick}
                    type='submit'>
                    Save
                  </Button>
                </div>
              </Box>

              {/* <p>Subscriber Count: {subscriberCount}</p> */}
            </div>
          </div>
        </div>
        <div className='social-container-right'>
          <AuthBanner />
        </div>
      </div>
    </div>
  );
};

export default SocialProvider;
