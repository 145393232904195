/** @format */

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getLoggedInToken } from '../utils/AuthConfig';
import { getInfLoggedInToken } from '../utils/InfluencerAuthConfig';

const PrivateInfluencerRoute = () => {
  const isLoggedIn = getInfLoggedInToken();
  console.log(Outlet, 'Outlet');
  // if (isLoggedIn) {
  //   return children;
  // }
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to='/influencer/login'
      replace
    />
  );
};

export default PrivateInfluencerRoute;
